// import awsExports from "../aws-exports";
import { dateIn1HourFromNow } from "@mltask/core/Utils/DateTimeHelper";
import { GraphQLAuthMode } from "@aws-amplify/core/internals/utils";
import {
  updateUser,
  updateUserRemoveNetworkMutation,
} from "@mltask/graphql/Codegen/custom-mutations";
import {
  UpdateUserConnectAcountIDMutation,
  UpdateUserConnectAcountSetupCompleteMutation,
} from "@mltask/graphql/Codegen/custom-api";
import {
  generateClient,
  GraphQLQuery,
  GraphQLSubscription,
} from "aws-amplify/api";
import { Cache } from "aws-amplify/utils";
import Constants from "@/utils/Constants";

import {
  GetUserQuery,
  OnCreateMLTaskExecutionResultSubscription,
  OnUpdateMLTaskExecutionResultSubscription,
  OnUpdateMLTaskExecutionResultSubscriptionVariables,
  ModelSubscriptionMLTaskExecutionResultFilterInput,
  OnUpdateUserSubscription,
  OnUpdateUserSubscriptionVariables,
  ModelInputPreset,
  CreateModelInputPresetMutation,
  UpdateModelInputPresetMutation,
  DeleteModelInputPresetMutation,
  ModelMLTaskDataFilterInput,
  CreateModelsMutation,
  CreateModelsMutationVariables,
  GetMLTaskDataQuery,
  GetMLTaskDataQueryVariables,
  MLTaskData,
  ListMLTaskExecutionResultsQuery,
  DeleteMLTasksWorkspaceMutation,
  DeleteMLTaskExecutionResultMutation,
  BatchGetUsersQuery,
  UpdateModelsMutationVariables,
  UpdateModelsMutation,
  UpdateMLTaskExecutionResultMutation,
  UpdateMLTaskExecutionResultMutationVariables,
  GetMLTaskExecutionResultQuery,
  GetMLTaskExecutionResultQueryVariables,
  CreateTaskReactionMutation,
  CreateTaskReactionInput,
  CreateSocialManPostMutationVariables,
  CreateSocialManPostMutation,
  DeleteSocialManPostMutationVariables,
  DeleteSocialManPostMutation,
  ListSocialManPostsByCreatedAtQuery,
  UpdateSocialManPostMutationVariables,
  UpdateSocialManPostMutation,
  UpdateSocialManPostInput,
  SocialManPost,
  User,
  GetSocialManPostQuery,
  ModelSubscriptionSocialManPostFilterInput,
  OnUpdateSocialManPostSubscriptionVariables,
  OnUpdateSocialManPostSubscription,
  SocialManTiktokPrivacyStatus,
  UpdateUserInput,
  UpdateUserMutation,
} from "@mltask/graphql/Codegen/API";

import { TaskExecutionCardProps, PagedTaskResults } from "@/typings";
import { v4 as uuidv4 } from "uuid";
import {
  getModelsTaskID,
  listAllMLTaskData,
  getAllUserExecutedTasks,
  onCreateMLTaskExecutionResult,
  getUserCredits,
  onFixedUpdateMLTaskExecutionResult,
  modelInputPresetsByModelsID,
  modelInputPresetsByUserID,
  getModelInputPresetByID,
  getAllMLTaskDataById,
  listPublicMLTaskExecutionResults,
  listSocialManPostsMinifiedDataByCreatedAt,
} from "@mltask/graphql/Codegen/custom-queries";
import { onUpdateSocialManPost } from "@mltask/graphql/Codegen/subscriptions";
import {
  updateUserUsername,
  createModelInputPreset,
  createModels,
} from "@mltask/graphql/Codegen/custom-mutations";

import {
  ModelInputPresetsByUserIDQuery,
  ModelInputPresetsByModelsIDQuery,
  ListMLTaskDataQuery,
  GetUserCredits,
  ListMLTaskExecutionResultsByCreatedAtQuery,
  GetModelInputPresetQuery,
} from "@mltask/graphql/Codegen/custom-api";

import {
  batchGetUsers,
  getMLTaskExecutionResult,
  getSocialManPost,
  getUser,
  listSocialManPostsByCreatedAt,
} from "@mltask/graphql/Codegen/queries";
import * as converter from "@/utils/graphql/convertionLayer";
import {
  LocalUser,
  LocalModelInputPreset,
  PagedLocalModelInputPresetResults,
  ModelsInfo,
  MLTasksData,
  LocalModelInputPresetEntryInput,
  MLTaskPropertyEntryData,
  LocalTaskPrivacyLevels,
  LocalReactionType,
} from "@/modeltypings";
import {
  createSocialManPost,
  createTaskReaction,
  deleteMLTaskExecutionResult,
  deleteMLTasksWorkspace,
  deleteSocialManPost,
  updateMLTaskExecutionResult,
  updateModelInputPreset,
  updateModels,
  updateSocialManPost,
} from "@mltask/graphql/Codegen/mutations";
import { NoUsernameError } from "../Errors/NoUsernameError";
import AWSS3Manager from "./AWSS3Manager";
import AWSCognitoManager from "./AWSCognitoManager";
import AWSManagerCommon from "./AWSManagerCommon";
import {
  LocalSocialManPost,
  LocalSocialManTiktokPrivacyStatus,
  PagedLocalSocialManPost,
  SocialManNetworks,
} from "@/app/SocialManmodeltypings";
import { SocialManPostStatus } from "../../../../graphql/Codegen/API";

export class AWSGQLManager {
  protected static man: AWSGQLManager;
  public static get Instance() {
    if (!this.man) {
      this.man = new AWSGQLManager();
    }
    return this.man;
  }
  protected api() {
    return generateClient();
  }

  async fetchModelTaskIDFromModelID(
    modelID: string
  ): Promise<string | undefined> {
    return new Promise(async (resolve, reject) => {
      try {
        const results = await this.api().graphql<
          GraphQLQuery<{
            getModels?: {
              id: string;
              mltaskdataID: string;
            } | null;
          }>
        >({
          query: getModelsTaskID,
          variables: {
            id: modelID,
            // $sortDirection: ModelSortDirection
            // $filter: ModelModelInputPresetFilterInput
            // $limit: Int
            // $nextToken: String
          },
          authMode: "userPool",
        });
        if (results.data?.getModels != null) {
          resolve(results.data!.getModels.mltaskdataID);
          return;
        }
        resolve(undefined);
      } catch (error) {
        // console.log("failed to fetch model input preset with error");
        // console.log(JSON.stringify(error));
        reject(error);
      }
    });
  }

  async fetchModelPresetByID(
    presetID: string
  ): Promise<LocalModelInputPreset | undefined> {
    return new Promise(async (resolve, reject) => {
      try {
        const results = await this.api().graphql<
          GraphQLQuery<GetModelInputPresetQuery>
        >({
          query: getModelInputPresetByID,
          variables: {
            id: presetID,
            // $sortDirection: ModelSortDirection
            // $filter: ModelModelInputPresetFilterInput
            // $limit: Int
            // $nextToken: String
          },
          authMode: "userPool",
        });
        if (results.data?.getModelInputPreset != null) {
          resolve(
            converter.convertModelInputPresetToLocal(
              results.data!.getModelInputPreset!
            )
          );
          return;
        }

        resolve(undefined);
      } catch (error) {
        // console.log("failed to fetch model input preset with error");
        // console.log(JSON.stringify(error));
        reject(error);
      }
    });
  }

  async updateUserUsername(username: string): Promise<LocalUser | undefined> {
    return new Promise(async (resolve, reject) => {
      try {
        var finalUserId = (await AWSCognitoManager.getCurrentCognitoUser())?.id;
        if (finalUserId == null) {
          reject(new Error("No Logged In User"));
          return;
        }
        const results = await this.api().graphql<
          GraphQLQuery<{
            updateUserUsername: {
              id: string;
              username: string;
            };
          }>
        >({
          query: updateUserUsername,
          variables: {
            userID: finalUserId,
            username,
          },
          authMode: "userPool",
        });
        resolve({
          id: results.data?.updateUserUsername.id,
          username: results.data?.updateUserUsername.username,
        } as LocalUser);
      } catch (error) {
        // console.log("failed to fetch model input preset with error");
        // console.log(JSON.stringify(error));
        reject(error);
      }
    });
  }
  async updateUserRemoveNetworkConnection(
    network: SocialManNetworks
  ): Promise<void> {
    return new Promise(async (resolve, reject) => {
      try {
        var finalUserId = (await AWSCognitoManager.getCurrentCognitoUser())?.id;
        if (finalUserId == null) {
          reject(new Error("No Logged In User"));
          return;
        }
        console.log(finalUserId);
        const updateUserInputData: UpdateUserInput = {
          id: finalUserId,
        };
        if (network == "TIKTOK") {
          updateUserInputData.tiktok = null;
        }
        if (network == "YOUTUBE") {
          updateUserInputData.google = null;
        }
        if (network == "FACEBOOK" || network == "INSTAGRAM") {
          updateUserInputData.facebook = null;
        }
        if (network == "TWITTER") {
          updateUserInputData.twitter = null;
        }
        if (network == "LINKEDIN") {
          updateUserInputData.linkedin = null;
        }
        if (network == "PINTEREST") {
          updateUserInputData.pinterest = null;
        }
        await this.api().graphql<GraphQLQuery<UpdateUserMutation>>({
          query: updateUserRemoveNetworkMutation,
          variables: { input: updateUserInputData },
          authMode: "userPool",
        });
        resolve();
      } catch (error) {
        console.log("failed to disconnect network with error:");
        console.error(error);
        reject(error);
      }
    });
  }
  async updateUserStripeConnectAccountID(
    userID: string,
    connectAccountID: string
  ): Promise<string | null | undefined> {
    return new Promise(async (resolve, reject) => {
      try {
        const results = await this.api().graphql<
          GraphQLQuery<UpdateUserConnectAcountIDMutation>
        >({
          query: updateUser,
          variables: {
            input: { id: userID, stripeConnectAccountID: connectAccountID },
          },
          authMode: "userPool",
        });
        resolve(results.data.updateUser?.stripeConnectAccountID);
      } catch (error) {
        // console.log("failed to fetch model input preset with error");
        // console.log(JSON.stringify(error));
        reject(error);
      }
    });
  }
  async setUserStripeAccountSetupComplete(userID: string): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      try {
        await this.api().graphql<
          GraphQLQuery<UpdateUserConnectAcountSetupCompleteMutation>
        >({
          query: updateUser,
          variables: {
            input: { id: userID, stripeConnectAccountSetupComplete: true },
          },
          authMode: "userPool",
        });
        resolve(true);
      } catch (error) {
        // console.log("failed to fetch model input preset with error");
        // console.log(JSON.stringify(error));
        reject(error);
      }
    });
  }
  async fetchModelPresetByModelID(
    modelID: string
  ): Promise<PagedLocalModelInputPresetResults | undefined> {
    return new Promise(async (resolve, reject) => {
      try {
        var finalUserId = (await AWSCognitoManager.getCurrentCognitoUser())?.id;
        if (finalUserId == null) {
          reject(new Error("No Logged In User"));
          return;
        }

        const results = await this.api().graphql<
          GraphQLQuery<ModelInputPresetsByModelsIDQuery>
        >({
          query: modelInputPresetsByModelsID,
          variables: {
            modelsID: modelID,
            // $sortDirection: ModelSortDirection
            // $filter: ModelModelInputPresetFilterInput
            // $limit: Int
            // $nextToken: String
          },
          authMode: "userPool",
        });

        const modelPresets =
          await converter.convertModelInputPresetByModelIDToLocal(results);
        resolve({
          presets: modelPresets,
          nextToken: "",
          // results.data?.listMLTaskExecutionResultsByCreatedAt?.nextToken,
        });
      } catch (error) {
        // console.log("failed to fetch model input preset with error");
        // console.log(JSON.stringify(error));
        reject(error);
      }
    });
  }

  async fetchCurrentUserModelPresets(): Promise<
    PagedLocalModelInputPresetResults | undefined
  > {
    return new Promise(async (resolve, reject) => {
      try {
        var finalUserId = (await AWSCognitoManager.getCurrentCognitoUser())?.id;
        if (finalUserId == null) {
          // no logged in user
          resolve(undefined);
          return;
        }
        const final = await this.fetchModelPresetByUserID(finalUserId!);
        resolve(final);
      } catch (error) {
        reject(error);
      }
    });
  }
  async fetchModelPresetByUserID(
    userID: string
  ): Promise<PagedLocalModelInputPresetResults | undefined> {
    return new Promise(async (resolve, reject) => {
      try {
        const results = await this.api().graphql<
          GraphQLQuery<ModelInputPresetsByUserIDQuery>
        >({
          query: modelInputPresetsByUserID,
          variables: {
            userID: userID,
            // $sortDirection: ModelSortDirection
            // $filter: ModelModelInputPresetFilterInput
            // $limit: Int
            // $nextToken: String
          },
          authMode: "userPool",
        });
        const modelPresets =
          await converter.convertModelInputPresetByUserIDToLocal(results);
        resolve({
          presets: modelPresets,
          nextToken: "",
          // results.data?.listMLTaskExecutionResultsByCreatedAt?.nextToken,
        });
      } catch (error) {
        console.log("failed to fetch model input preset with error");
        console.log(JSON.stringify(error));
        reject(error);
      }
    });
  }

  async createUserPresetForModel(
    modelsID: string,
    presetName: string,
    description: string,
    taskName: string,
    modelName: string,
    isPublic: boolean,
    isOpenSource: boolean,
    cost: number,
    entries: LocalModelInputPresetEntryInput[],
    progressCallback: (progress: any) => void
  ): Promise<LocalModelInputPreset> {
    return new Promise(async (resolve, reject) => {
      var currentUser = await this.fetchCurrentUser();
      if (currentUser?.id == null) {
        reject(new Error("No logged in User"));
        return;
      }
      if (currentUser?.username == null) {
        reject(
          new NoUsernameError({
            name: "USER_DIDNT_SETUP_USERNAME",
            cause: "",
            message: "",
          })
        );
        return;
      }

      //entries
      //for every item with url we have to upload it to s3 then create the preset
      const preset_id = uuidv4();

      for (let index = 0; index < entries.length; index++) {
        const entry = entries[index];
        if (entry.modelInputType == "TEXT") {
          for (let j = 0; j < entries[index].presetEntryDatas.length; j++) {
            const TaskQuotaCalculator = (
              await import("@mltask/core/TaskQuotaCalculator")
            ).default;
            const tokensLength = TaskQuotaCalculator.calculateGPTTokensCount(
              entries[index].presetEntryDatas[j].val ?? "",
              modelName
            );

            entries[index].presetEntryDatas[j].metaData = [
              ...(entries[index].presetEntryDatas[j].metaData ?? []),

              {
                key: "tokensCount",
                value: tokensLength + "",
              },
            ];
          }
        }

        if (
          entry.modelInputType == "URL_IMAGE" ||
          entry.modelInputType == "URL_AUDIO" ||
          entry.modelInputType == "URL_VIDEO"
        ) {
          const uploadedResult = await AWSS3Manager.uploadUserContentToS3(
            `presets/${preset_id}`,
            entries[index].presetEntryDatas[0].val,
            isPublic ? "guest" : "private",
            null,
            null,
            null,
            progressCallback
          );
          entries[index].presetEntryDatas[0].val = uploadedResult;
        }
      }
      var queryVars: { [key: string]: any } = {
        input: {
          id: preset_id,
          userID: currentUser!.id,
          modelsID,
          presetName,
          username: currentUser!.username,
          taskName,
          modelName,
          description,
          isPublic,
          isOpenSource,
          cost,
          usage: 0,
          entries,
        },
      };

      try {
        const results = await this.api().graphql<
          GraphQLQuery<CreateModelInputPresetMutation>
        >({
          query: createModelInputPreset,
          variables: queryVars,
          authMode: "userPool",
        });

        const jsonResult = results.data
          ?.createModelInputPreset as ModelInputPreset;
        const ret = converter.convertModelInputPresetToLocal(jsonResult);
        resolve(ret);
      } catch (error) {
        console.log("Failed to create preset with error");
        console.log(error);
        reject(error);
      }
    });
  }
  async updateUserPresetForModel(
    originalPreset: LocalModelInputPreset,
    presetID: string,
    presetName: string,
    description?: string,
    isPublic?: boolean,
    isOpenSource?: boolean,
    cost?: number,
    entries?: LocalModelInputPresetEntryInput[]
  ): Promise<LocalModelInputPreset> {
    return new Promise(async (resolve, reject) => {
      var userID = (await AWSCognitoManager.getCurrentCognitoUser())?.id;

      if (userID == null) {
        reject(new Error("No logged in User"));
        return;
      }

      var queryVars: { [key: string]: any } = {
        input: {
          id: presetID,
        },
        condition: {
          userID: { eq: userID },
        },
      };

      if (presetName != undefined) queryVars.input.presetName = presetName;
      if (description != undefined) queryVars.input.description = description;
      if (isPublic != undefined) {
        //moving files to private folder if applicable
        queryVars.input.isPublic = isPublic;
        if (entries == undefined) entries = [...originalPreset.entries];
        for (let index = 0; index < entries!.length; index++) {
          const entry = entries![index];
          if (
            entry.modelInputType == "URL_IMAGE" ||
            entry.modelInputType == "URL_AUDIO" ||
            entry.modelInputType == "URL_VIDEO"
          ) {
            //if we decide to go back to using user content upload
            // const fromLevel = isPublic == true ? "private" : "public";
            // const toLevel = isPublic == true ? "public" : "private";
            // const fromFileKey = entries[index].presetEntryDatas[0].val!;
            // const toFileKey = entries[index].presetEntryDatas[0].val!.replace(
            //   fromLevel,
            //   toLevel
            // );
            // await AWSS3Manager.moveUserContentOnS3(
            //   fromFileKey,
            //   AWSManagerCommon.getAccessLevelFromPublicBoolean(!isPublic),
            //   toFileKey,
            //   AWSManagerCommon.getAccessLevelFromPublicBoolean(isPublic)
            // );
            var fromFileKey, toFileKey;
            var cognitoID: string | undefined =
              await AWSCognitoManager.fetchCurrentIdentityID();

            if (isPublic == false) {
              // going to private
              fromFileKey = entries[index].presetEntryDatas[0].val!;
              toFileKey = `${entries[index].presetEntryDatas[0].val!.replace(
                `public/${userID}/`,
                `private/${cognitoID}/`
              )}`;
            } else {
              fromFileKey = entries[index].presetEntryDatas[0].val!;
              toFileKey = `${entries[index].presetEntryDatas[0].val!.replace(
                `private/${cognitoID}/`,
                `public/${userID}/`
              )}`;
            }
            await AWSS3Manager.moveFileOnS3(fromFileKey, toFileKey);
            entries[index].presetEntryDatas[0].val = toFileKey;
          }
        }
      }
      if (isOpenSource != undefined)
        queryVars.input.isOpenSource = isOpenSource;
      if (cost != undefined) queryVars.input.cost = cost;
      if (entries != undefined) queryVars.input.entries = entries;
      try {
        const results = await this.api().graphql<
          GraphQLQuery<UpdateModelInputPresetMutation>
        >({
          query: updateModelInputPreset,
          variables: queryVars,
          authMode: "userPool",
        });
        const jsonResult = results.data
          ?.updateModelInputPreset as ModelInputPreset;
        const ret = converter.convertModelInputPresetToLocal(jsonResult);
        resolve(ret);
      } catch (error) {
        reject(error);
      }
    });
  }
  deleteUserPresetWithPresetID(presetID: string): Promise<string> {
    return new Promise(async (resolve, reject) => {
      var userID = (await AWSCognitoManager.getCurrentCognitoUser())?.id;
      if (userID == null) {
        reject(new Error("No logged in User"));
        return;
      }
      var queryVars: { [key: string]: any } = {
        input: {
          id: presetID,
        },
      };
      try {
        const results = await this.api().graphql<
          GraphQLQuery<DeleteModelInputPresetMutation>
        >({
          query: `
          mutation DeleteModelInputPreset(
            $input: DeleteModelInputPresetInput!
            $condition: ModelModelInputPresetConditionInput
          ) {
            deleteModelInputPreset(input: $input, condition: $condition) {
              id
            }
          }
        `,
          variables: queryVars,
          authMode: "userPool",
        });

        // const jsonResult = results.data
        //   ?.deleteModelInputPreset as ModelInputPreset;
        // const ret = converter.convertModelInputPresetToLocal(jsonResult);
        resolve(presetID);
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  }

  async fetchCurrentUserTaskExecutionResults(
    userId?: string,
    nextToken?: string
  ): Promise<PagedTaskResults | undefined> {
    return new Promise(async (resolve, reject) => {
      try {
        var finalUserId =
          userId ?? (await AWSCognitoManager.getCurrentCognitoUser())?.id;
        if (finalUserId == null) {
          // no logged in user
          reject(new Error("No Logged In User"));
          return;
        }

        const results = await this.api().graphql<
          GraphQLQuery<ListMLTaskExecutionResultsByCreatedAtQuery>
        >({
          query: getAllUserExecutedTasks,
          variables: {
            userID: finalUserId,
            limit: Constants.TASK_PAGE_SIZE,
            nextToken: nextToken != null ? nextToken : undefined,
            sortDirection: "DESC",
          },
          authMode: "userPool",
        });
        const taskCardsData =
          await converter.convertTaskExecutionResultsToLocal(results);
        resolve({
          tasks: taskCardsData,
          nextToken:
            results.data?.listMLTaskExecutionResultsByCreatedAt?.nextToken,
        } as PagedTaskResults);
      } catch (error) {
        // console.log("failed to fetch graphql with error");
        // console.log(JSON.stringify(error));
        reject(error);
      }
    });
  }

  async deleteCurrentUserTaskWorkspace(
    workspaceID: string,
    userId?: string
  ): Promise<boolean | undefined> {
    return new Promise(async (resolve, reject) => {
      try {
        var finalUserId =
          userId ?? (await AWSCognitoManager.getCurrentCognitoUser())?.id;
        if (finalUserId == null) {
          // no logged in user
          reject(new Error("No Logged In User"));
          return;
        }

        const results = await this.api().graphql<
          GraphQLQuery<DeleteMLTasksWorkspaceMutation>
        >({
          query: deleteMLTasksWorkspace,
          variables: {
            input: { id: workspaceID },
            condition: { userID: { eq: finalUserId } },
          },
          authMode: "userPool",
        });
        resolve(true);
      } catch (error) {
        // console.log("failed to fetch graphql with error");
        // console.log(JSON.stringify(error));
        reject(error);
      }
    });
  }
  async getTaskExecutionResult(
    taskID: string
  ): Promise<TaskExecutionCardProps> {
    const vars: GetMLTaskExecutionResultQueryVariables = { id: taskID };
    return new Promise(async (resolve, reject) => {
      try {
        const results = await this.api().graphql<
          GraphQLQuery<GetMLTaskExecutionResultQuery>
        >({
          query: getMLTaskExecutionResult,
          variables: vars,
          authMode: "userPool",
        });
        const convertedTask = converter.convertTaskExecutionEntryToLocal(
          results.data.getMLTaskExecutionResult!
        );
        resolve(convertedTask);
      } catch (error) {
        console.log("failed to get task with error");
        console.error(error);
        reject(error);
      }
    });
  }
  async updateTaskExecutionResultsPrivacy(
    taskID: string,
    targetPrivacyLevel: LocalTaskPrivacyLevels
  ): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      try {
        var userID = (await AWSCognitoManager.getCurrentCognitoUser())?.id;
        if (userID == null) {
          // no logged in user
          reject(new Error("No Logged In User"));
          return;
        }
        const taskData = await this.getTaskExecutionResult(taskID);
        const { inputs } = taskData.taskExecutionData;
        const { outputs } = taskData.taskExecutionOutput;
        var linksToMove = [];
        for (let key in inputs) {
          const currentVal = inputs[key];
          const { value, type } = currentVal;
          if (type.startsWith("URL_")) {
            linksToMove.push(value);
          }
        }
        for (let key in outputs) {
          const currentVal = outputs[key];
          const { value, type } = currentVal;
          if (type.startsWith("URL_")) {
            linksToMove.push(value);
          }
        }
        for (let linkToMove of linksToMove) {
          const targetAccessLevel =
            AWSManagerCommon.getAccessLevelFromLocalTaskPrivacy(
              targetPrivacyLevel
            );

          await AWSS3Manager.moveUserFileOnS3ForPrivacyChange(
            userID,
            linkToMove,
            targetAccessLevel
          );
        }

        const vars: UpdateMLTaskExecutionResultMutationVariables = {
          input: {
            id: taskID,
            privacyLevel:
              converter.convertLocalPrivacyToAWSPrivacy(targetPrivacyLevel),
          },
          condition: { userID: { eq: userID } },
        };
        await this.api().graphql<
          GraphQLQuery<UpdateMLTaskExecutionResultMutation>
        >({
          query: updateMLTaskExecutionResult,
          variables: vars,
          authMode: "userPool",
        });
        resolve(true);
      } catch (error) {
        console.log("failed to update task with error");
        console.error(error);
        reject(error);
      }
    });
  }
  async addUserReactionToTask(
    userID: string,
    taskID: string,
    localReactionType: LocalReactionType
  ): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      try {
        const reactionType =
          converter.convertLocalReactionToAWSReaction(localReactionType);
        var input: CreateTaskReactionInput = {
          mltaskexecutionresultID: taskID,
          type: reactionType,
          userID,
        };
        await this.api().graphql<GraphQLQuery<CreateTaskReactionMutation>>({
          query: createTaskReaction,
          variables: { input },
          authMode: "userPool",
        });
        resolve(true);
      } catch (error) {
        console.log("failed to add reaction to task with error");
        console.error(error);
        reject(error);
      }
    });
  }

  async deleteCurrentUserTaskExecutionResult(
    taskID: string
  ): Promise<boolean | undefined> {
    return new Promise(async (resolve, reject) => {
      try {
        var finalUserId = (await AWSCognitoManager.getCurrentCognitoUser())?.id;
        if (finalUserId == null) {
          // no logged in user
          reject(new Error("No Logged In User"));
          return;
        }

        const results = await this.api().graphql<
          GraphQLQuery<DeleteMLTaskExecutionResultMutation>
        >({
          query: deleteMLTaskExecutionResult,
          variables: {
            input: { id: taskID },
            condition: { userID: { eq: finalUserId } },
          },
          authMode: "userPool",
        });
        resolve(true);
      } catch (error) {
        console.log("failed to delete task with error");
        console.log(JSON.stringify(error));
        reject(error);
      }
    });
  }
  async batchFetchAndCacheUsersByIds(
    idsToFetch: string[],
    authMode: GraphQLAuthMode
  ) {
    if (idsToFetch.length == 0) return;
    const results = await this.api().graphql<GraphQLQuery<BatchGetUsersQuery>>({
      query: batchGetUsers,
      variables: { ids: idsToFetch },
      authMode: authMode,
    });

    let localUsers = results.data.batchGetUsers?.map((x) =>
      converter.convertUserToLocal(x as User)
    );
    if (localUsers) {
      for (const x of localUsers) {
        let cacheKey = `${x.id}`;
        await Cache.setItem(cacheKey, x, {
          expires: dateIn1HourFromNow().getTime(),
        });
      }
    }
  }
  async getPublicTasks(
    nextToken?: string,
    authMode: GraphQLAuthMode = "userPool",
    limit: number = Constants.TASK_PAGE_SIZE
  ): Promise<{ results: PagedTaskResults } | undefined> {
    try {
      const publicTasksResults = await this.api().graphql<
        GraphQLQuery<ListMLTaskExecutionResultsQuery>
      >({
        query: listPublicMLTaskExecutionResults,
        variables: {
          filter: {
            status: { eq: "FINISHED" },
          },
          limit: limit,
          nextToken: nextToken,
        },
        authMode: authMode,
      });
      const taskCardsData = await converter.convertTaskExecutionResultsToLocal(
        publicTasksResults
      );

      return {
        results: {
          tasks: taskCardsData,
          nextToken:
            publicTasksResults.data?.listMLTaskExecutionResults?.nextToken,
        } as PagedTaskResults,
      };
    } catch (error) {
      console.log("failed to fetch graphql with error");
      console.log(JSON.stringify(error));
      return undefined;
    }
  }
  async createModel(
    modelData: ModelsInfo,
    isPublic: boolean
  ): Promise<ModelsInfo> {
    // var convertedRequiredLocalToAWS = [...modelData.inputs.minimumRequired].map((requiredInput => {
    //   requiredInput.optionType
    //   var convered: ModelInputEntryInput = {...requiredInput, optionType: OptionType.DROP_DOWN, type: ModelInputOutputEntryType.NUMBER }
    //   return convered

    // }));

    return new Promise(async (resolve, reject) => {
      try {
        const input: CreateModelsMutationVariables = {
          input: {
            isPublic: isPublic,
            name: modelData.name,
            inputs: {
              required: modelData.inputs.minimumRequired,
              optional: modelData.inputs.advancedInput,
            },
            outputs: modelData.outputs,
            mltaskdataID: modelData.taskID,
          },
        };
        const results = await this.api().graphql<
          GraphQLQuery<CreateModelsMutation>
        >({
          query: createModels,
          variables: input,
          authMode: "userPool",
        });
        resolve({ ...modelData, id: results.data?.createModels?.id! });
      } catch (error) {
        console.log("Failed to create model:");
        console.log(error);
        reject(error);
      }
    });
  }
  async updateModel(modelData: ModelsInfo): Promise<ModelsInfo> {
    return new Promise(async (resolve, reject) => {
      try {
        const input: UpdateModelsMutationVariables = {
          input: {
            id: modelData.id,
            name: modelData.name,
            inputs: {
              required: modelData.inputs.minimumRequired,
              optional: modelData.inputs.advancedInput,
            },
            outputs: modelData.outputs,
            mltaskdataID: modelData.taskID,
          },
        };
        const results = await this.api().graphql<
          GraphQLQuery<UpdateModelsMutation>
        >({
          query: updateModels,
          variables: input,
          authMode: "userPool",
        });
        resolve({ ...modelData, id: results.data?.updateModels?.id! });
      } catch (error) {
        console.log("Failed to create model:");
        console.log(error);
        reject(error);
      }
    });
  }

  async fetchCurrentUser(): Promise<LocalUser | undefined> {
    return new Promise(async (resolve, reject) => {
      try {
        const user = await AWSCognitoManager.getCurrentCognitoUser();
        const results = await this.api().graphql<GraphQLQuery<GetUserQuery>>({
          query: getUser,
          variables: { id: user?.id },
          authMode: "userPool",
        });
        const final = converter.convertUserResultsToLocal(results);
        resolve(final);
      } catch (error) {
        reject(error);
      }
    });
  }

  async fetchUserByID(
    userID: string,
    authMode: GraphQLAuthMode = "userPool"
  ): Promise<LocalUser | undefined> {
    return new Promise(async (resolve, reject) => {
      try {
        const results = await this.api().graphql<GraphQLQuery<GetUserQuery>>({
          query: getUser,
          variables: { id: userID },
          authMode: authMode,
        });
        const final = converter.convertUserResultsToLocal(results);
        resolve(final);
      } catch (error) {
        console.log("failed to fetch graphql with error");
        console.log(JSON.stringify(error));
        reject(error);
      }
    });
  }

  async batchGetUsersByIDs(
    ids: string[],
    authMode: GraphQLAuthMode = "userPool"
  ): Promise<LocalUser[] | undefined> {
    // const cachedUser = await Cache.getItem(cacheKey);
    // if (cachedUser) {
    //   return new Promise(async (resolve, reject) => {
    //     resolve(cachedUser);
    //   });
    // }
    return new Promise(async (resolve, reject) => {
      try {
        const results = await this.api().graphql<
          GraphQLQuery<BatchGetUsersQuery>
        >({
          query: batchGetUsers,
          variables: { ids },
          authMode: authMode,
        });
        let localUsers = results.data.batchGetUsers?.map((x) =>
          converter.convertUserToLocal(x as User)
        );

        // Cache.setItem(cacheKey, final, {
        //   expires: dateIn1HourFromNow().getTime(),
        // });
        resolve(localUsers);
      } catch (error) {
        console.log("failed to fetch graphql with error");
        console.log(JSON.stringify(error));
        reject(error);
      }
    });
  }
  async subscribeToUserCreditUpdate(
    userId: string | undefined,
    updatesCallback: (credits: number | null | undefined) => void,
    errorsCallback: (error: any) => void
  ) {
    userId = userId ?? (await AWSCognitoManager.getCurrentCognitoUser())?.id;
    // userId = "98d16320-30f1-705a-f284-3e94b8aae0ba";
    // console.log("subscribing to user with id " + userId);

    // const filter: ModelSubscriptionUserFilterInput = {
    //   stripeCustomerID: { eq: "cus_O71QTHDkiMqT5E" },
    // };

    const variables: OnUpdateUserSubscriptionVariables = {
      id: userId,
    };

    this.api()
      .graphql<GraphQLSubscription<OnUpdateUserSubscription>>({
        query: `
      subscription OnUpdateUser(
        $id: String
      ) {
        onUpdateUser(id: $id) {
          credits
        }
      }
    `,
        variables: variables,
        authMode: "userPool",
      })
      .subscribe({
        next: async ({ data }) => {
          updatesCallback?.(data!.onUpdateUser!.credits);
        },
        error: ({ error }) => {
          console.log("Failed to subscribe to user updates with errors:");
          console.log(JSON.stringify(error));
          // reject(error.errors[0]);
          errorsCallback(error);
        },
      });
  }
  async fetchCurrentUserCredits(): Promise<number | null | undefined> {
    return new Promise(async (resolve, reject) => {
      try {
        const user = await AWSCognitoManager.getCurrentCognitoUser();
        const results = await this.api().graphql<GraphQLQuery<GetUserCredits>>({
          query: getUserCredits,
          variables: { id: user?.id },
          authMode: "userPool",
        });
        resolve(results.data!.getUser!.credits);
      } catch (error) {
        // console.log("failed to fetch graphql with error");
        // console.log(JSON.stringify(error));
        reject(error);
      }
    });
  }
  subscriptions: { [key: string]: any } = {};
  unsubscribeToTaskExecutionUpdates(
    userId: string | undefined,
    taskId: string | undefined
  ) {
    this.subscriptions[`${userId}_${taskId}`].unsubscribe();
  }
  async subscribeToTaskExecutionUpdates(
    userId: string | undefined,
    taskId: string | undefined,
    updatesCallback: (updatedTask: TaskExecutionCardProps) => void,
    errorsCallback: (error: any) => void
  ) {
    const filter: ModelSubscriptionMLTaskExecutionResultFilterInput = {
      id: { eq: taskId },
    };

    const variables: OnUpdateMLTaskExecutionResultSubscriptionVariables = {
      filter: filter,
      userID: userId,
    };

    const ret = this.api()
      .graphql<GraphQLSubscription<OnUpdateMLTaskExecutionResultSubscription>>({
        query: onFixedUpdateMLTaskExecutionResult,
        variables: variables,
        authMode: "userPool",
      })
      .subscribe({
        next: async ({ data }) => {
          // console.log("received updates ");
          // console.log(
          //   JSON.stringify(value.data!.onUpdateMLTaskExecutionResult!)
          // );
          const converted = await converter.convertTaskExecutionEntryToLocal(
            data!.onUpdateMLTaskExecutionResult!
          );
          updatesCallback(converted);
        },
        error: ({ error }) => {
          console.log("Failed to subscribe to task updates with errors:");
          console.log(JSON.stringify(error));
          // reject(error.errors[0]);
          errorsCallback(error);
        },
      });
    this.subscriptions[`${userId}_${taskId}`] = ret;
  }
  async subscribeToOnTaskResultCreation(
    userId: string | undefined
  ): Promise<TaskExecutionCardProps> {
    var finalUserId =
      userId ?? (await AWSCognitoManager.getCurrentCognitoUser())?.id;
    return new Promise((resolve, reject) => {
      this.api()
        .graphql<
          GraphQLSubscription<OnCreateMLTaskExecutionResultSubscription>
        >({
          query: onCreateMLTaskExecutionResult,
          variables: {
            userID: finalUserId,
          },
          authMode: "userPool",
        })
        .subscribe({
          next: async ({ data }) => {
            const converted = await converter.convertTaskExecutionEntryToLocal(
              data!.onCreateMLTaskExecutionResult!
            );
            resolve(converted);
          },
          error: ({ error }) => {
            // console.log("Failed with errors:");
            // console.log(JSON.stringify(error.errors));
            reject(error.errors[0]);
          },
        });
    });
  }

  async getTasksGQL(): Promise<MLTasksData | undefined> {
    return new Promise(async (resolve, reject) => {
      var queryVars: { [key: string]: any } = {
        filter: { isPublic: { eq: true } } as ModelMLTaskDataFilterInput,
        nextToken: undefined,
        // nextToken: nextToken != null ? nextToken : undefined,
      };

      try {
        const results = await this.api().graphql<
          GraphQLQuery<ListMLTaskDataQuery>
        >({
          query: listAllMLTaskData,
          variables: queryVars,
          authMode: "userPool",
        });
        resolve(converter.convertListMLTaskDataToLocal(results));
      } catch (error) {
        console.log("failed to fetch tasks with error");
        console.error(error);
        reject(error);
      }
    });
    // const results = await API.get(process.env.NEXT_PUBLIC_API_NAME!, "/getCaller", {});
  }
  async getTasksByIDGQL(
    taskID: string
  ): Promise<MLTaskPropertyEntryData | undefined> {
    return new Promise(async (resolve, reject) => {
      var queryVars: GetMLTaskDataQueryVariables = {
        id: taskID,
      };

      try {
        const results = await this.api().graphql<
          GraphQLQuery<GetMLTaskDataQuery>
        >({
          query: getAllMLTaskDataById,
          variables: queryVars,
          authMode: "userPool",
        });
        resolve(
          converter.convertMLTaskDataToLocal(
            results.data?.getMLTaskData as MLTaskData
          )
        );
      } catch (error) {
        console.log("failed to fetch tasks with error");
        console.log(error);
        reject(error);
      }
    });
    // const results = await API.get(process.env.NEXT_PUBLIC_API_NAME!, "/getCaller", {});
  }

  async createSocialManPost(
    title: string,
    description: string,
    userID: string,
    fileData: string,
    progressCallback?: (progress: any) => void
  ): Promise<LocalSocialManPost> {
    return new Promise(async (resolve, reject) => {
      try {
        // const metadata = fileMetadata?.reduce((result, item) => {
        //   result[item.key] = item.value.toString();
        //   return result;
        // }, {} as { [key: string]: string });
        // console.log("metadata =");
        // console.log(JSON.stringify(metadata, null, 4));
        const postID = uuidv4();
        var fileKey = await AWSS3Manager.uploadUserContentToS3(
          `Apps/SocialMan/${postID}`,
          fileData,
          "private",
          null,
          null,
          null,
          (progress) => {
            const adjustedProgress = Math.floor(progress * 100);
            progressCallback?.(adjustedProgress);
          }
        );
        //removing everything before apps/ we will reconstruct the full path on the backend
        const index = fileKey!.indexOf("Apps/");
        const finalKey = fileKey!.substring(index) ?? fileKey;
        const input: CreateSocialManPostMutationVariables = {
          input: {
            id: postID,
            userID,
            title,
            description,
            s3Key: finalKey,
            status: SocialManPostStatus.DRAFT,
          },
        };
        const results = await this.api().graphql<
          GraphQLQuery<CreateSocialManPostMutation>
        >({
          query: createSocialManPost,
          variables: input,
          authMode: "userPool",
        });
        const converted = converter.convertSocialManPostToLocal(
          results.data?.createSocialManPost as SocialManPost
        );
        resolve({
          ...converted,
        });
      } catch (error) {
        console.log("Failed to create social man post:");
        console.log(error);
        reject(error);
      }
    });
  }
  async updateSocialManPost(
    localSocialManPost: LocalSocialManPost
  ): Promise<void> {
    return new Promise(async (resolve, reject) => {
      var postUpdateInput: UpdateSocialManPostInput = {
        id: localSocialManPost.id,
      };
      if (localSocialManPost.title) {
        postUpdateInput.title = localSocialManPost.title;
      }
      if (localSocialManPost.description) {
        postUpdateInput.description = localSocialManPost.description;
      }
      if (localSocialManPost.instagram) {
        postUpdateInput.instagram = {
          thumbnail: localSocialManPost.instagram?.thumbnail,
          caption: localSocialManPost.instagram?.caption ?? "",
          targetAccountID: localSocialManPost.instagram.targetAccountID,
          postToStory: localSocialManPost.instagram.postToStory,
        };
      }
      if (localSocialManPost.pinterest) {
        postUpdateInput.pinterest = {
          thumbnailURL: localSocialManPost.pinterest?.thumbnailURL,
          title: localSocialManPost.pinterest?.title ?? "",
          description: localSocialManPost.pinterest.description,
          link: localSocialManPost.pinterest.link,
          boardID: localSocialManPost.pinterest.boardID,
        };
      }
      if (localSocialManPost.facebook) {
        postUpdateInput.facebook = {
          thumbnail: localSocialManPost.facebook?.thumbnail,
          caption: localSocialManPost.facebook?.caption ?? "",
          targetPageID: localSocialManPost.facebook.targetPageID,
          postToStory: localSocialManPost.facebook.postToStory,
        };
      }
      if (localSocialManPost.tiktok) {
        postUpdateInput.tiktok = {
          ...localSocialManPost.tiktok,
          privacy: converter.localTiktokPrivactToGQL(
            localSocialManPost.tiktok.privacy ??
              LocalSocialManTiktokPrivacyStatus.PUBLIC_TO_EVERYONE
          ),
        };
      }
      if (localSocialManPost.youtube) {
        postUpdateInput.youtube = localSocialManPost.youtube;
      }
      if (localSocialManPost.twitter) {
        postUpdateInput.twitter = localSocialManPost.twitter;
      }
      if (localSocialManPost.linkedin) {
        postUpdateInput.linkedin = localSocialManPost.linkedin;
      }
      postUpdateInput.enabledFacebook = localSocialManPost.enabledFacebook;
      postUpdateInput.enabledPinterest = localSocialManPost.enabledPinterest;
      postUpdateInput.enabledInstagram = localSocialManPost.enabledInstagram;
      postUpdateInput.enabledTiktok = localSocialManPost.enabledTiktok;
      postUpdateInput.enabledTwitter = localSocialManPost.enabledTwitter;
      postUpdateInput.enabledYoutube = localSocialManPost.enabledYoutube;
      postUpdateInput.enabledLinkedin = localSocialManPost.enabledLinkedin;
      try {
        const input: UpdateSocialManPostMutationVariables = {
          input: postUpdateInput,
        };
        await this.api().graphql<GraphQLQuery<UpdateSocialManPostMutation>>({
          query: updateSocialManPost,
          variables: input,
          authMode: "userPool",
        });
        resolve();
      } catch (error) {
        console.log("Failed to update social man post:");
        console.log(error);
        reject(error);
      }
    });
  }
  async deleteSocialManPost(postID: string): Promise<void> {
    return new Promise(async (resolve, reject) => {
      try {
        const input: DeleteSocialManPostMutationVariables = {
          input: {
            id: postID,
          },
        };

        await this.api().graphql<GraphQLQuery<DeleteSocialManPostMutation>>({
          query: deleteSocialManPost,
          variables: input,
          authMode: "userPool",
        });
        resolve();
      } catch (error) {
        console.log("Failed to delete social man post:");
        console.log(error);
        reject(error);
      }
    });
  }
  async fetchCurrentUserSocialManPostByID(
    postID: string,
    authMode: GraphQLAuthMode = "userPool"
  ): Promise<LocalSocialManPost | undefined> {
    return new Promise(async (resolve, reject) => {
      try {
        const results = await this.api().graphql<
          GraphQLQuery<GetSocialManPostQuery>
        >({
          query: getSocialManPost,
          variables: {
            id: postID,
          },
          authMode: authMode,
        });
        const post = converter.convertSocialManPostToLocal(
          results.data.getSocialManPost as SocialManPost
        );
        resolve(post as LocalSocialManPost);
      } catch (error) {
        console.log("failed to fetch current user social man posts with error");
        console.log(JSON.stringify(error));
        reject(error);
      }
    });
  }
  async fetchCurrentUserSocialManPosts(
    userId?: string,
    nextToken?: string,
    authMode: GraphQLAuthMode = "userPool"
  ): Promise<PagedLocalSocialManPost | undefined> {
    return new Promise(async (resolve, reject) => {
      try {
        var finalUserId = userId;
        if (finalUserId == undefined)
          finalUserId = (await AWSCognitoManager.getCurrentCognitoUser())?.id;
        if (finalUserId == null) {
          // no logged in user
          throw new Error("No Logged In User");
        }
        const results = await this.api().graphql<
          GraphQLQuery<ListSocialManPostsByCreatedAtQuery>
        >({
          query: listSocialManPostsMinifiedDataByCreatedAt,
          variables: {
            userID: finalUserId,
            limit: Constants.SOCIAL_MAN_POSTS_PAGE_SIZE,
            nextToken: nextToken,
            sortDirection: "DESC",
          },
          authMode: authMode,
        });
        const posts = converter.convertSocialManPostsToLocal(results);
        resolve({
          posts,
          nextToken: results.data?.listSocialManPostsByCreatedAt?.nextToken,
        } as PagedLocalSocialManPost);
      } catch (error) {
        console.log("failed to fetch current user social man posts with error");
        console.error(error);
        reject(error);
      }
    });
  }
  socialManPostSubscriptions: { [key: string]: any } = {};
  unsubscribeFromSocialManPostUpdates(
    userID: string | undefined,
    postID: string | undefined
  ) {
    this.socialManPostSubscriptions[`${userID}_${postID}`].unsubscribe();
  }
  async subscribeToSocialManPostUpdates(
    userID: string | undefined,
    postID: string | undefined,
    updatesCallback: (updatedPost: LocalSocialManPost) => void,
    errorsCallback: (error: any) => void
  ) {
    const filter: ModelSubscriptionSocialManPostFilterInput = {
      id: { eq: postID },
    };

    const variables: OnUpdateSocialManPostSubscriptionVariables = {
      filter: filter,
      userID: userID,
    };

    const ret = this.api()
      .graphql<GraphQLSubscription<OnUpdateSocialManPostSubscription>>({
        query: onUpdateSocialManPost,
        variables: variables,
        authMode: "userPool",
      })
      .subscribe({
        next: async ({ data }) => {
          const converted = await converter.convertSocialManPostToLocal(
            data!.onUpdateSocialManPost!
          );
          updatesCallback(converted);
        },
        error: ({ error }) => {
          console.log("Failed to subscribe to task updates with errors:");
          console.log(JSON.stringify(error));
          // reject(error.errors[0]);
          errorsCallback(error);
        },
      });
    this.socialManPostSubscriptions[`${userID}_${postID}`] = ret;
  }
}

export default AWSGQLManager.Instance;
