/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "./API_WithTypename";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const batchGetUsers = /* GraphQL */ `query BatchGetUsers($ids: [ID]!) {
  batchGetUsers(ids: $ids) {
    MLTaskExecutionResults {
      items {
        ANGRYReactionStats
        CUTEReactionStats
        FUNNYReactionStats
        GROSSReactionStats
        HEARTReactionStats
        POOPReactionStats
        SADReactionStats
        SCARYReactionStats
        WOWReactionStats
        createdAt
        creditsUsed
        id
        mltasksworkspaceID
        modelName
        modelsID
        privacyLevel
        status
        taskName
        updatedAt
        userID
      }
      nextToken
    }
    MLTasksWorkspaces {
      items {
        createdAt
        description
        id
        name
        updatedAt
        userID
      }
      nextToken
    }
    ModelInputPresets {
      items {
        cost
        createdAt
        description
        id
        isOpenSource
        isPublic
        modelName
        modelsID
        presetName
        taskName
        updatedAt
        usage
        userID
        username
      }
      nextToken
    }
    SocialManPosts {
      items {
        createdAt
        description
        enabledFacebook
        enabledInstagram
        enabledLinkedin
        enabledPinterest
        enabledTiktok
        enabledTwitter
        enabledYoutube
        id
        postedTime
        postedToFacebook
        postedToInstagram
        postedToLinkedin
        postedToPinterest
        postedToTiktok
        postedToTwitter
        postedToYoutube
        s3Key
        s3KeyResized
        scheduledPostTime
        status
        title
        updatedAt
        userID
      }
      nextToken
    }
    completedTasksCount
    createdAt
    createdPresetCount
    credits
    currentSubscriptionPlan
    email
    facebook {
      instagramsData {
        accessToken
        biography
        connectedPageID
        expiresOn
        followersCount
        followsCount
        id
        igID
        mediaCount
        name
        profilePictureURL
        username
      }
      pagesData {
        accessToken
        category
        expiresOn
        id
        name
        picture
      }
      userData {
        accessToken
        expiresOn
        id
        name
        picture
      }
    }
    google {
      channelsData {
        customUrl
        description
        id
        thumbnail
        title
      }
      tokenData {
        accessToken
        expiresOn
        idToken
        refreshToken
        scope
        tokenType
      }
      userData {
        email
        familyName
        givenName
        id
        locale
        name
        picture
        verifiedEmail
      }
    }
    id
    linkedin {
      tokenData {
        accessToken
        accessTokenExpirationDate
        scope
      }
      userData {
        email
        name
        picture
        sub
      }
    }
    name
    periodEndOfLastSubscriptionCredits
    picture
    pinterest {
      boardsData {
        createdAt
        description
        followerCount
        id
        imageCoverUrl
        name
        pinCount
        privacy
      }
      tokenData {
        accessToken
        accessTokenExpirationDate
        refreshToken
        refreshTokenExpirationDate
        scope
      }
      userData {
        about
        accountType
        boardCount
        businessName
        followerCount
        followingCount
        id
        monthlyViews
        pinCount
        profileImage
        username
        websiteUrl
      }
    }
    stripeConnectAccountID
    stripeConnectAccountSetupComplete
    stripeCustomerID
    tiktok {
      tokenData {
        accessToken
        accessTokenExpirationDate
        refreshToken
        refreshTokenExpirationDate
        scope
      }
      userData {
        avatarLargeURL
        avatarURL
        avatarURL100
        bioDescription
        commentDisabled
        displayName
        duetDisabled
        followerCount
        followingCount
        isVerified
        likesCount
        maxVideoPostDuration_sec
        privacyLevelOptions
        profileDeepLink
        stitchDisabled
        username
        videoCount
      }
    }
    twitter {
      tokenData {
        accessToken
        accessTokenSecret
        codeVerifier
        expiresOn
        oauthTokenSecret
        refreshToken
      }
      userData {
        description
        id
        name
        profilePictureURL
        username
        verifiedType
      }
    }
    updatedAt
    username
  }
}
` as GeneratedQuery<
  APITypes.BatchGetUsersQueryVariables,
  APITypes.BatchGetUsersQuery
>;
export const getMLTaskData = /* GraphQL */ `query GetMLTaskData($id: ID!) {
  getMLTaskData(id: $id) {
    Models {
      items {
        createdAt
        id
        isPublic
        mltaskdataID
        name
        updatedAt
      }
      nextToken
    }
    category
    createdAt
    id
    isPublic
    name
    updatedAt
  }
}
` as GeneratedQuery<
  APITypes.GetMLTaskDataQueryVariables,
  APITypes.GetMLTaskDataQuery
>;
export const getMLTaskExecutionResult = /* GraphQL */ `query GetMLTaskExecutionResult($id: ID!) {
  getMLTaskExecutionResult(id: $id) {
    ANGRYReactionStats
    CUTEReactionStats
    FUNNYReactionStats
    GROSSReactionStats
    HEARTReactionStats
    POOPReactionStats
    Reactions {
      items {
        createdAt
        mltaskexecutionresultID
        type
        updatedAt
        userID
      }
      nextToken
    }
    SADReactionStats
    SCARYReactionStats
    WOWReactionStats
    createdAt
    creditsUsed
    id
    inputs {
      metaData {
        key
        value
      }
      name
      type
      value
    }
    mltasksworkspaceID
    modelName
    modelsID
    output {
      entries {
        name
        type
        value
      }
      message
    }
    presetUserInput {
      presetCreatorName
      presetCreatorUID
      presetID
      presetName
      presetValues {
        modelInputName
      }
    }
    privacyLevel
    status
    taskName
    updatedAt
    userID
  }
}
` as GeneratedQuery<
  APITypes.GetMLTaskExecutionResultQueryVariables,
  APITypes.GetMLTaskExecutionResultQuery
>;
export const getMLTasksWorkspace = /* GraphQL */ `query GetMLTasksWorkspace($id: ID!) {
  getMLTasksWorkspace(id: $id) {
    MLTaskExecutionResults {
      items {
        ANGRYReactionStats
        CUTEReactionStats
        FUNNYReactionStats
        GROSSReactionStats
        HEARTReactionStats
        POOPReactionStats
        SADReactionStats
        SCARYReactionStats
        WOWReactionStats
        createdAt
        creditsUsed
        id
        mltasksworkspaceID
        modelName
        modelsID
        privacyLevel
        status
        taskName
        updatedAt
        userID
      }
      nextToken
    }
    createdAt
    description
    id
    name
    updatedAt
    userID
  }
}
` as GeneratedQuery<
  APITypes.GetMLTasksWorkspaceQueryVariables,
  APITypes.GetMLTasksWorkspaceQuery
>;
export const getModelInputPreset = /* GraphQL */ `query GetModelInputPreset($id: ID!) {
  getModelInputPreset(id: $id) {
    cost
    createdAt
    description
    entries {
      modelInputName
      modelInputType
      presetEntryDatas {
        description
        entryType
        parameterName
        presetOrder
        val
      }
    }
    id
    isOpenSource
    isPublic
    modelName
    modelsID
    presetName
    taskName
    updatedAt
    usage
    userID
    username
  }
}
` as GeneratedQuery<
  APITypes.GetModelInputPresetQueryVariables,
  APITypes.GetModelInputPresetQuery
>;
export const getModels = /* GraphQL */ `query GetModels($id: ID!) {
  getModels(id: $id) {
    MLTaskExecutionResults {
      items {
        ANGRYReactionStats
        CUTEReactionStats
        FUNNYReactionStats
        GROSSReactionStats
        HEARTReactionStats
        POOPReactionStats
        SADReactionStats
        SCARYReactionStats
        WOWReactionStats
        createdAt
        creditsUsed
        id
        mltasksworkspaceID
        modelName
        modelsID
        privacyLevel
        status
        taskName
        updatedAt
        userID
      }
      nextToken
    }
    ModelInputPresets {
      items {
        cost
        createdAt
        description
        id
        isOpenSource
        isPublic
        modelName
        modelsID
        presetName
        taskName
        updatedAt
        usage
        userID
        username
      }
      nextToken
    }
    createdAt
    id
    inputs {
      optional {
        defaultValue
        description
        examples
        name
        optionStep
        optionType
        type
      }
      required {
        defaultValue
        description
        examples
        name
        optionStep
        optionType
        type
      }
    }
    isPublic
    mltaskdataID
    name
    outputs {
      entries {
        name
        type
      }
    }
    updatedAt
  }
}
` as GeneratedQuery<APITypes.GetModelsQueryVariables, APITypes.GetModelsQuery>;
export const getSocialManPost = /* GraphQL */ `query GetSocialManPost($id: ID!) {
  getSocialManPost(id: $id) {
    createdAt
    description
    enabledFacebook
    enabledInstagram
    enabledLinkedin
    enabledPinterest
    enabledTiktok
    enabledTwitter
    enabledYoutube
    facebook {
      caption
      postID
      postToStory
      storyID
      storyURL
      targetPageID
      thumbnail
    }
    id
    instagram {
      caption
      postID
      postToStory
      storyID
      targetAccountID
      thumbnail
    }
    linkedin {
      caption
      mediaDescription
      postID
    }
    pinterest {
      boardID
      description
      link
      pinID
      thumbnailURL
      title
    }
    postedTime
    postedToFacebook
    postedToInstagram
    postedToLinkedin
    postedToPinterest
    postedToTiktok
    postedToTwitter
    postedToYoutube
    s3Key
    s3KeyResized
    scheduledPostTime
    status
    tiktok {
      caption
      contentDisclosureBrandedContent
      contentDisclosureEnabled
      contentDisclosureYourBrand
      postID
      privacy
      title
      usersCanComment
      usersCanDuet
      usersCanStitch
      videoCoverTimestampMs
    }
    title
    twitter {
      caption
      tweetID
    }
    updatedAt
    userID
    youtube {
      category
      description
      privacy
      tags
      targetChannelID
      thumbnailURL
      title
      videoID
    }
  }
}
` as GeneratedQuery<
  APITypes.GetSocialManPostQueryVariables,
  APITypes.GetSocialManPostQuery
>;
export const getTaskReaction = /* GraphQL */ `query GetTaskReaction($userID: ID!) {
  getTaskReaction(userID: $userID) {
    createdAt
    mltaskexecutionresultID
    type
    updatedAt
    userID
  }
}
` as GeneratedQuery<
  APITypes.GetTaskReactionQueryVariables,
  APITypes.GetTaskReactionQuery
>;
export const getUser = /* GraphQL */ `query GetUser($id: ID!) {
  getUser(id: $id) {
    MLTaskExecutionResults {
      items {
        ANGRYReactionStats
        CUTEReactionStats
        FUNNYReactionStats
        GROSSReactionStats
        HEARTReactionStats
        POOPReactionStats
        SADReactionStats
        SCARYReactionStats
        WOWReactionStats
        createdAt
        creditsUsed
        id
        mltasksworkspaceID
        modelName
        modelsID
        privacyLevel
        status
        taskName
        updatedAt
        userID
      }
      nextToken
    }
    MLTasksWorkspaces {
      items {
        createdAt
        description
        id
        name
        updatedAt
        userID
      }
      nextToken
    }
    ModelInputPresets {
      items {
        cost
        createdAt
        description
        id
        isOpenSource
        isPublic
        modelName
        modelsID
        presetName
        taskName
        updatedAt
        usage
        userID
        username
      }
      nextToken
    }
    SocialManPosts {
      items {
        createdAt
        description
        enabledFacebook
        enabledInstagram
        enabledLinkedin
        enabledPinterest
        enabledTiktok
        enabledTwitter
        enabledYoutube
        id
        postedTime
        postedToFacebook
        postedToInstagram
        postedToLinkedin
        postedToPinterest
        postedToTiktok
        postedToTwitter
        postedToYoutube
        s3Key
        s3KeyResized
        scheduledPostTime
        status
        title
        updatedAt
        userID
      }
      nextToken
    }
    completedTasksCount
    createdAt
    createdPresetCount
    credits
    currentSubscriptionPlan
    email
    facebook {
      instagramsData {
        accessToken
        biography
        connectedPageID
        expiresOn
        followersCount
        followsCount
        id
        igID
        mediaCount
        name
        profilePictureURL
        username
      }
      pagesData {
        accessToken
        category
        expiresOn
        id
        name
        picture
      }
      userData {
        accessToken
        expiresOn
        id
        name
        picture
      }
    }
    google {
      channelsData {
        customUrl
        description
        id
        thumbnail
        title
      }
      tokenData {
        accessToken
        expiresOn
        idToken
        refreshToken
        scope
        tokenType
      }
      userData {
        email
        familyName
        givenName
        id
        locale
        name
        picture
        verifiedEmail
      }
    }
    id
    linkedin {
      tokenData {
        accessToken
        accessTokenExpirationDate
        scope
      }
      userData {
        email
        name
        picture
        sub
      }
    }
    name
    periodEndOfLastSubscriptionCredits
    picture
    pinterest {
      boardsData {
        createdAt
        description
        followerCount
        id
        imageCoverUrl
        name
        pinCount
        privacy
      }
      tokenData {
        accessToken
        accessTokenExpirationDate
        refreshToken
        refreshTokenExpirationDate
        scope
      }
      userData {
        about
        accountType
        boardCount
        businessName
        followerCount
        followingCount
        id
        monthlyViews
        pinCount
        profileImage
        username
        websiteUrl
      }
    }
    stripeConnectAccountID
    stripeConnectAccountSetupComplete
    stripeCustomerID
    tiktok {
      tokenData {
        accessToken
        accessTokenExpirationDate
        refreshToken
        refreshTokenExpirationDate
        scope
      }
      userData {
        avatarLargeURL
        avatarURL
        avatarURL100
        bioDescription
        commentDisabled
        displayName
        duetDisabled
        followerCount
        followingCount
        isVerified
        likesCount
        maxVideoPostDuration_sec
        privacyLevelOptions
        profileDeepLink
        stitchDisabled
        username
        videoCount
      }
    }
    twitter {
      tokenData {
        accessToken
        accessTokenSecret
        codeVerifier
        expiresOn
        oauthTokenSecret
        refreshToken
      }
      userData {
        description
        id
        name
        profilePictureURL
        username
        verifiedType
      }
    }
    updatedAt
    username
  }
}
` as GeneratedQuery<APITypes.GetUserQueryVariables, APITypes.GetUserQuery>;
export const getUserFromUsername = /* GraphQL */ `query GetUserFromUsername($username: String!) {
  getUserFromUsername(username: $username) {
    MLTaskExecutionResults {
      items {
        ANGRYReactionStats
        CUTEReactionStats
        FUNNYReactionStats
        GROSSReactionStats
        HEARTReactionStats
        POOPReactionStats
        SADReactionStats
        SCARYReactionStats
        WOWReactionStats
        createdAt
        creditsUsed
        id
        mltasksworkspaceID
        modelName
        modelsID
        privacyLevel
        status
        taskName
        updatedAt
        userID
      }
      nextToken
    }
    MLTasksWorkspaces {
      items {
        createdAt
        description
        id
        name
        updatedAt
        userID
      }
      nextToken
    }
    ModelInputPresets {
      items {
        cost
        createdAt
        description
        id
        isOpenSource
        isPublic
        modelName
        modelsID
        presetName
        taskName
        updatedAt
        usage
        userID
        username
      }
      nextToken
    }
    SocialManPosts {
      items {
        createdAt
        description
        enabledFacebook
        enabledInstagram
        enabledLinkedin
        enabledPinterest
        enabledTiktok
        enabledTwitter
        enabledYoutube
        id
        postedTime
        postedToFacebook
        postedToInstagram
        postedToLinkedin
        postedToPinterest
        postedToTiktok
        postedToTwitter
        postedToYoutube
        s3Key
        s3KeyResized
        scheduledPostTime
        status
        title
        updatedAt
        userID
      }
      nextToken
    }
    completedTasksCount
    createdAt
    createdPresetCount
    credits
    currentSubscriptionPlan
    email
    facebook {
      instagramsData {
        accessToken
        biography
        connectedPageID
        expiresOn
        followersCount
        followsCount
        id
        igID
        mediaCount
        name
        profilePictureURL
        username
      }
      pagesData {
        accessToken
        category
        expiresOn
        id
        name
        picture
      }
      userData {
        accessToken
        expiresOn
        id
        name
        picture
      }
    }
    google {
      channelsData {
        customUrl
        description
        id
        thumbnail
        title
      }
      tokenData {
        accessToken
        expiresOn
        idToken
        refreshToken
        scope
        tokenType
      }
      userData {
        email
        familyName
        givenName
        id
        locale
        name
        picture
        verifiedEmail
      }
    }
    id
    linkedin {
      tokenData {
        accessToken
        accessTokenExpirationDate
        scope
      }
      userData {
        email
        name
        picture
        sub
      }
    }
    name
    periodEndOfLastSubscriptionCredits
    picture
    pinterest {
      boardsData {
        createdAt
        description
        followerCount
        id
        imageCoverUrl
        name
        pinCount
        privacy
      }
      tokenData {
        accessToken
        accessTokenExpirationDate
        refreshToken
        refreshTokenExpirationDate
        scope
      }
      userData {
        about
        accountType
        boardCount
        businessName
        followerCount
        followingCount
        id
        monthlyViews
        pinCount
        profileImage
        username
        websiteUrl
      }
    }
    stripeConnectAccountID
    stripeConnectAccountSetupComplete
    stripeCustomerID
    tiktok {
      tokenData {
        accessToken
        accessTokenExpirationDate
        refreshToken
        refreshTokenExpirationDate
        scope
      }
      userData {
        avatarLargeURL
        avatarURL
        avatarURL100
        bioDescription
        commentDisabled
        displayName
        duetDisabled
        followerCount
        followingCount
        isVerified
        likesCount
        maxVideoPostDuration_sec
        privacyLevelOptions
        profileDeepLink
        stitchDisabled
        username
        videoCount
      }
    }
    twitter {
      tokenData {
        accessToken
        accessTokenSecret
        codeVerifier
        expiresOn
        oauthTokenSecret
        refreshToken
      }
      userData {
        description
        id
        name
        profilePictureURL
        username
        verifiedType
      }
    }
    updatedAt
    username
  }
}
` as GeneratedQuery<
  APITypes.GetUserFromUsernameQueryVariables,
  APITypes.GetUserFromUsernameQuery
>;
export const listMLTaskData = /* GraphQL */ `query ListMLTaskData(
  $filter: ModelMLTaskDataFilterInput
  $limit: Int
  $nextToken: String
) {
  listMLTaskData(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      Models {
        nextToken
      }
      category
      createdAt
      id
      isPublic
      name
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListMLTaskDataQueryVariables,
  APITypes.ListMLTaskDataQuery
>;
export const listMLTaskExecutionResults = /* GraphQL */ `query ListMLTaskExecutionResults(
  $filter: ModelMLTaskExecutionResultFilterInput
  $limit: Int
  $nextToken: String
) {
  listMLTaskExecutionResults(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      ANGRYReactionStats
      CUTEReactionStats
      FUNNYReactionStats
      GROSSReactionStats
      HEARTReactionStats
      POOPReactionStats
      Reactions {
        nextToken
      }
      SADReactionStats
      SCARYReactionStats
      WOWReactionStats
      createdAt
      creditsUsed
      id
      inputs {
        name
        type
        value
      }
      mltasksworkspaceID
      modelName
      modelsID
      output {
        message
      }
      presetUserInput {
        presetCreatorName
        presetCreatorUID
        presetID
        presetName
      }
      privacyLevel
      status
      taskName
      updatedAt
      userID
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListMLTaskExecutionResultsQueryVariables,
  APITypes.ListMLTaskExecutionResultsQuery
>;
export const listMLTaskExecutionResultsByCreatedAt = /* GraphQL */ `query ListMLTaskExecutionResultsByCreatedAt(
  $createdAt: ModelStringKeyConditionInput
  $filter: ModelMLTaskExecutionResultFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
  $userID: ID!
) {
  listMLTaskExecutionResultsByCreatedAt(
    createdAt: $createdAt
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
    userID: $userID
  ) {
    items {
      ANGRYReactionStats
      CUTEReactionStats
      FUNNYReactionStats
      GROSSReactionStats
      HEARTReactionStats
      POOPReactionStats
      Reactions {
        nextToken
      }
      SADReactionStats
      SCARYReactionStats
      WOWReactionStats
      createdAt
      creditsUsed
      id
      inputs {
        name
        type
        value
      }
      mltasksworkspaceID
      modelName
      modelsID
      output {
        message
      }
      presetUserInput {
        presetCreatorName
        presetCreatorUID
        presetID
        presetName
      }
      privacyLevel
      status
      taskName
      updatedAt
      userID
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListMLTaskExecutionResultsByCreatedAtQueryVariables,
  APITypes.ListMLTaskExecutionResultsByCreatedAtQuery
>;
export const listMLTasksWorkspaceByCreatedAt = /* GraphQL */ `query ListMLTasksWorkspaceByCreatedAt(
  $createdAt: ModelStringKeyConditionInput
  $filter: ModelMLTasksWorkspaceFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
  $userID: ID!
) {
  listMLTasksWorkspaceByCreatedAt(
    createdAt: $createdAt
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
    userID: $userID
  ) {
    items {
      MLTaskExecutionResults {
        nextToken
      }
      createdAt
      description
      id
      name
      updatedAt
      userID
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListMLTasksWorkspaceByCreatedAtQueryVariables,
  APITypes.ListMLTasksWorkspaceByCreatedAtQuery
>;
export const listMLTasksWorkspaces = /* GraphQL */ `query ListMLTasksWorkspaces(
  $filter: ModelMLTasksWorkspaceFilterInput
  $limit: Int
  $nextToken: String
) {
  listMLTasksWorkspaces(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      MLTaskExecutionResults {
        nextToken
      }
      createdAt
      description
      id
      name
      updatedAt
      userID
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListMLTasksWorkspacesQueryVariables,
  APITypes.ListMLTasksWorkspacesQuery
>;
export const listModelInputPresets = /* GraphQL */ `query ListModelInputPresets(
  $filter: ModelModelInputPresetFilterInput
  $limit: Int
  $nextToken: String
) {
  listModelInputPresets(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      cost
      createdAt
      description
      entries {
        modelInputName
        modelInputType
      }
      id
      isOpenSource
      isPublic
      modelName
      modelsID
      presetName
      taskName
      updatedAt
      usage
      userID
      username
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListModelInputPresetsQueryVariables,
  APITypes.ListModelInputPresetsQuery
>;
export const listModels = /* GraphQL */ `query ListModels(
  $filter: ModelModelsFilterInput
  $limit: Int
  $nextToken: String
) {
  listModels(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      MLTaskExecutionResults {
        nextToken
      }
      ModelInputPresets {
        nextToken
      }
      createdAt
      id
      isPublic
      mltaskdataID
      name
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListModelsQueryVariables,
  APITypes.ListModelsQuery
>;
export const listSocialManPosts = /* GraphQL */ `query ListSocialManPosts(
  $filter: ModelSocialManPostFilterInput
  $limit: Int
  $nextToken: String
) {
  listSocialManPosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      createdAt
      description
      enabledFacebook
      enabledInstagram
      enabledLinkedin
      enabledPinterest
      enabledTiktok
      enabledTwitter
      enabledYoutube
      facebook {
        caption
        postID
        postToStory
        storyID
        storyURL
        targetPageID
        thumbnail
      }
      id
      instagram {
        caption
        postID
        postToStory
        storyID
        targetAccountID
        thumbnail
      }
      linkedin {
        caption
        mediaDescription
        postID
      }
      pinterest {
        boardID
        description
        link
        pinID
        thumbnailURL
        title
      }
      postedTime
      postedToFacebook
      postedToInstagram
      postedToLinkedin
      postedToPinterest
      postedToTiktok
      postedToTwitter
      postedToYoutube
      s3Key
      s3KeyResized
      scheduledPostTime
      status
      tiktok {
        caption
        contentDisclosureBrandedContent
        contentDisclosureEnabled
        contentDisclosureYourBrand
        postID
        privacy
        title
        usersCanComment
        usersCanDuet
        usersCanStitch
        videoCoverTimestampMs
      }
      title
      twitter {
        caption
        tweetID
      }
      updatedAt
      userID
      youtube {
        category
        description
        privacy
        tags
        targetChannelID
        thumbnailURL
        title
        videoID
      }
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListSocialManPostsQueryVariables,
  APITypes.ListSocialManPostsQuery
>;
export const listSocialManPostsByCreatedAt = /* GraphQL */ `query ListSocialManPostsByCreatedAt(
  $createdAt: ModelStringKeyConditionInput
  $filter: ModelSocialManPostFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
  $userID: ID!
) {
  listSocialManPostsByCreatedAt(
    createdAt: $createdAt
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
    userID: $userID
  ) {
    items {
      createdAt
      description
      enabledFacebook
      enabledInstagram
      enabledLinkedin
      enabledPinterest
      enabledTiktok
      enabledTwitter
      enabledYoutube
      facebook {
        caption
        postID
        postToStory
        storyID
        storyURL
        targetPageID
        thumbnail
      }
      id
      instagram {
        caption
        postID
        postToStory
        storyID
        targetAccountID
        thumbnail
      }
      linkedin {
        caption
        mediaDescription
        postID
      }
      pinterest {
        boardID
        description
        link
        pinID
        thumbnailURL
        title
      }
      postedTime
      postedToFacebook
      postedToInstagram
      postedToLinkedin
      postedToPinterest
      postedToTiktok
      postedToTwitter
      postedToYoutube
      s3Key
      s3KeyResized
      scheduledPostTime
      status
      tiktok {
        caption
        contentDisclosureBrandedContent
        contentDisclosureEnabled
        contentDisclosureYourBrand
        postID
        privacy
        title
        usersCanComment
        usersCanDuet
        usersCanStitch
        videoCoverTimestampMs
      }
      title
      twitter {
        caption
        tweetID
      }
      updatedAt
      userID
      youtube {
        category
        description
        privacy
        tags
        targetChannelID
        thumbnailURL
        title
        videoID
      }
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListSocialManPostsByCreatedAtQueryVariables,
  APITypes.ListSocialManPostsByCreatedAtQuery
>;
export const listTaskReactions = /* GraphQL */ `query ListTaskReactions(
  $filter: ModelTaskReactionFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
  $userID: ID
) {
  listTaskReactions(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
    userID: $userID
  ) {
    items {
      createdAt
      mltaskexecutionresultID
      type
      updatedAt
      userID
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListTaskReactionsQueryVariables,
  APITypes.ListTaskReactionsQuery
>;
export const listUsers = /* GraphQL */ `query ListUsers(
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      MLTaskExecutionResults {
        nextToken
      }
      MLTasksWorkspaces {
        nextToken
      }
      ModelInputPresets {
        nextToken
      }
      SocialManPosts {
        nextToken
      }
      completedTasksCount
      createdAt
      createdPresetCount
      credits
      currentSubscriptionPlan
      email
      id
      name
      periodEndOfLastSubscriptionCredits
      picture
      stripeConnectAccountID
      stripeConnectAccountSetupComplete
      stripeCustomerID
      updatedAt
      username
    }
    nextToken
  }
}
` as GeneratedQuery<APITypes.ListUsersQueryVariables, APITypes.ListUsersQuery>;
export const mLTaskExecutionResultsByMltasksworkspaceID = /* GraphQL */ `query MLTaskExecutionResultsByMltasksworkspaceID(
  $filter: ModelMLTaskExecutionResultFilterInput
  $limit: Int
  $mltasksworkspaceID: ID!
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  mLTaskExecutionResultsByMltasksworkspaceID(
    filter: $filter
    limit: $limit
    mltasksworkspaceID: $mltasksworkspaceID
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      ANGRYReactionStats
      CUTEReactionStats
      FUNNYReactionStats
      GROSSReactionStats
      HEARTReactionStats
      POOPReactionStats
      Reactions {
        nextToken
      }
      SADReactionStats
      SCARYReactionStats
      WOWReactionStats
      createdAt
      creditsUsed
      id
      inputs {
        name
        type
        value
      }
      mltasksworkspaceID
      modelName
      modelsID
      output {
        message
      }
      presetUserInput {
        presetCreatorName
        presetCreatorUID
        presetID
        presetName
      }
      privacyLevel
      status
      taskName
      updatedAt
      userID
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.MLTaskExecutionResultsByMltasksworkspaceIDQueryVariables,
  APITypes.MLTaskExecutionResultsByMltasksworkspaceIDQuery
>;
export const mLTaskExecutionResultsByModelsID = /* GraphQL */ `query MLTaskExecutionResultsByModelsID(
  $filter: ModelMLTaskExecutionResultFilterInput
  $limit: Int
  $modelsID: ID!
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  mLTaskExecutionResultsByModelsID(
    filter: $filter
    limit: $limit
    modelsID: $modelsID
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      ANGRYReactionStats
      CUTEReactionStats
      FUNNYReactionStats
      GROSSReactionStats
      HEARTReactionStats
      POOPReactionStats
      Reactions {
        nextToken
      }
      SADReactionStats
      SCARYReactionStats
      WOWReactionStats
      createdAt
      creditsUsed
      id
      inputs {
        name
        type
        value
      }
      mltasksworkspaceID
      modelName
      modelsID
      output {
        message
      }
      presetUserInput {
        presetCreatorName
        presetCreatorUID
        presetID
        presetName
      }
      privacyLevel
      status
      taskName
      updatedAt
      userID
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.MLTaskExecutionResultsByModelsIDQueryVariables,
  APITypes.MLTaskExecutionResultsByModelsIDQuery
>;
export const modelInputPresetsByModelsID = /* GraphQL */ `query ModelInputPresetsByModelsID(
  $filter: ModelModelInputPresetFilterInput
  $limit: Int
  $modelsID: ID!
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  modelInputPresetsByModelsID(
    filter: $filter
    limit: $limit
    modelsID: $modelsID
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      cost
      createdAt
      description
      entries {
        modelInputName
        modelInputType
      }
      id
      isOpenSource
      isPublic
      modelName
      modelsID
      presetName
      taskName
      updatedAt
      usage
      userID
      username
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ModelInputPresetsByModelsIDQueryVariables,
  APITypes.ModelInputPresetsByModelsIDQuery
>;
export const modelInputPresetsByUserID = /* GraphQL */ `query ModelInputPresetsByUserID(
  $filter: ModelModelInputPresetFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
  $userID: ID!
) {
  modelInputPresetsByUserID(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
    userID: $userID
  ) {
    items {
      cost
      createdAt
      description
      entries {
        modelInputName
        modelInputType
      }
      id
      isOpenSource
      isPublic
      modelName
      modelsID
      presetName
      taskName
      updatedAt
      usage
      userID
      username
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ModelInputPresetsByUserIDQueryVariables,
  APITypes.ModelInputPresetsByUserIDQuery
>;
export const modelsByMltaskdataID = /* GraphQL */ `query ModelsByMltaskdataID(
  $filter: ModelModelsFilterInput
  $limit: Int
  $mltaskdataID: ID!
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  modelsByMltaskdataID(
    filter: $filter
    limit: $limit
    mltaskdataID: $mltaskdataID
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      MLTaskExecutionResults {
        nextToken
      }
      ModelInputPresets {
        nextToken
      }
      createdAt
      id
      isPublic
      mltaskdataID
      name
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ModelsByMltaskdataIDQueryVariables,
  APITypes.ModelsByMltaskdataIDQuery
>;
export const taskReactionsByMltaskexecutionresultID = /* GraphQL */ `query TaskReactionsByMltaskexecutionresultID(
  $filter: ModelTaskReactionFilterInput
  $limit: Int
  $mltaskexecutionresultID: ID!
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  taskReactionsByMltaskexecutionresultID(
    filter: $filter
    limit: $limit
    mltaskexecutionresultID: $mltaskexecutionresultID
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      createdAt
      mltaskexecutionresultID
      type
      updatedAt
      userID
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.TaskReactionsByMltaskexecutionresultIDQueryVariables,
  APITypes.TaskReactionsByMltaskexecutionresultIDQuery
>;
