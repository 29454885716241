// import awsExports from "../aws-exports";

import { CloudAccessLevels, TaskExecutionInput } from "@/modeltypings";
import {
  copyFileOnS3,
  deleteFileOnS3,
  getUrlFromBucket,
} from "@mltask/core/AWSHelpers/S3Helper";
import {
  extractContentMimeTypeFromData,
  extractFileExtensionFromData,
} from "@mltask/core/Utils/FileHelper";
import { fetchAuthSession } from "aws-amplify/auth";
import { copy, remove, uploadData } from "aws-amplify/storage";
import { v4 as uuidv4 } from "uuid";

import { ModelInputOutputEntryType } from "@mltask/graphql/Codegen/API";
// import { Config } from "sst/node/config";
import AWSCognitoManager from "./AWSCognitoManager";
import { aws_configs } from "./awsConfig";

export class AWSS3Manager {
  protected static man: AWSS3Manager;
  public static get Instance() {
    if (!this.man) {
      this.man = new AWSS3Manager();
    }
    return this.man;
  }

  async getCloudContent(
    fullKey: string,
    isContentPublic: boolean
  ): Promise<string> {
    return new Promise(async (resolve, reject) => {
      // console.log("Cache MISSSSS: ", fullKey);
      try {
        const creds = await fetchAuthSession();
        const urlResult = await getUrlFromBucket(
          aws_configs.aws_user_files_s3_bucket!,
          aws_configs.aws_user_files_s3_bucket_region!,
          fullKey,
          creds.credentials,
          isContentPublic
        );
        if (urlResult == undefined) {
          reject(new Error("Object not found"));
          return;
        }
        resolve(urlResult);
      } catch (error) {
        console.log("error:");
        console.log(error);
      }
    });
  }

  // async uploadContentToS3(destination: string, content: any) {
  //   const creds = await fetchAuthSession();
  //   const s3Configuration: S3ClientConfig = {
  //     credentials: creds.credentials,
  //     region: aws_configs.aws_user_files_s3_bucket_region,
  //   };
  //   const s3Client = new S3Client(s3Configuration);
  //   const putCommand = new PutObjectCommand({
  //     Bucket: aws_configs.aws_user_files_s3_bucket,
  //     Key: destination,
  //     Body: content,

  //   });
  //   await s3Client.send(putCommand);
  // }

  async moveUserFileOnS3ForPrivacyChange(
    customUserID: string | undefined,
    keyToMove: string,
    targetLevel: CloudAccessLevels
    //unfortunatley Storage.get maxes out at 1 hour.....
  ) {
    const creds = await fetchAuthSession();
    var fromKey = "";
    var toKey = "";
    const userID =
      customUserID ?? (await AWSCognitoManager.fetchCurrentUserID());

    if (targetLevel == "guest") {
      // moving to public
      fromKey = `private/${creds.identityId}/${keyToMove}`;
      toKey = `public/${userID}/${keyToMove}`;
    } else {
      // moving to private
      fromKey = `public/${userID}/${keyToMove}`;
      toKey = `private/${creds.identityId}/${keyToMove}`;
    }
    this.moveFileOnS3(fromKey, toKey);
  }

  async moveFileOnS3(from: string, destination: string) {
    const creds = await fetchAuthSession();
    await copyFileOnS3(
      from,
      destination,
      aws_configs.aws_user_files_s3_bucket!,
      aws_configs.aws_user_files_s3_bucket_region!,
      creds.credentials
    );
    await deleteFileOnS3(
      from,
      aws_configs.aws_user_files_s3_bucket!,
      aws_configs.aws_user_files_s3_bucket_region!,
      creds.credentials
    );
  }

  async uploadFileForKeyAndInput(
    key: string,
    inputs: TaskExecutionInput,
    level: CloudAccessLevels,
    updatedInputType: ModelInputOutputEntryType,
    progressCallback?: (progress: any) => void
  ) {
    if (key in inputs == false) {
      // console.log(`KEY ${key} not in inputs, ignoring upload`);
      return;
    }
    const fileInput = inputs[key];
    const fileData = fileInput["value"];
    const metadata = fileInput.metaData!.reduce((result, item) => {
      result[item.key] = item.value.toString();
      return result;
    }, {} as { [key: string]: string });

    var fileKey = await this.uploadUserContentToS3(
      "task_staging",
      fileData,
      level,
      metadata,
      null,
      null,
      progressCallback
    );
    //removing everything before task_staging (we will reconstruct it later on the backend for security reasons)
    const index = fileKey!.indexOf("task_staging/");
    const finalKey = fileKey!.substring(index) ?? fileKey;
    // console.log("fileKey = " + fileKey);
    inputs[key] = {
      type: updatedInputType,
      name: fileInput.name,
      value: finalKey,
      metaData: fileInput.metaData,
    };
  }

  async getUserContent(
    customUserID: string | undefined,
    key: string,
    level: CloudAccessLevels
  ): Promise<string> {
    const creds = await fetchAuthSession();
    if (level == "guest") {
      const userID =
        customUserID ?? (await AWSCognitoManager.fetchCurrentUserID());
      key = `public/${userID}/${key}`;
    } else {
      key = `private/${creds.identityId}/${key}`;
    }
    return this.getCloudContent(key, level == "guest");
  }
  async copyUserContentOnS3(
    from: string,
    fromLevel: CloudAccessLevels,
    destination: string,
    toLevel: CloudAccessLevels
  ) {
    var userID: string | undefined = "";
    if (toLevel == "guest" || fromLevel == "guest") {
      userID = await AWSCognitoManager.fetchCurrentUserID();
    }
    await copy({
      source: {
        path: ({ identityId }) =>
          fromLevel == "guest"
            ? `public/${userID}/${from}`
            : `${fromLevel}/${identityId}/${from}`,
      },
      destination: {
        path: ({ identityId }) =>
          fromLevel == "guest"
            ? `public/${userID}/${destination}`
            : `${fromLevel}/${identityId}/${destination}`,
      },
    });
  }
  async moveUserContentOnS3(
    from: string,
    fromLevel: CloudAccessLevels,
    destination: string,
    toLevel: CloudAccessLevels
  ) {
    var userID: string | undefined = "";
    if (toLevel == "guest" || fromLevel == "guest") {
      userID = await AWSCognitoManager.fetchCurrentUserID();
    }
    await copy({
      source: {
        path: ({ identityId }) =>
          fromLevel == "guest"
            ? `public/${userID}/${from}`
            : `${fromLevel}/${identityId}/${from}`,
      },
      destination: {
        path: ({ identityId }) =>
          fromLevel == "guest"
            ? `public/${userID}/${destination}`
            : `${fromLevel}/${identityId}/${destination}`,
      },
    });
    await remove({
      path: ({ identityId }) =>
        fromLevel == "guest"
          ? `public/${userID}/${from}`
          : `${fromLevel}/${identityId}/${from}`,
    });
  }
  async uploadUserContentToS3(
    destination: string,
    content: any,
    level: CloudAccessLevels,
    metadata: {} | null,
    dotExtension: string | null = null,
    filenameWithoutExtension: string | null = null,
    progressCompletionPercentCallback?: (progress: any) => void,
    errorCallback?: (error: any) => void
  ): Promise<string> {
    if (dotExtension == null) {
      dotExtension = extractFileExtensionFromData(content);
    }
    if (filenameWithoutExtension == null) {
      filenameWithoutExtension = `${uuidv4()}`;
    }
    //need to replace cognito id with user id when uploading to public
    var userID: string | undefined = "";
    if (level == "guest") {
      userID = await AWSCognitoManager.fetchCurrentUserID();
    }
    const filename = `${filenameWithoutExtension}${dotExtension}`;
    const contentType = extractContentMimeTypeFromData(content);
    const filepath = [destination, filename].join("/");
    const blobbed = await fetch(content).then((res) => res.blob());
    try {
      const result = await uploadData({
        path: ({ identityId }) =>
          level == "guest"
            ? `public/${userID}/${filepath}`
            : `${level}/${identityId}/${filepath}`,
        data: blobbed,
        options: {
          contentType: contentType,
          metadata: metadata ?? {},
          onProgress: ({ transferredBytes, totalBytes }) => {
            if (totalBytes) {
              progressCompletionPercentCallback?.(
                transferredBytes / totalBytes
              );
            }
          },
        },
      }).result;
      return result.path;
    } catch (error) {
      errorCallback?.(error);
      return "";
    }
  }

  // async uploadUserContentToS3FromLink(
  //   link: string,
  //   filename: string = `${uuidv4()}.png`
  // ) {
  //   const downloadedImageBlob = await downloadImageBlob(link);
  //   const ret = await uploadData({
  //     key: filename,
  //     data: downloadedImageBlob,
  //     options: {
  //       contentType: "image/png",
  //       // acl: "private",
  //       // acl: "authenticated_read",
  //     },
  //   });
  //   return (await ret.result).key;
  // }
}

export default AWSS3Manager.Instance;
