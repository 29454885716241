/* tslint:disable */
/* eslint-disable */
import * as APITypes from "./API_WithTypename";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const listSocialManPostsMinifiedDataByCreatedAt =
  /* GraphQL */ `query listSocialManPostsMinifiedDataByCreatedAt(
  $createdAt: ModelStringKeyConditionInput
  $filter: ModelSocialManPostFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
  $userID: ID!
) {
  listSocialManPostsByCreatedAt(
    createdAt: $createdAt
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
    userID: $userID
  ) {
    items {
      createdAt
      description
      id
      s3Key
      s3KeyResized
      status
      title
      updatedAt
      userID
      facebook {
        thumbnail
      }
      youtube {
        thumbnailURL
      }
      instagram {
        thumbnail
      }
      pinterest {
        thumbnailURL
      }
      postedTime
      postedToFacebook
      postedToInstagram
      postedToPinterest
      postedToTiktok
      postedToTwitter
      postedToYoutube
    }
    nextToken
  }
}
` as GeneratedQuery<
    APITypes.ListSocialManPostsByCreatedAtQueryVariables,
    APITypes.ListSocialManPostsByCreatedAtQuery
  >;

export const getUserFromUsername = /* GraphQL */ `
  query getUserFromUsername($username: String!) {
    getUserFromUsername(username: $username) {
      id
      name
      username
      createdPresetCount
      completedTasksCount
      stripeCustomerID
      stripeConnectAccountID
      stripeConnectAccountSetupComplete
      email
      picture
      credits
      MLTaskExecutionResults {
        nextToken
        __typename
      }
      MLTasksWorkspaces {
        nextToken
        __typename
      }
      ModelInputPresets {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;

export const getModelsTaskID = /* GraphQL */ `
  query GetModelsTaskID($id: ID!) {
    getModels(id: $id) {
      id
      mltaskdataID
    }
  }
`;

export const getModelInputPresetByID = /* GraphQL */ `
  query GetModelInputPresetCustom($id: ID!) {
    getModelInputPreset(id: $id) {
      id
      presetName
      description
      isPublic
      isOpenSource
      modelsID
      userID
      username
      taskName
      modelName
      cost
      createdAt
      updatedAt
      usage
      entries {
        modelInputName
        modelInputType
        presetEntryDatas {
          entryType
          parameterName
          description
          val
          presetOrder
          metaData {
            key
            value
          }
        }
      }
    }
  }
`;

export const modelInputPresetsByUserID = /* GraphQL */ `
  query ModelInputPresetsByUserIDCustom(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelModelInputPresetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    modelInputPresetsByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        presetName
        description
        isPublic
        isOpenSource
        modelsID
        userID
        username
        taskName
        modelName
        cost
        createdAt
        updatedAt
        usage
        entries {
          modelInputName
          modelInputType
          presetEntryDatas {
            entryType
            parameterName
            description
            val
            presetOrder
            metaData {
              key
              value
            }
          }
        }
      }
      nextToken
    }
  }
`;

export const modelInputPresetsByModelsID = /* GraphQL */ `
  query ModelInputPresetsByModelsIDCustom(
    $modelsID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelModelInputPresetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    modelInputPresetsByModelsID(
      modelsID: $modelsID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        presetName
        description
        isPublic
        isOpenSource
        modelsID
        userID
        username
        taskName
        modelName
        cost
        createdAt
        updatedAt
        usage
        entries {
          modelInputName
          modelInputType
          presetEntryDatas {
            entryType
            parameterName
            description
            val
            presetOrder
            metaData {
              key
              value
            }
          }
        }
      }
      nextToken
    }
  }
`;

export const onFixedUpdateMLTaskExecutionResult = /* GraphQL */ `
  subscription OnUpdateMLTaskExecutionResult2(
    $filter: ModelSubscriptionMLTaskExecutionResultFilterInput
    $userID: String
  ) {
    onUpdateMLTaskExecutionResult(filter: $filter, userID: $userID) {
      id
      taskName
      modelName
      privacyLevel
      inputs {
        name
        value
        type
      }
      presetUserInput {
        presetName
        presetCreatorName
        presetCreatorUID
        presetValues {
          modelInputName
          presetEntryDatas {
            val
            presetOrder
            parameterName
          }
        }
      }
      output {
        message
        entries {
          name
          value
          type
        }
      }
      creditsUsed
      status
    }
  }
`;

export const onCreateMLTaskExecutionResult = /* GraphQL */ `
  subscription OnCreateMLTaskExecutionResult2(
    $filter: ModelSubscriptionMLTaskExecutionResultFilterInput
    $userID: String
  ) {
    onCreateMLTaskExecutionResult(filter: $filter, userID: $userID) {
      id
      userID
      taskName
      modelName
      privacyLevel
      inputs {
        name
        value
        type
      }
      output {
        message
        entries {
          name
          value
          type
        }
      }
      status
    }
  }
`;

export const getAllMLTaskDataById = /* GraphQL */ `
  query getMLTaskDataQuery($id: ID!) {
    getMLTaskData(id: $id) {
      id
      name
      category
      isPublic
      Models(filter: { isPublic: { eq: true } }) {
        items {
          id
          name
          mltaskdataID
          outputs {
            entries {
              name
              type
            }
          }
          inputs {
            optional {
              defaultValue
              description
              examples
              name
              optionType
              options {
                name
                value
                description
              }
              optionStep
              type
              constraints {
                type
                value
              }
            }
            required {
              defaultValue
              description
              examples
              name
              optionType
              options {
                name
                value
                description
              }
              optionStep
              type
              constraints {
                type
                value
              }
            }
          }
        }
      }
    }
  }
`;

export const listAllMLTaskData = /* GraphQL */ `
  query ListTasks(
    $filter: ModelMLTaskDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMLTaskData(filter: $filter, limit: $limit, nextToken: $nextToken) {
      nextToken
      items {
        id
        name
        category
        isPublic
        Models(filter: { isPublic: { eq: true } }) {
          items {
            id
            name
            mltaskdataID
            outputs {
              entries {
                name
                type
              }
            }
            inputs {
              optional {
                defaultValue
                description
                examples
                name
                optionType
                options {
                  name
                  value
                  description
                }
                optionStep
                type
                constraints {
                  type
                  value
                }
              }
              required {
                defaultValue
                description
                examples
                name
                optionType
                options {
                  name
                  value
                  description
                }
                optionStep
                type
                constraints {
                  type
                  value
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const getAllUserExecutedTasks = /* GraphQL */ `
  query getUserExecutedTasks(
    $nextToken: String
    $sortDirection: ModelSortDirection
    $limit: Int
    $userID: ID!
  ) {
    listMLTaskExecutionResultsByCreatedAt(
      userID: $userID
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        userID
        createdAt
        status
        creditsUsed
        modelName
        privacyLevel
        modelsID
        taskName
        inputs {
          name
          value
          type
        }
        output {
          message
          entries {
            name
            type
            value
          }
        }
        presetUserInput {
          presetID
          presetName
          presetCreatorName
          presetCreatorUID
          presetValues {
            modelInputName
            presetEntryDatas {
              parameterName
              val
              presetOrder
            }
          }
        }
      }
      nextToken
    }
  }
`;

export const listPublicMLTaskExecutionResults = /* GraphQL */ `
  query CustomListMLTaskExecutionResults(
    $filter: ModelMLTaskExecutionResultFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMLTaskExecutionResults(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        createdAt
        status
        creditsUsed
        modelName
        privacyLevel
        modelsID
        taskName
        inputs {
          name
          value
          type
        }
        output {
          message
          entries {
            name
            type
            value
          }
        }
        presetUserInput {
          presetID
          presetName
          presetCreatorName
          presetCreatorUID
          presetValues {
            modelInputName
            presetEntryDatas {
              parameterName
              val
              presetOrder
            }
          }
        }
      }
      nextToken
    }
  }
`;

export const getUserCredits = /* GraphQL */ `
  query GetUserCredits($id: ID!) {
    getUser(id: $id) {
      id
      credits
    }
  }
`;
