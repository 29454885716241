export const aws_configs = {
  aws_project_region: process.env.NEXT_PUBLIC_REGION,
  aws_cloud_logic_custom: [
    {
      name: process.env.NEXT_PUBLIC_API_NAME,
      endpoint: process.env.NEXT_PUBLIC_API_URL,
      region: process.env.NEXT_PUBLIC_REGION,
    },
  ],
  aws_appsync_graphqlEndpoint: process.env.NEXT_PUBLIC_GQL_API_URL,
  aws_appsync_region: process.env.NEXT_PUBLIC_REGION,
  aws_appsync_authenticationType: "AWS_IAM",
  aws_appsync_apiKey: process.env.NEXT_PUBLIC_GQL_API_KEY,
  aws_cognito_identity_pool_id: process.env.NEXT_PUBLIC_IDENTITY_POOL_ID,
  aws_cognito_region: process.env.NEXT_PUBLIC_REGION,
  aws_user_pools_id: process.env.NEXT_PUBLIC_USER_POOL_ID,
  aws_user_pools_web_client_id: process.env.NEXT_PUBLIC_USER_POOL_CLIENT_ID,
  Auth: {
    Cognito: {
      loginWith: {
        oauth: {
          redirectSignIn: process.env.NEXT_PUBLIC_REDIRECT_LOGIN_CALLBACK,
          redirectSignOut: process.env.NEXT_PUBLIC_REDIRECT_LOGOUT_CALLBACK,
          domain: process.env.NEXT_PUBLIC_COGNITO_DOMAIN_NAME,
          scope: [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin",
          ],
          responseType: "code",
          providers: ["Google"],
        },
      },
    },
  },
  oauth: {
    domain: process.env.NEXT_PUBLIC_COGNITO_DOMAIN_NAME,
    scope: [
      "phone",
      "email",
      "openid",
      "profile",
      "aws.cognito.signin.user.admin",
    ],
    redirectSignIn: process.env.NEXT_PUBLIC_REDIRECT_LOGIN_CALLBACK,
    redirectSignOut: process.env.NEXT_PUBLIC_REDIRECT_LOGOUT_CALLBACK,
    responseType: "code",
  },
  federationTarget: "COGNITO_USER_POOLS",
  aws_cognito_username_attributes: ["EMAIL"],
  aws_cognito_social_providers: ["GOOGLE"],
  aws_cognito_signup_attributes: [],
  aws_cognito_mfa_configuration: "OFF",
  aws_cognito_mfa_types: ["SMS"],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [
      "REQUIRES_LOWERCASE",
      "REQUIRES_NUMBERS",
      "REQUIRES_SYMBOLS",
      "REQUIRES_UPPERCASE",
    ],
  },
  aws_cognito_verification_mechanisms: ["EMAIL"],
  aws_user_files_s3_bucket: process.env.NEXT_PUBLIC_BUCKET,
  aws_user_files_s3_bucket_region: process.env.NEXT_PUBLIC_REGION,
  aws_mobile_analytics_app_id: process.env.NEXT_PUBLIC_PINPOINT_PROJECT_ID,
  aws_mobile_analytics_app_region: process.env.NEXT_PUBLIC_REGION,
  Analytics: {
    AWSPinpoint: {
      appId: process.env.NEXT_PUBLIC_PINPOINT_PROJECT_ID,
      region: process.env.NEXT_PUBLIC_REGION,
    },
  },
};
