/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "./API_WithTypename";
type GeneratedSubscription<InputType, OutputType> = string & {
  __generatedSubscriptionInput: InputType;
  __generatedSubscriptionOutput: OutputType;
};

export const onCreateMLTaskData = /* GraphQL */ `subscription OnCreateMLTaskData(
  $filter: ModelSubscriptionMLTaskDataFilterInput
) {
  onCreateMLTaskData(filter: $filter) {
    Models {
      items {
        createdAt
        id
        isPublic
        mltaskdataID
        name
        updatedAt
      }
      nextToken
    }
    category
    createdAt
    id
    isPublic
    name
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateMLTaskDataSubscriptionVariables,
  APITypes.OnCreateMLTaskDataSubscription
>;
export const onCreateMLTaskExecutionResult = /* GraphQL */ `subscription OnCreateMLTaskExecutionResult(
  $filter: ModelSubscriptionMLTaskExecutionResultFilterInput
  $userID: String
) {
  onCreateMLTaskExecutionResult(filter: $filter, userID: $userID) {
    ANGRYReactionStats
    CUTEReactionStats
    FUNNYReactionStats
    GROSSReactionStats
    HEARTReactionStats
    POOPReactionStats
    Reactions {
      items {
        createdAt
        mltaskexecutionresultID
        type
        updatedAt
        userID
      }
      nextToken
    }
    SADReactionStats
    SCARYReactionStats
    WOWReactionStats
    createdAt
    creditsUsed
    id
    inputs {
      metaData {
        key
        value
      }
      name
      type
      value
    }
    mltasksworkspaceID
    modelName
    modelsID
    output {
      entries {
        name
        type
        value
      }
      message
    }
    presetUserInput {
      presetCreatorName
      presetCreatorUID
      presetID
      presetName
      presetValues {
        modelInputName
      }
    }
    privacyLevel
    status
    taskName
    updatedAt
    userID
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateMLTaskExecutionResultSubscriptionVariables,
  APITypes.OnCreateMLTaskExecutionResultSubscription
>;
export const onCreateMLTasksWorkspace = /* GraphQL */ `subscription OnCreateMLTasksWorkspace(
  $filter: ModelSubscriptionMLTasksWorkspaceFilterInput
  $userID: String
) {
  onCreateMLTasksWorkspace(filter: $filter, userID: $userID) {
    MLTaskExecutionResults {
      items {
        ANGRYReactionStats
        CUTEReactionStats
        FUNNYReactionStats
        GROSSReactionStats
        HEARTReactionStats
        POOPReactionStats
        SADReactionStats
        SCARYReactionStats
        WOWReactionStats
        createdAt
        creditsUsed
        id
        mltasksworkspaceID
        modelName
        modelsID
        privacyLevel
        status
        taskName
        updatedAt
        userID
      }
      nextToken
    }
    createdAt
    description
    id
    name
    updatedAt
    userID
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateMLTasksWorkspaceSubscriptionVariables,
  APITypes.OnCreateMLTasksWorkspaceSubscription
>;
export const onCreateModelInputPreset = /* GraphQL */ `subscription OnCreateModelInputPreset(
  $filter: ModelSubscriptionModelInputPresetFilterInput
  $userID: String
) {
  onCreateModelInputPreset(filter: $filter, userID: $userID) {
    cost
    createdAt
    description
    entries {
      modelInputName
      modelInputType
      presetEntryDatas {
        description
        entryType
        parameterName
        presetOrder
        val
      }
    }
    id
    isOpenSource
    isPublic
    modelName
    modelsID
    presetName
    taskName
    updatedAt
    usage
    userID
    username
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateModelInputPresetSubscriptionVariables,
  APITypes.OnCreateModelInputPresetSubscription
>;
export const onCreateModels = /* GraphQL */ `subscription OnCreateModels($filter: ModelSubscriptionModelsFilterInput) {
  onCreateModels(filter: $filter) {
    MLTaskExecutionResults {
      items {
        ANGRYReactionStats
        CUTEReactionStats
        FUNNYReactionStats
        GROSSReactionStats
        HEARTReactionStats
        POOPReactionStats
        SADReactionStats
        SCARYReactionStats
        WOWReactionStats
        createdAt
        creditsUsed
        id
        mltasksworkspaceID
        modelName
        modelsID
        privacyLevel
        status
        taskName
        updatedAt
        userID
      }
      nextToken
    }
    ModelInputPresets {
      items {
        cost
        createdAt
        description
        id
        isOpenSource
        isPublic
        modelName
        modelsID
        presetName
        taskName
        updatedAt
        usage
        userID
        username
      }
      nextToken
    }
    createdAt
    id
    inputs {
      optional {
        defaultValue
        description
        examples
        name
        optionStep
        optionType
        type
      }
      required {
        defaultValue
        description
        examples
        name
        optionStep
        optionType
        type
      }
    }
    isPublic
    mltaskdataID
    name
    outputs {
      entries {
        name
        type
      }
    }
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateModelsSubscriptionVariables,
  APITypes.OnCreateModelsSubscription
>;
export const onCreateSocialManPost = /* GraphQL */ `subscription OnCreateSocialManPost(
  $filter: ModelSubscriptionSocialManPostFilterInput
  $userID: String
) {
  onCreateSocialManPost(filter: $filter, userID: $userID) {
    createdAt
    description
    enabledFacebook
    enabledInstagram
    enabledLinkedin
    enabledPinterest
    enabledTiktok
    enabledTwitter
    enabledYoutube
    facebook {
      caption
      postID
      postToStory
      storyID
      storyURL
      targetPageID
      thumbnail
    }
    id
    instagram {
      caption
      postID
      postToStory
      storyID
      targetAccountID
      thumbnail
    }
    linkedin {
      caption
      mediaDescription
      postID
    }
    pinterest {
      boardID
      description
      link
      pinID
      thumbnailURL
      title
    }
    postedTime
    postedToFacebook
    postedToInstagram
    postedToLinkedin
    postedToPinterest
    postedToTiktok
    postedToTwitter
    postedToYoutube
    s3Key
    s3KeyResized
    scheduledPostTime
    status
    tiktok {
      caption
      contentDisclosureBrandedContent
      contentDisclosureEnabled
      contentDisclosureYourBrand
      postID
      privacy
      title
      usersCanComment
      usersCanDuet
      usersCanStitch
      videoCoverTimestampMs
    }
    title
    twitter {
      caption
      tweetID
    }
    updatedAt
    userID
    youtube {
      category
      description
      privacy
      tags
      targetChannelID
      thumbnailURL
      title
      videoID
    }
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateSocialManPostSubscriptionVariables,
  APITypes.OnCreateSocialManPostSubscription
>;
export const onCreateTaskReaction = /* GraphQL */ `subscription OnCreateTaskReaction(
  $filter: ModelSubscriptionTaskReactionFilterInput
) {
  onCreateTaskReaction(filter: $filter) {
    createdAt
    mltaskexecutionresultID
    type
    updatedAt
    userID
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateTaskReactionSubscriptionVariables,
  APITypes.OnCreateTaskReactionSubscription
>;
export const onCreateUser = /* GraphQL */ `subscription OnCreateUser(
  $filter: ModelSubscriptionUserFilterInput
  $id: String
) {
  onCreateUser(filter: $filter, id: $id) {
    MLTaskExecutionResults {
      items {
        ANGRYReactionStats
        CUTEReactionStats
        FUNNYReactionStats
        GROSSReactionStats
        HEARTReactionStats
        POOPReactionStats
        SADReactionStats
        SCARYReactionStats
        WOWReactionStats
        createdAt
        creditsUsed
        id
        mltasksworkspaceID
        modelName
        modelsID
        privacyLevel
        status
        taskName
        updatedAt
        userID
      }
      nextToken
    }
    MLTasksWorkspaces {
      items {
        createdAt
        description
        id
        name
        updatedAt
        userID
      }
      nextToken
    }
    ModelInputPresets {
      items {
        cost
        createdAt
        description
        id
        isOpenSource
        isPublic
        modelName
        modelsID
        presetName
        taskName
        updatedAt
        usage
        userID
        username
      }
      nextToken
    }
    SocialManPosts {
      items {
        createdAt
        description
        enabledFacebook
        enabledInstagram
        enabledLinkedin
        enabledPinterest
        enabledTiktok
        enabledTwitter
        enabledYoutube
        id
        postedTime
        postedToFacebook
        postedToInstagram
        postedToLinkedin
        postedToPinterest
        postedToTiktok
        postedToTwitter
        postedToYoutube
        s3Key
        s3KeyResized
        scheduledPostTime
        status
        title
        updatedAt
        userID
      }
      nextToken
    }
    completedTasksCount
    createdAt
    createdPresetCount
    credits
    currentSubscriptionPlan
    email
    facebook {
      instagramsData {
        accessToken
        biography
        connectedPageID
        expiresOn
        followersCount
        followsCount
        id
        igID
        mediaCount
        name
        profilePictureURL
        username
      }
      pagesData {
        accessToken
        category
        expiresOn
        id
        name
        picture
      }
      userData {
        accessToken
        expiresOn
        id
        name
        picture
      }
    }
    google {
      channelsData {
        customUrl
        description
        id
        thumbnail
        title
      }
      tokenData {
        accessToken
        expiresOn
        idToken
        refreshToken
        scope
        tokenType
      }
      userData {
        email
        familyName
        givenName
        id
        locale
        name
        picture
        verifiedEmail
      }
    }
    id
    linkedin {
      tokenData {
        accessToken
        accessTokenExpirationDate
        scope
      }
      userData {
        email
        name
        picture
        sub
      }
    }
    name
    periodEndOfLastSubscriptionCredits
    picture
    pinterest {
      boardsData {
        createdAt
        description
        followerCount
        id
        imageCoverUrl
        name
        pinCount
        privacy
      }
      tokenData {
        accessToken
        accessTokenExpirationDate
        refreshToken
        refreshTokenExpirationDate
        scope
      }
      userData {
        about
        accountType
        boardCount
        businessName
        followerCount
        followingCount
        id
        monthlyViews
        pinCount
        profileImage
        username
        websiteUrl
      }
    }
    stripeConnectAccountID
    stripeConnectAccountSetupComplete
    stripeCustomerID
    tiktok {
      tokenData {
        accessToken
        accessTokenExpirationDate
        refreshToken
        refreshTokenExpirationDate
        scope
      }
      userData {
        avatarLargeURL
        avatarURL
        avatarURL100
        bioDescription
        commentDisabled
        displayName
        duetDisabled
        followerCount
        followingCount
        isVerified
        likesCount
        maxVideoPostDuration_sec
        privacyLevelOptions
        profileDeepLink
        stitchDisabled
        username
        videoCount
      }
    }
    twitter {
      tokenData {
        accessToken
        accessTokenSecret
        codeVerifier
        expiresOn
        oauthTokenSecret
        refreshToken
      }
      userData {
        description
        id
        name
        profilePictureURL
        username
        verifiedType
      }
    }
    updatedAt
    username
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateUserSubscriptionVariables,
  APITypes.OnCreateUserSubscription
>;
export const onDeleteMLTaskData = /* GraphQL */ `subscription OnDeleteMLTaskData(
  $filter: ModelSubscriptionMLTaskDataFilterInput
) {
  onDeleteMLTaskData(filter: $filter) {
    Models {
      items {
        createdAt
        id
        isPublic
        mltaskdataID
        name
        updatedAt
      }
      nextToken
    }
    category
    createdAt
    id
    isPublic
    name
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteMLTaskDataSubscriptionVariables,
  APITypes.OnDeleteMLTaskDataSubscription
>;
export const onDeleteMLTaskExecutionResult = /* GraphQL */ `subscription OnDeleteMLTaskExecutionResult(
  $filter: ModelSubscriptionMLTaskExecutionResultFilterInput
  $userID: String
) {
  onDeleteMLTaskExecutionResult(filter: $filter, userID: $userID) {
    ANGRYReactionStats
    CUTEReactionStats
    FUNNYReactionStats
    GROSSReactionStats
    HEARTReactionStats
    POOPReactionStats
    Reactions {
      items {
        createdAt
        mltaskexecutionresultID
        type
        updatedAt
        userID
      }
      nextToken
    }
    SADReactionStats
    SCARYReactionStats
    WOWReactionStats
    createdAt
    creditsUsed
    id
    inputs {
      metaData {
        key
        value
      }
      name
      type
      value
    }
    mltasksworkspaceID
    modelName
    modelsID
    output {
      entries {
        name
        type
        value
      }
      message
    }
    presetUserInput {
      presetCreatorName
      presetCreatorUID
      presetID
      presetName
      presetValues {
        modelInputName
      }
    }
    privacyLevel
    status
    taskName
    updatedAt
    userID
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteMLTaskExecutionResultSubscriptionVariables,
  APITypes.OnDeleteMLTaskExecutionResultSubscription
>;
export const onDeleteMLTasksWorkspace = /* GraphQL */ `subscription OnDeleteMLTasksWorkspace(
  $filter: ModelSubscriptionMLTasksWorkspaceFilterInput
  $userID: String
) {
  onDeleteMLTasksWorkspace(filter: $filter, userID: $userID) {
    MLTaskExecutionResults {
      items {
        ANGRYReactionStats
        CUTEReactionStats
        FUNNYReactionStats
        GROSSReactionStats
        HEARTReactionStats
        POOPReactionStats
        SADReactionStats
        SCARYReactionStats
        WOWReactionStats
        createdAt
        creditsUsed
        id
        mltasksworkspaceID
        modelName
        modelsID
        privacyLevel
        status
        taskName
        updatedAt
        userID
      }
      nextToken
    }
    createdAt
    description
    id
    name
    updatedAt
    userID
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteMLTasksWorkspaceSubscriptionVariables,
  APITypes.OnDeleteMLTasksWorkspaceSubscription
>;
export const onDeleteModelInputPreset = /* GraphQL */ `subscription OnDeleteModelInputPreset(
  $filter: ModelSubscriptionModelInputPresetFilterInput
  $userID: String
) {
  onDeleteModelInputPreset(filter: $filter, userID: $userID) {
    cost
    createdAt
    description
    entries {
      modelInputName
      modelInputType
      presetEntryDatas {
        description
        entryType
        parameterName
        presetOrder
        val
      }
    }
    id
    isOpenSource
    isPublic
    modelName
    modelsID
    presetName
    taskName
    updatedAt
    usage
    userID
    username
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteModelInputPresetSubscriptionVariables,
  APITypes.OnDeleteModelInputPresetSubscription
>;
export const onDeleteModels = /* GraphQL */ `subscription OnDeleteModels($filter: ModelSubscriptionModelsFilterInput) {
  onDeleteModels(filter: $filter) {
    MLTaskExecutionResults {
      items {
        ANGRYReactionStats
        CUTEReactionStats
        FUNNYReactionStats
        GROSSReactionStats
        HEARTReactionStats
        POOPReactionStats
        SADReactionStats
        SCARYReactionStats
        WOWReactionStats
        createdAt
        creditsUsed
        id
        mltasksworkspaceID
        modelName
        modelsID
        privacyLevel
        status
        taskName
        updatedAt
        userID
      }
      nextToken
    }
    ModelInputPresets {
      items {
        cost
        createdAt
        description
        id
        isOpenSource
        isPublic
        modelName
        modelsID
        presetName
        taskName
        updatedAt
        usage
        userID
        username
      }
      nextToken
    }
    createdAt
    id
    inputs {
      optional {
        defaultValue
        description
        examples
        name
        optionStep
        optionType
        type
      }
      required {
        defaultValue
        description
        examples
        name
        optionStep
        optionType
        type
      }
    }
    isPublic
    mltaskdataID
    name
    outputs {
      entries {
        name
        type
      }
    }
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteModelsSubscriptionVariables,
  APITypes.OnDeleteModelsSubscription
>;
export const onDeleteSocialManPost = /* GraphQL */ `subscription OnDeleteSocialManPost(
  $filter: ModelSubscriptionSocialManPostFilterInput
  $userID: String
) {
  onDeleteSocialManPost(filter: $filter, userID: $userID) {
    createdAt
    description
    enabledFacebook
    enabledInstagram
    enabledLinkedin
    enabledPinterest
    enabledTiktok
    enabledTwitter
    enabledYoutube
    facebook {
      caption
      postID
      postToStory
      storyID
      storyURL
      targetPageID
      thumbnail
    }
    id
    instagram {
      caption
      postID
      postToStory
      storyID
      targetAccountID
      thumbnail
    }
    linkedin {
      caption
      mediaDescription
      postID
    }
    pinterest {
      boardID
      description
      link
      pinID
      thumbnailURL
      title
    }
    postedTime
    postedToFacebook
    postedToInstagram
    postedToLinkedin
    postedToPinterest
    postedToTiktok
    postedToTwitter
    postedToYoutube
    s3Key
    s3KeyResized
    scheduledPostTime
    status
    tiktok {
      caption
      contentDisclosureBrandedContent
      contentDisclosureEnabled
      contentDisclosureYourBrand
      postID
      privacy
      title
      usersCanComment
      usersCanDuet
      usersCanStitch
      videoCoverTimestampMs
    }
    title
    twitter {
      caption
      tweetID
    }
    updatedAt
    userID
    youtube {
      category
      description
      privacy
      tags
      targetChannelID
      thumbnailURL
      title
      videoID
    }
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteSocialManPostSubscriptionVariables,
  APITypes.OnDeleteSocialManPostSubscription
>;
export const onDeleteTaskReaction = /* GraphQL */ `subscription OnDeleteTaskReaction(
  $filter: ModelSubscriptionTaskReactionFilterInput
) {
  onDeleteTaskReaction(filter: $filter) {
    createdAt
    mltaskexecutionresultID
    type
    updatedAt
    userID
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteTaskReactionSubscriptionVariables,
  APITypes.OnDeleteTaskReactionSubscription
>;
export const onDeleteUser = /* GraphQL */ `subscription OnDeleteUser(
  $filter: ModelSubscriptionUserFilterInput
  $id: String
) {
  onDeleteUser(filter: $filter, id: $id) {
    MLTaskExecutionResults {
      items {
        ANGRYReactionStats
        CUTEReactionStats
        FUNNYReactionStats
        GROSSReactionStats
        HEARTReactionStats
        POOPReactionStats
        SADReactionStats
        SCARYReactionStats
        WOWReactionStats
        createdAt
        creditsUsed
        id
        mltasksworkspaceID
        modelName
        modelsID
        privacyLevel
        status
        taskName
        updatedAt
        userID
      }
      nextToken
    }
    MLTasksWorkspaces {
      items {
        createdAt
        description
        id
        name
        updatedAt
        userID
      }
      nextToken
    }
    ModelInputPresets {
      items {
        cost
        createdAt
        description
        id
        isOpenSource
        isPublic
        modelName
        modelsID
        presetName
        taskName
        updatedAt
        usage
        userID
        username
      }
      nextToken
    }
    SocialManPosts {
      items {
        createdAt
        description
        enabledFacebook
        enabledInstagram
        enabledLinkedin
        enabledPinterest
        enabledTiktok
        enabledTwitter
        enabledYoutube
        id
        postedTime
        postedToFacebook
        postedToInstagram
        postedToLinkedin
        postedToPinterest
        postedToTiktok
        postedToTwitter
        postedToYoutube
        s3Key
        s3KeyResized
        scheduledPostTime
        status
        title
        updatedAt
        userID
      }
      nextToken
    }
    completedTasksCount
    createdAt
    createdPresetCount
    credits
    currentSubscriptionPlan
    email
    facebook {
      instagramsData {
        accessToken
        biography
        connectedPageID
        expiresOn
        followersCount
        followsCount
        id
        igID
        mediaCount
        name
        profilePictureURL
        username
      }
      pagesData {
        accessToken
        category
        expiresOn
        id
        name
        picture
      }
      userData {
        accessToken
        expiresOn
        id
        name
        picture
      }
    }
    google {
      channelsData {
        customUrl
        description
        id
        thumbnail
        title
      }
      tokenData {
        accessToken
        expiresOn
        idToken
        refreshToken
        scope
        tokenType
      }
      userData {
        email
        familyName
        givenName
        id
        locale
        name
        picture
        verifiedEmail
      }
    }
    id
    linkedin {
      tokenData {
        accessToken
        accessTokenExpirationDate
        scope
      }
      userData {
        email
        name
        picture
        sub
      }
    }
    name
    periodEndOfLastSubscriptionCredits
    picture
    pinterest {
      boardsData {
        createdAt
        description
        followerCount
        id
        imageCoverUrl
        name
        pinCount
        privacy
      }
      tokenData {
        accessToken
        accessTokenExpirationDate
        refreshToken
        refreshTokenExpirationDate
        scope
      }
      userData {
        about
        accountType
        boardCount
        businessName
        followerCount
        followingCount
        id
        monthlyViews
        pinCount
        profileImage
        username
        websiteUrl
      }
    }
    stripeConnectAccountID
    stripeConnectAccountSetupComplete
    stripeCustomerID
    tiktok {
      tokenData {
        accessToken
        accessTokenExpirationDate
        refreshToken
        refreshTokenExpirationDate
        scope
      }
      userData {
        avatarLargeURL
        avatarURL
        avatarURL100
        bioDescription
        commentDisabled
        displayName
        duetDisabled
        followerCount
        followingCount
        isVerified
        likesCount
        maxVideoPostDuration_sec
        privacyLevelOptions
        profileDeepLink
        stitchDisabled
        username
        videoCount
      }
    }
    twitter {
      tokenData {
        accessToken
        accessTokenSecret
        codeVerifier
        expiresOn
        oauthTokenSecret
        refreshToken
      }
      userData {
        description
        id
        name
        profilePictureURL
        username
        verifiedType
      }
    }
    updatedAt
    username
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteUserSubscriptionVariables,
  APITypes.OnDeleteUserSubscription
>;
export const onUpdateMLTaskData = /* GraphQL */ `subscription OnUpdateMLTaskData(
  $filter: ModelSubscriptionMLTaskDataFilterInput
) {
  onUpdateMLTaskData(filter: $filter) {
    Models {
      items {
        createdAt
        id
        isPublic
        mltaskdataID
        name
        updatedAt
      }
      nextToken
    }
    category
    createdAt
    id
    isPublic
    name
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateMLTaskDataSubscriptionVariables,
  APITypes.OnUpdateMLTaskDataSubscription
>;
export const onUpdateMLTaskExecutionResult = /* GraphQL */ `subscription OnUpdateMLTaskExecutionResult(
  $filter: ModelSubscriptionMLTaskExecutionResultFilterInput
  $userID: String
) {
  onUpdateMLTaskExecutionResult(filter: $filter, userID: $userID) {
    ANGRYReactionStats
    CUTEReactionStats
    FUNNYReactionStats
    GROSSReactionStats
    HEARTReactionStats
    POOPReactionStats
    Reactions {
      items {
        createdAt
        mltaskexecutionresultID
        type
        updatedAt
        userID
      }
      nextToken
    }
    SADReactionStats
    SCARYReactionStats
    WOWReactionStats
    createdAt
    creditsUsed
    id
    inputs {
      metaData {
        key
        value
      }
      name
      type
      value
    }
    mltasksworkspaceID
    modelName
    modelsID
    output {
      entries {
        name
        type
        value
      }
      message
    }
    presetUserInput {
      presetCreatorName
      presetCreatorUID
      presetID
      presetName
      presetValues {
        modelInputName
      }
    }
    privacyLevel
    status
    taskName
    updatedAt
    userID
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateMLTaskExecutionResultSubscriptionVariables,
  APITypes.OnUpdateMLTaskExecutionResultSubscription
>;
export const onUpdateMLTasksWorkspace = /* GraphQL */ `subscription OnUpdateMLTasksWorkspace(
  $filter: ModelSubscriptionMLTasksWorkspaceFilterInput
  $userID: String
) {
  onUpdateMLTasksWorkspace(filter: $filter, userID: $userID) {
    MLTaskExecutionResults {
      items {
        ANGRYReactionStats
        CUTEReactionStats
        FUNNYReactionStats
        GROSSReactionStats
        HEARTReactionStats
        POOPReactionStats
        SADReactionStats
        SCARYReactionStats
        WOWReactionStats
        createdAt
        creditsUsed
        id
        mltasksworkspaceID
        modelName
        modelsID
        privacyLevel
        status
        taskName
        updatedAt
        userID
      }
      nextToken
    }
    createdAt
    description
    id
    name
    updatedAt
    userID
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateMLTasksWorkspaceSubscriptionVariables,
  APITypes.OnUpdateMLTasksWorkspaceSubscription
>;
export const onUpdateModelInputPreset = /* GraphQL */ `subscription OnUpdateModelInputPreset(
  $filter: ModelSubscriptionModelInputPresetFilterInput
  $userID: String
) {
  onUpdateModelInputPreset(filter: $filter, userID: $userID) {
    cost
    createdAt
    description
    entries {
      modelInputName
      modelInputType
      presetEntryDatas {
        description
        entryType
        parameterName
        presetOrder
        val
      }
    }
    id
    isOpenSource
    isPublic
    modelName
    modelsID
    presetName
    taskName
    updatedAt
    usage
    userID
    username
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateModelInputPresetSubscriptionVariables,
  APITypes.OnUpdateModelInputPresetSubscription
>;
export const onUpdateModels = /* GraphQL */ `subscription OnUpdateModels($filter: ModelSubscriptionModelsFilterInput) {
  onUpdateModels(filter: $filter) {
    MLTaskExecutionResults {
      items {
        ANGRYReactionStats
        CUTEReactionStats
        FUNNYReactionStats
        GROSSReactionStats
        HEARTReactionStats
        POOPReactionStats
        SADReactionStats
        SCARYReactionStats
        WOWReactionStats
        createdAt
        creditsUsed
        id
        mltasksworkspaceID
        modelName
        modelsID
        privacyLevel
        status
        taskName
        updatedAt
        userID
      }
      nextToken
    }
    ModelInputPresets {
      items {
        cost
        createdAt
        description
        id
        isOpenSource
        isPublic
        modelName
        modelsID
        presetName
        taskName
        updatedAt
        usage
        userID
        username
      }
      nextToken
    }
    createdAt
    id
    inputs {
      optional {
        defaultValue
        description
        examples
        name
        optionStep
        optionType
        type
      }
      required {
        defaultValue
        description
        examples
        name
        optionStep
        optionType
        type
      }
    }
    isPublic
    mltaskdataID
    name
    outputs {
      entries {
        name
        type
      }
    }
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateModelsSubscriptionVariables,
  APITypes.OnUpdateModelsSubscription
>;
export const onUpdateSocialManPost = /* GraphQL */ `subscription OnUpdateSocialManPost(
  $filter: ModelSubscriptionSocialManPostFilterInput
  $userID: String
) {
  onUpdateSocialManPost(filter: $filter, userID: $userID) {
    createdAt
    description
    enabledFacebook
    enabledInstagram
    enabledLinkedin
    enabledPinterest
    enabledTiktok
    enabledTwitter
    enabledYoutube
    facebook {
      caption
      postID
      postToStory
      storyID
      storyURL
      targetPageID
      thumbnail
    }
    id
    instagram {
      caption
      postID
      postToStory
      storyID
      targetAccountID
      thumbnail
    }
    linkedin {
      caption
      mediaDescription
      postID
    }
    pinterest {
      boardID
      description
      link
      pinID
      thumbnailURL
      title
    }
    postedTime
    postedToFacebook
    postedToInstagram
    postedToLinkedin
    postedToPinterest
    postedToTiktok
    postedToTwitter
    postedToYoutube
    s3Key
    s3KeyResized
    scheduledPostTime
    status
    tiktok {
      caption
      contentDisclosureBrandedContent
      contentDisclosureEnabled
      contentDisclosureYourBrand
      postID
      privacy
      title
      usersCanComment
      usersCanDuet
      usersCanStitch
      videoCoverTimestampMs
    }
    title
    twitter {
      caption
      tweetID
    }
    updatedAt
    userID
    youtube {
      category
      description
      privacy
      tags
      targetChannelID
      thumbnailURL
      title
      videoID
    }
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateSocialManPostSubscriptionVariables,
  APITypes.OnUpdateSocialManPostSubscription
>;
export const onUpdateTaskReaction = /* GraphQL */ `subscription OnUpdateTaskReaction(
  $filter: ModelSubscriptionTaskReactionFilterInput
) {
  onUpdateTaskReaction(filter: $filter) {
    createdAt
    mltaskexecutionresultID
    type
    updatedAt
    userID
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateTaskReactionSubscriptionVariables,
  APITypes.OnUpdateTaskReactionSubscription
>;
export const onUpdateUser = /* GraphQL */ `subscription OnUpdateUser(
  $filter: ModelSubscriptionUserFilterInput
  $id: String
) {
  onUpdateUser(filter: $filter, id: $id) {
    MLTaskExecutionResults {
      items {
        ANGRYReactionStats
        CUTEReactionStats
        FUNNYReactionStats
        GROSSReactionStats
        HEARTReactionStats
        POOPReactionStats
        SADReactionStats
        SCARYReactionStats
        WOWReactionStats
        createdAt
        creditsUsed
        id
        mltasksworkspaceID
        modelName
        modelsID
        privacyLevel
        status
        taskName
        updatedAt
        userID
      }
      nextToken
    }
    MLTasksWorkspaces {
      items {
        createdAt
        description
        id
        name
        updatedAt
        userID
      }
      nextToken
    }
    ModelInputPresets {
      items {
        cost
        createdAt
        description
        id
        isOpenSource
        isPublic
        modelName
        modelsID
        presetName
        taskName
        updatedAt
        usage
        userID
        username
      }
      nextToken
    }
    SocialManPosts {
      items {
        createdAt
        description
        enabledFacebook
        enabledInstagram
        enabledLinkedin
        enabledPinterest
        enabledTiktok
        enabledTwitter
        enabledYoutube
        id
        postedTime
        postedToFacebook
        postedToInstagram
        postedToLinkedin
        postedToPinterest
        postedToTiktok
        postedToTwitter
        postedToYoutube
        s3Key
        s3KeyResized
        scheduledPostTime
        status
        title
        updatedAt
        userID
      }
      nextToken
    }
    completedTasksCount
    createdAt
    createdPresetCount
    credits
    currentSubscriptionPlan
    email
    facebook {
      instagramsData {
        accessToken
        biography
        connectedPageID
        expiresOn
        followersCount
        followsCount
        id
        igID
        mediaCount
        name
        profilePictureURL
        username
      }
      pagesData {
        accessToken
        category
        expiresOn
        id
        name
        picture
      }
      userData {
        accessToken
        expiresOn
        id
        name
        picture
      }
    }
    google {
      channelsData {
        customUrl
        description
        id
        thumbnail
        title
      }
      tokenData {
        accessToken
        expiresOn
        idToken
        refreshToken
        scope
        tokenType
      }
      userData {
        email
        familyName
        givenName
        id
        locale
        name
        picture
        verifiedEmail
      }
    }
    id
    linkedin {
      tokenData {
        accessToken
        accessTokenExpirationDate
        scope
      }
      userData {
        email
        name
        picture
        sub
      }
    }
    name
    periodEndOfLastSubscriptionCredits
    picture
    pinterest {
      boardsData {
        createdAt
        description
        followerCount
        id
        imageCoverUrl
        name
        pinCount
        privacy
      }
      tokenData {
        accessToken
        accessTokenExpirationDate
        refreshToken
        refreshTokenExpirationDate
        scope
      }
      userData {
        about
        accountType
        boardCount
        businessName
        followerCount
        followingCount
        id
        monthlyViews
        pinCount
        profileImage
        username
        websiteUrl
      }
    }
    stripeConnectAccountID
    stripeConnectAccountSetupComplete
    stripeCustomerID
    tiktok {
      tokenData {
        accessToken
        accessTokenExpirationDate
        refreshToken
        refreshTokenExpirationDate
        scope
      }
      userData {
        avatarLargeURL
        avatarURL
        avatarURL100
        bioDescription
        commentDisabled
        displayName
        duetDisabled
        followerCount
        followingCount
        isVerified
        likesCount
        maxVideoPostDuration_sec
        privacyLevelOptions
        profileDeepLink
        stitchDisabled
        username
        videoCount
      }
    }
    twitter {
      tokenData {
        accessToken
        accessTokenSecret
        codeVerifier
        expiresOn
        oauthTokenSecret
        refreshToken
      }
      userData {
        description
        id
        name
        profilePictureURL
        username
        verifiedType
      }
    }
    updatedAt
    username
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateUserSubscriptionVariables,
  APITypes.OnUpdateUserSubscription
>;
