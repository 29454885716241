export function dateInXSecondsFromNow(seconds: number) {
  const now = new Date();
  const expiration = new Date(now.getTime() + seconds * 1000);
  return expiration;
}
export function dateIn1HourFromNow() {
  return dateInXSecondsFromNow(3600);
}

export function isDateExpired(dateString: string): boolean {
  const inputDate = new Date(dateString);
  if (isNaN(inputDate.getTime())) {
    throw new Error("Invalid date string format");
  }
  const currentDate = new Date();
  return inputDate < currentDate;
}
