import { post } from "aws-amplify/api";
import { AxiosError } from "axios";
import Constants from "@/utils/Constants";

import { InternalCreditsPriceID, InternalSubscriptionPriceID } from "@/typings";
import { StripeInvoice, StripePayoutHistoryItem } from "@/modeltypings";
import AWSGQLManager from "./AWSGQLManager";
import AWSCognitoManager from "./AWSCognitoManager";

export class AWSStripeManager {
  protected static man: AWSStripeManager;
  public static get Instance() {
    if (!this.man) {
      this.man = new AWSStripeManager();
    }
    return this.man;
  }
  //STRIPE
  async getUserStripeId(): Promise<string | undefined> {
    return new Promise(async (resolve) => {
      const user = await AWSCognitoManager.getCurrentCognitoUser();
      resolve(user?.stripeID);
    });
  }

  async getUserStripeConnectAccountId(): Promise<string | undefined> {
    return new Promise(async (resolve) => {
      const user = await AWSGQLManager.fetchCurrentUser();
      resolve(user?.stripeConnectAccountID);
    });
  }

  async createStripeConnectAccount() {
    const postInit = {
      body: {},
    };
    const user = await AWSGQLManager.fetchCurrentUser();
    return new Promise(async (resolve, reject) => {
      try {
        const res = await post({
          apiName: process.env.NEXT_PUBLIC_API_NAME!,
          path: "/stripe/create-connect-account",
          options: postInit,
        });

        const responseBody = (await res.response).body;
        const responseBodyJSON = (await responseBody.json()) as {
          [prop: string]: any;
        };
        const body = responseBodyJSON.body;
        const account = body.account;

        const connectAccountID = account.id;
        AWSGQLManager.updateUserStripeConnectAccountID(
          user!.id,
          connectAccountID
        );
        resolve(connectAccountID);
      } catch (error) {
        // console.log("failed to complete with erro");
        // console.log(error);
        reject(error);
      }
    });
  }

  async deleteStripeConnectAccount() {
    return new Promise(async (resolve, reject) => {
      try {
        const user = await AWSGQLManager.fetchCurrentUser();
        const postInit = {
          body: { connectAccountID: user!.stripeConnectAccountID },
        };
        const deleteionResponse = await post({
          apiName: process.env.NEXT_PUBLIC_API_NAME!,
          path: "/stripe/delete-connect-account",
          options: postInit,
        });
        const responseBody = (await deleteionResponse.response).body;
        // const responseBodyJSON = (await responseBody.json()) as {
        //   [prop: string]: any;
        // };
        // const body = responseBodyJSON.body;

        resolve({});
      } catch (error) {
        console.log("failed to complete with erro");
        console.log(error);
        reject(error);
      }
    });
  }

  async getStripeConnectAccountLink() {
    const connectAccountID = await this.getUserStripeConnectAccountId();

    // const userC = await Auth.currentAuthenticatedUser();
    // const token = userC.signInUserSession.idToken.jwtToken;

    const postInit = {
      body: { connectAccountID: connectAccountID! },
    };

    const request = post({
      apiName: process.env.NEXT_PUBLIC_API_NAME!,
      path: "/stripe/get-connect-account-link",
      options: postInit,
    });

    const responseBody = (await request.response).body;
    const responseBodyJSON = (await responseBody.json()) as {
      [prop: string]: any;
    };
    const body = responseBodyJSON.body;
    return body.accountLink.url;
  }

  async getStripeConnectAccountPayoutHistory(): Promise<
    StripePayoutHistoryItem[]
  > {
    return new Promise(async (resolve, reject) => {
      try {
        const connectAccountID = await this.getUserStripeConnectAccountId();
        const postInit = {
          body: { connectAccountID: connectAccountID! },
        };

        const payoutsResponse = await post({
          apiName: process.env.NEXT_PUBLIC_API_NAME!,
          path: "/stripe/get-connect-account-payout-history",
          options: postInit,
        });

        const responseBody = (await payoutsResponse.response).body;
        const responseBodyJSON = (await responseBody.json()) as {
          [prop: string]: any;
        };
        const body = responseBodyJSON.body;

        const payouts = body["payouts"] as StripePayoutHistoryItem[];
        resolve(payouts);
      } catch (error) {
        reject(error);
      }
    });
  }

  async getStripeConnectAccountLoginLink(): Promise<string> {
    const connectAccountID = await this.getUserStripeConnectAccountId();
    const postInit = {
      body: { connectAccountID: connectAccountID! },
    };

    const request = post({
      apiName: process.env.NEXT_PUBLIC_API_NAME!,
      path: "/stripe/get-connect-account-login-link",
      options: postInit,
    });

    const responseBody = (await request.response).body;
    const responseBodyJSON = (await responseBody.json()) as {
      [prop: string]: any;
    };
    const body = responseBodyJSON.body;
    return body.loginLink.url;
  }

  async checkStripeConnectAccountCompletedSetupAndUpdateUser(): Promise<
    boolean | undefined
  > {
    const user = await AWSGQLManager.fetchCurrentUser();
    const connectAccountID = user?.stripeConnectAccountID;

    const postInit = {
      body: { connectAccountID: connectAccountID! },
    };

    return new Promise(async (resolve, reject) => {
      try {
        const res = await post({
          apiName: process.env.NEXT_PUBLIC_API_NAME!,
          path: "/stripe/get-connect-account-data",
          options: postInit,
        });
        const responseBody = (await res.response).body;
        const responseBodyJSON = (await responseBody.json()) as {
          [prop: string]: any;
        };
        const body = responseBodyJSON.body;

        const accountData = body.accountData;
        if (accountData.charges_enabled == true) {
          return AWSGQLManager.setUserStripeAccountSetupComplete(user!.id);
        } else {
          resolve(false);
        }
      } catch (error) {
        reject(error);
      }
    });
  }

  async getPaymentHistory(): Promise<StripeInvoice[]> {
    return new Promise(async (resolve, reject) => {
      try {
        const customerId = await this.getUserStripeId();
        const postInit = {
          body: { customer: customerId! },
        };
        const payments = await post({
          apiName: process.env.NEXT_PUBLIC_API_NAME!,
          path: "/stripe/get-payment-history",
          options: postInit,
        });
        const responseBody = (await payments.response).body;
        const responseBodyJSON = (await responseBody.json()) as {
          [prop: string]: any;
        };
        const body = responseBodyJSON.body;
        const invoices = body.invoices as StripeInvoice[];
        resolve(invoices);
      } catch (error) {
        reject(error);
      }
    });
  }

  async cashOut(amount: number) {
    return new Promise(async (resolve, reject) => {
      try {
        // const userC = await Auth.currentAuthenticatedUser();
        // const token = userC.signInUserSession.idToken.jwtToken;

        const user = await AWSGQLManager.fetchCurrentUser();
        const postInit = {
          // headers: {
          //   Authorization: `Bearer ${token}`,
          //   "Content-Type": "application/json",
          // },
          body: { userID: user!.id, amount },
        };
        const request = await post({
          apiName: process.env.NEXT_PUBLIC_API_NAME!,
          path: "/stripe/cash-out",
          options: postInit,
        });
        const responseBody = (await request.response).body;
        const responseBodyJSON = (await responseBody.json()) as {
          [prop: string]: any;
        };
        const body = responseBodyJSON.body;

        resolve({});
      } catch (error) {
        const axiosError = (error as AxiosError)?.response;
        // const customCode = axiosError?.data["customCode"];
        // console.log(JSON.stringify(axiosError, null, 4));
        var finalErrorMessage = axiosError?.data ?? "Error";
        finalErrorMessage =
          finalErrorMessage == null
            ? Constants.contactUsErrorMessage
            : finalErrorMessage;
        reject(finalErrorMessage);
      }
    });
  }
  //more suitable for subscriptions which we don't currently support
  async getCustomerPortal() {
    const customerId = await this.getUserStripeId();
    const postInit = {
      body: { customer: customerId! },
    };
    const request = post({
      apiName: process.env.NEXT_PUBLIC_API_NAME!,
      path: "/stripe/create-customer-portal-session",
      options: postInit,
    });
    const responseBody = (await request.response).body;
    const responseBodyJSON = (await responseBody.json()) as {
      [prop: string]: any;
    };
    const body = responseBodyJSON.body;
    return body.url;
  }

  async stripeCheckout(
    internalPriceID: InternalCreditsPriceID,
    dontRedirect: boolean
  ): Promise<{ clientSecret: string; url: string }> {
    const customerId = await this.getUserStripeId();
    const postInit = {
      body: { internalPriceID, customer: customerId!, dontRedirect },
    };

    const stripeRequest = post({
      apiName: process.env.NEXT_PUBLIC_API_NAME!,
      path: "/stripe/create-checkout-session",
      options: postInit,
    });

    const responseBody = (await stripeRequest.response).body;
    const responseBodyJSON = (await responseBody.json()) as {
      [prop: string]: any;
    };
    const body = responseBodyJSON.body;
    const clientSecret = body.clientSecret as string;
    const url = body.url as string;
    return { clientSecret, url };
  }

  async stripeSubscriptionCheckout(
    internalPriceID: InternalSubscriptionPriceID
  ): Promise<{ url: string }> {
    const customerId = await this.getUserStripeId();
    const postInit = {
      body: { internalPriceID, customer: customerId! },
    };

    const stripeRequest = post({
      apiName: process.env.NEXT_PUBLIC_API_NAME!,
      path: "/stripe/create-subscription-checkout-session",
      options: postInit,
    });

    const responseBody = (await stripeRequest.response).body;
    const responseBodyJSON = (await responseBody.json()) as {
      [prop: string]: any;
    };
    const body = responseBodyJSON.body;
    const url = body.url as string;
    return { url };
  }

  async getStripeSessionStatus(session_id: string) {
    const postInit = {
      body: { session_id },
    };

    return post({
      apiName: process.env.NEXT_PUBLIC_API_NAME!,
      path: "/stripe/session-status",
      options: postInit,
    });
  }
}

export default AWSStripeManager.Instance;
