import {
  InternalCreditsPriceID,
  InternalSubscriptionPriceID,
  PagedTaskResults,
  TaskExecutionCardProps,
} from "@/typings";
import { GraphQLAuthMode } from "@aws-amplify/core/internals/utils";
import { Amplify } from "aws-amplify";
import { Hub } from "aws-amplify/utils";

import {
  LocalSocialManPost,
  PagedLocalSocialManPost,
  SocialManNetworks,
} from "@/app/SocialManmodeltypings";
import {
  CloudAccessLevels,
  ComfyTokenData,
  LocalModelInputPreset,
  LocalModelInputPresetEntryInput,
  LocalReactionType,
  LocalTaskPrivacyLevels,
  LocalUser,
  MLTaskPropertyEntryData,
  MLTasksData,
  ModelsInfo,
  PagedLocalModelInputPresetResults,
  StripeInvoice,
  StripePayoutHistoryItem,
  TaskExecutionData,
  TaskExecutionInput,
} from "@/modeltypings";
import { ModelInputOutputEntryType } from "@mltask/graphql/Codegen/API";
import { IBackendManagerInterface } from "../BackendManager";
import AWSAPIManager from "./AWSAPIManager";
import AWSCognitoManager from "./AWSCognitoManager";
import AWSGQLManager from "./AWSGQLManager";
import AWSS3Manager from "./AWSS3Manager";
import AWSStripeManager from "./AWSStripeManager";
import { aws_configs } from "./awsConfig";

type CognitoUserData = {
  id: string;
  email: string;
  name: string;
  picture: string;
  stripeID: string;
};

export class AWSManagerClass implements IBackendManagerInterface {
  protected static man: AWSManagerClass;
  public static get Instance() {
    if (!this.man) {
      this.man = new AWSManagerClass();
    }
    return this.man;
  }
  constructor() {
    //NOTE: amplify update auth --> Add/Edit redirect URIs
    // const defaultConfig = Cache.configure();
    // defaultConfig.capacityInBytes = 5242880;
    // (defaultConfig.itemMaxSize = 410000), Cache.configure(defaultConfig);
    Amplify.configure(aws_configs, {
      ssr: true, // required when using Amplify with Next.js
    });
    this.setupAuthListeners();
  }

  private setupAuthListeners() {
    // Hub.listen(/.*/, (data) => {
    Hub.listen("auth", (data: any) => {
      // console.log(
      //   "%c" + "AMPLIFY_event: " + data.payload.event,
      //   "color: #008080"
      // );
      switch (data.payload.event) {
        case "signedIn":
          this.signInListeners.forEach((x) => x?.());
          this.updateIdentityID();
          break;
        case "signedOut":
        case "oAuthSignOut":
          this.signOutListeners.forEach((x) => x?.());
          break;
        case "customOAuthState":
          this.customAuthStateListeners.forEach((x) => x?.(data.payload.data));
          break;
        case "configured":
          break;
        case "credentials_configured":
          break;
        case "signIn_failure":
          break;
        case "signUp":
          break;
        case "signUp_failure":
          break;
        case "confirmSignUp":
          this.confirmSignUpListeners.forEach((x) => x?.());
          break;
        case "completeNewPassword_failure":
          break;
        case "autoSignIn":
          break;
        case "autoSignIn_failure":
          break;
        case "forgotPassword":
          break;
        case "forgotPassword_failure":
          break;
        case "forgotPasswordSubmit":
          break;
        case "forgotPasswordSubmit_failure":
          break;
        case "tokenRefresh":
          break;
        case "tokenRefresh_failure":
          break;
        case "cognitoHostedUI":
          break;
        case "cognitoHostedUI_failure":
          break;
        case "customState_failure":
          break;
        case "parsingCallbackUrl":
          break;
        case "userDeleted":
          break;
      }
    });
  }
  currentUser: any = null;
  confirmSignUpListeners: (() => void)[] = [];
  signOutListeners: (() => void)[] = [];
  customAuthStateListeners: ((customState: string) => void)[] = [];
  signInListeners: (() => void)[] = [];

  addConfirmSignUpListener(listener: () => void) {
    // console.log("adding listener for sign up confirmation");
    this.confirmSignUpListeners.push(listener);
  }
  addSignInListener(listener: () => void) {
    // console.log("adding listener for sign In");
    this.signInListeners.push(listener);
  }
  addSignOutListener(listener: () => void) {
    // console.log("adding listener for sign out");
    this.signOutListeners.push(listener);
  }
  addCustomAuthStateListener(listener: (customState: string) => void) {
    // console.log("adding listener for sign out");
    this.customAuthStateListeners.push(listener);
  }
  //COGNITO
  async isUserLoggedIn(): Promise<boolean | undefined> {
    return AWSCognitoManager.isUserLoggedIn();
  }

  async fetchCurrentUserID(): Promise<string | undefined> {
    return AWSCognitoManager.fetchCurrentUserID();
  }

  async getCurrentCognitoUser(): Promise<CognitoUserData | undefined> {
    return AWSCognitoManager.getCurrentCognitoUser();
  }
  //AUTH
  async signInWithGoogle(customState?: string) {
    return AWSCognitoManager.signInWithGoogle(customState);
  }

  async logIn(username: string, password: string) {
    return AWSCognitoManager.logIn(username, password);
  }

  async signUserOut() {
    await AWSCognitoManager.signUserOut();
  }
  async clearCache() {
    await AWSCognitoManager.clearCache();
  }

  async signUp(email: string, password: string, name: string) {
    await AWSCognitoManager.signUp(email, password, name);
  }

  async confirmSignUp(email: string, verificationCode: string) {
    await AWSCognitoManager.confirmSignUp(email, verificationCode);
  }
  async forgotPassword(email: string) {
    await AWSCognitoManager.forgotPassword(email);
  }
  async forgotPasswordReset(email: string, code: string, password: string) {
    await AWSCognitoManager.forgotPasswordReset(email, code, password);
  }
  async resendConfirmation(email: string) {
    await AWSCognitoManager.resendConfirmation(email);
  }
  //GQL
  async getTasksGQL(): Promise<MLTasksData | undefined> {
    return AWSGQLManager.getTasksGQL();
  }
  async getTasksByIDGQL(
    taskID: string
  ): Promise<MLTaskPropertyEntryData | undefined> {
    return AWSGQLManager.getTasksByIDGQL(taskID);
  }

  async fetchModelTaskIDFromModelID(
    modelID: string
  ): Promise<string | undefined> {
    return AWSGQLManager.fetchModelTaskIDFromModelID(modelID);
  }

  async fetchModelPresetByID(
    presetID: string
  ): Promise<LocalModelInputPreset | undefined> {
    return AWSGQLManager.fetchModelPresetByID(presetID);
  }

  async updateUserUsername(username: string): Promise<LocalUser | undefined> {
    return AWSGQLManager.updateUserUsername(username);
  }
  async updateUserRemoveNetworkConnection(
    network: SocialManNetworks
  ): Promise<void> {
    return AWSGQLManager.updateUserRemoveNetworkConnection(network);
  }
  async fetchModelPresetByModelID(
    modelID: string
  ): Promise<PagedLocalModelInputPresetResults | undefined> {
    return AWSGQLManager.fetchModelPresetByModelID(modelID);
  }

  async fetchCurrentUserModelPresets(): Promise<
    PagedLocalModelInputPresetResults | undefined
  > {
    return AWSGQLManager.fetchCurrentUserModelPresets();
  }
  async fetchModelPresetByUserID(
    userID: string
  ): Promise<PagedLocalModelInputPresetResults | undefined> {
    return AWSGQLManager.fetchModelPresetByUserID(userID);
  }

  async createUserPresetForModel(
    modelsID: string,
    presetName: string,
    description: string,
    taskName: string,
    modelName: string,
    isPublic: boolean,
    isOpenSource: boolean,
    cost: number,
    entries: LocalModelInputPresetEntryInput[],
    progressCallback: (progress: any) => void
  ): Promise<LocalModelInputPreset> {
    return AWSGQLManager.createUserPresetForModel(
      modelsID,
      presetName,
      description,
      taskName,
      modelName,
      isPublic,
      isOpenSource,
      cost,
      entries,
      progressCallback
    );
  }
  async updateUserPresetForModel(
    originalPreset: LocalModelInputPreset,
    presetID: string,
    presetName: string,
    description?: string,
    isPublic?: boolean,
    isOpenSource?: boolean,
    cost?: number,
    entries?: LocalModelInputPresetEntryInput[]
  ): Promise<LocalModelInputPreset> {
    return AWSGQLManager.updateUserPresetForModel(
      originalPreset,
      presetID,
      presetName,
      description,
      isPublic,
      isOpenSource,
      cost,
      entries
    );
  }
  deleteUserPresetWithPresetID(presetID: string): Promise<string> {
    return AWSGQLManager.deleteUserPresetWithPresetID(presetID);
  }

  async fetchCurrentUserTaskExecutionResults(
    userId?: string,
    nextToken?: string
  ): Promise<PagedTaskResults | undefined> {
    return AWSGQLManager.fetchCurrentUserTaskExecutionResults(
      userId,
      nextToken
    );
  }

  async updateTaskExecutionResultsPrivacy(
    taskID: string,
    targetPrivacyLevel: LocalTaskPrivacyLevels
  ): Promise<boolean> {
    return AWSGQLManager.updateTaskExecutionResultsPrivacy(
      taskID,
      targetPrivacyLevel
    );
  }
  async addUserReactionToTask(
    userID: string,
    taskID: string,
    reactionType: LocalReactionType
  ): Promise<boolean> {
    return AWSGQLManager.addUserReactionToTask(userID, taskID, reactionType);
  }

  async deleteUserTaskWorkspaceWithWorkspaceID(
    workspaceID: string
  ): Promise<boolean | undefined> {
    return AWSGQLManager.deleteCurrentUserTaskWorkspace(workspaceID);
  }
  async deleteUserTaskResultWithTaskID(
    taskID: string
  ): Promise<boolean | undefined> {
    return AWSGQLManager.deleteCurrentUserTaskExecutionResult(taskID);
  }

  async getPublicTasks(
    nextToken?: string,
    authMode: GraphQLAuthMode = "userPool",
    limit?: number
  ): Promise<{ results: PagedTaskResults } | undefined> {
    return AWSGQLManager.getPublicTasks(nextToken, authMode, limit);
  }

  async createModel(
    modelData: ModelsInfo,
    isPublic: boolean
  ): Promise<ModelsInfo> {
    return AWSGQLManager.createModel(modelData, isPublic);
  }
  async updateModel(modelData: ModelsInfo): Promise<ModelsInfo> {
    return AWSGQLManager.updateModel(modelData);
  }

  async fetchCurrentUser(): Promise<LocalUser | undefined> {
    return AWSGQLManager.fetchCurrentUser();
  }
  async fetchUserByID(
    userID: string,
    authMode: GraphQLAuthMode = "userPool"
  ): Promise<LocalUser | undefined> {
    return AWSGQLManager.fetchUserByID(userID, authMode);
  }
  async batchGetUsersByIDs(ids: string[]): Promise<LocalUser[] | undefined> {
    return AWSGQLManager.batchGetUsersByIDs(ids);
  }
  async subscribeToUserCreditUpdate(
    userId: string | undefined,
    updatesCallback: (credits: number | null | undefined) => void,
    errorsCallback: (error: any) => void
  ) {
    AWSGQLManager.subscribeToUserCreditUpdate(
      userId,
      updatesCallback,
      errorsCallback
    );
  }
  async fetchCurrentUserCredits(): Promise<number | null | undefined> {
    return AWSGQLManager.fetchCurrentUserCredits();
  }

  unsubscribeToTaskExecutionUpdates(
    userId: string | undefined,
    taskId: string | undefined
  ) {
    AWSGQLManager.unsubscribeToTaskExecutionUpdates(userId, taskId);
  }
  async subscribeToTaskExecutionUpdates(
    userId: string | undefined,
    taskId: string | undefined,
    updatesCallback: (updatedTask: TaskExecutionCardProps) => void,
    errorsCallback: (error: any) => void
  ) {
    AWSGQLManager.subscribeToTaskExecutionUpdates(
      userId,
      taskId,
      updatesCallback,
      errorsCallback
    );
  }
  async subscribeToOnTaskResultCreation(
    userId: string | undefined
  ): Promise<TaskExecutionCardProps> {
    return AWSGQLManager.subscribeToOnTaskResultCreation(userId);
  }
  unsubscribeFromSocialManPostUpdates(
    userID: string | undefined,
    postID: string | undefined
  ) {
    return AWSGQLManager.unsubscribeFromSocialManPostUpdates(userID, postID);
  }
  subscribeToSocialManPostUpdates(
    userID: string | undefined,
    postID: string | undefined,
    updatesCallback: (updatedPost: LocalSocialManPost) => void,
    errorsCallback: (error: any) => void
  ): Promise<void> {
    return AWSGQLManager.subscribeToSocialManPostUpdates(
      userID,
      postID,
      updatesCallback,
      errorsCallback
    );
  }

  // API
  async addToWaitlist(email: string): Promise<void> {
    return AWSAPIManager.addToWaitlist(email);
  }
  async postSocialManPostToEverywhere(
    userID: string,
    postID: string
  ): Promise<void> {
    return AWSAPIManager.postSocialManPostToEverywhere(userID, postID);
  }
  async getFacebookLoginURL(
    userID: string,
    scopes: string[],
    redirectData?: Record<string, string>
  ): Promise<string> {
    return AWSAPIManager.getFacebookLoginURL(userID, scopes, redirectData);
  }
  async facebookLoginCallback(
    accessToken: string,
    userID: string
  ): Promise<void> {
    return AWSAPIManager.facebookLoginCallback(accessToken, userID);
  }
  async postToFacebookPage(
    userID: string,
    isReel: boolean,
    caption: string,
    pageID: string,
    videoS3Key: string
  ): Promise<void> {
    return AWSAPIManager.postToFacebookPage(
      userID,
      isReel,
      caption,
      pageID,
      videoS3Key
    );
  }
  async postToInstagram(
    userID: string,
    isReel: boolean,
    caption: string,
    instagramAccountID: string,
    mediaS3Key: string,
    thumbnailS3Key?: string
  ): Promise<void> {
    return AWSAPIManager.postToInstagram(
      userID,
      isReel,
      caption,
      instagramAccountID,
      mediaS3Key,
      thumbnailS3Key
    );
  }
  async getYoutubeLoginURL(
    userID: string,
    redirectData?: Record<string, string>
  ): Promise<string> {
    return AWSAPIManager.getYoutubeLoginURL(userID, redirectData);
  }
  async getTwitterLoginURL(
    userID: string,
    redirectData?: Record<string, string>
  ): Promise<string> {
    return AWSAPIManager.getTwitterLoginURL(userID, redirectData);
  }
  async postToYoutube(
    userID: string,
    title: string,
    description: string,
    tags: string,
    thumbnailS3Key: string,
    videoS3Key: string,
    privacyStatus: string,
    categoryName: string,
    notifySubscribers: boolean
  ): Promise<void> {
    return AWSAPIManager.postToYoutube(
      userID,
      title,
      description,
      tags,
      thumbnailS3Key,
      videoS3Key,
      privacyStatus,
      categoryName,
      notifySubscribers
    );
  }
  async getPinterestLoginURL(
    userID: string,
    redirectData?: Record<string, string>
  ): Promise<string> {
    return AWSAPIManager.getPinterestLoginURL(userID, redirectData);
  }
  async getTiktokLoginURL(
    userID: string,
    redirectData?: Record<string, string>
  ): Promise<string> {
    return AWSAPIManager.getTiktokLoginURL(userID, redirectData);
  }
  async getLinkedinLoginURL(
    userID: string,
    redirectData?: Record<string, string>
  ): Promise<string> {
    return AWSAPIManager.getLinkedinLoginURL(userID, redirectData);
  }
  async postToTiktok(userID: string, videoS3Key: string): Promise<void> {
    return AWSAPIManager.postToTiktok(userID, videoS3Key);
  }
  async tiktokLoginCallback(
    tiktokCode: string,
    userID: string,
    state: string
  ): Promise<void> {
    return AWSAPIManager.tiktokLoginCallback(tiktokCode, userID, state);
  }
  async sendContactEmail(
    email: string,
    subject: string,
    message: string
  ): Promise<string> {
    return AWSAPIManager.sendContactEmail(email, subject, message);
  }
  async executeTask(
    workspaceId: string | undefined,
    taskExecutionData: TaskExecutionData,
    progressCallback?: (progress: any) => void
  ): Promise<TaskExecutionCardProps> {
    return AWSAPIManager.executeTask(
      workspaceId,
      taskExecutionData,
      progressCallback
    );
  }

  async createSocialManPost(
    title: string,
    description: string,
    userID: string,
    fileData: string,
    progressCallback?: (progress: any) => void
  ): Promise<LocalSocialManPost> {
    return AWSGQLManager.createSocialManPost(
      title,
      description,
      userID,
      fileData,
      progressCallback
    );
  }
  async updateSocialManPost(
    localSocialManPost: LocalSocialManPost
  ): Promise<void> {
    return AWSGQLManager.updateSocialManPost(localSocialManPost);
  }
  async deleteSocialManPost(postID: string): Promise<void> {
    return AWSGQLManager.deleteSocialManPost(postID);
  }
  async fetchCurrentUserSocialManPostByID(
    postID: string,
    authMode: GraphQLAuthMode = "userPool"
  ): Promise<LocalSocialManPost | undefined> {
    return AWSGQLManager.fetchCurrentUserSocialManPostByID(postID, authMode);
  }
  async fetchCurrentUserSocialManPosts(
    userId?: string,
    nextToken?: string,
    authMode: GraphQLAuthMode = "userPool"
  ): Promise<PagedLocalSocialManPost | undefined> {
    return AWSGQLManager.fetchCurrentUserSocialManPosts(
      userId,
      nextToken,
      authMode
    );
  }
  //STRIPE
  async getUserStripeId(): Promise<string | undefined> {
    return AWSStripeManager.getUserStripeId();
  }

  async getUserStripeConnectAccountId(): Promise<string | undefined> {
    return AWSStripeManager.getUserStripeConnectAccountId();
  }

  async createStripeConnectAccount() {
    return AWSStripeManager.createStripeConnectAccount();
  }

  async deleteStripeConnectAccount() {
    return AWSStripeManager.deleteStripeConnectAccount();
  }

  async getStripeConnectAccountLink() {
    return AWSStripeManager.getStripeConnectAccountLink();
  }

  async getStripeConnectAccountPayoutHistory(): Promise<
    StripePayoutHistoryItem[]
  > {
    return AWSStripeManager.getStripeConnectAccountPayoutHistory();
  }

  async getStripeConnectAccountLoginLink(): Promise<string> {
    return AWSStripeManager.getStripeConnectAccountLoginLink();
  }

  async checkStripeConnectAccountCompletedSetupAndUpdateUser(): Promise<
    boolean | undefined
  > {
    return AWSStripeManager.checkStripeConnectAccountCompletedSetupAndUpdateUser();
  }

  async getPaymentHistory(): Promise<StripeInvoice[]> {
    return AWSStripeManager.getPaymentHistory();
  }

  async cashOut(amount: number) {
    return AWSStripeManager.cashOut(amount);
  }
  //more suitable for subscriptions which we don't currently support
  async getCustomerPortal() {
    return AWSStripeManager.getCustomerPortal();
  }

  async stripeCheckout(
    internalPriceID: InternalCreditsPriceID,
    dontRedirect: boolean
  ): Promise<{ clientSecret: string; url: string }> {
    return AWSStripeManager.stripeCheckout(internalPriceID, dontRedirect);
  }
  async stripeSubscriptionCheckout(
    internalPriceID: InternalSubscriptionPriceID
  ): Promise<{ url: string }> {
    return AWSStripeManager.stripeSubscriptionCheckout(internalPriceID);
  }
  async getStripeSessionStatus(session_id: string) {
    return AWSStripeManager.getStripeSessionStatus(session_id);
  }

  async invokeSagemakerEndpoint() {
    // https://aws.amazon.com/blogs/machine-learning/call-an-amazon-sagemaker-model-endpoint-using-amazon-api-gateway-and-aws-lambda/
    //permission reference
    //   {
    //     "Version": "2012-10-17",
    //     "Statement": [
    //         {
    //             "Sid": "VisualEditor0",
    //             "Effect": "Allow",
    //             "Action": "sagemaker:InvokeEndpoint",
    //             "Resource": "*"
    //         }
    //     ]
    // }
    //lambda code
    // import * as AWS_SM from "@aws-sdk/client-sagemaker-runtime";
    // function JsonToArray(json) {
    //   var str = JSON.stringify(json, null, 0);
    //   var ret = new Uint8Array(str.length);
    //   for (var i = 0; i < str.length; i++) {
    //     ret[i] = str.charCodeAt(i);
    //   }
    //   return ret;
    // }
    // async function invokeSageEndpoint() {
    //   const inputParams = {
    //     prompt: "a world full of love",
    //   };
    //   const endpointName = "custom-polyworld-endpoint2023-04-08-00-44-54";
    //   const configs = {
    //     region: AWS_REGION,
    //   };
    //   const client = new AWS_SM.SageMakerRuntime(configs);
    //   const params = {
    //     Body: JsonToArray(
    //       inputParams
    //     ) /* Strings will be Base-64 encoded on your behalf */ /* required */,
    //     EndpointName: endpointName,
    //     Accept: "application/json",
    //     ContentType: "application/json",
    //     // CustomAttributes: 'STRING_VALUE',
    //     // EnableExplanations: 'STRING_VALUE',
    //     // InferenceId: 'STRING_VALUE',
    //     // TargetContainerHostname: 'STRING_VALUE',
    //     // TargetModel: 'STRING_VALUE',
    //     // TargetVariant: 'STRING_VALUE'
    //   };
    //   return client
    //     .invokeEndpoint(params)
    //     .then((data) => {
    //       console.log("invoked data = ");
    //       console.log(JSON.stringify(data));
    //       // data.status(200).json({ name: "John Doe1" });
    //     })
    //     .catch((error) => {
    //       // error handling.
    //       console.log("ERROR = ");
    //       console.log(JSON.stringify(error));
    //       // res.status(500).json({ name: "John Doe1" });
    //     });
    // }
    //client call
    // const postInit = {
    //   body: {}, // replace this with attributes you need
    // };
    // return API.post(process.env.NEXT_PUBLIC_API_NAME!, "/executeTask/sagemakertest", postInit)
    //   .then((x) => {
    //     console.log("finished with result");
    //     console.log(x);
    //   })
    //   .catch((error) => {
    //     console.log("failed with error");
    //   });
  }
  // S3
  async uploadFileForKeyAndInput(
    key: string,
    inputs: TaskExecutionInput,
    level: CloudAccessLevels,
    updatedInputType: ModelInputOutputEntryType,
    progressCallback: (progress: any) => void
  ) {
    return await AWSS3Manager.uploadFileForKeyAndInput(
      key,
      inputs,
      level,
      updatedInputType,
      progressCallback
    );
  }

  async getCloudContent(
    fullKey: string,
    isContentPublic: boolean
  ): Promise<string> {
    return await AWSS3Manager.getCloudContent(fullKey, isContentPublic);
  }
  async getUserContent(
    customUserID: string | undefined,
    key: string,
    level: CloudAccessLevels
    //unfortunatley Storage.get maxes out at 1 hour.....
  ): Promise<string> {
    return await AWSS3Manager.getUserContent(customUserID, key, level);
  }
  async moveFileOnS3(from: string, destination: string) {
    return await AWSS3Manager.moveFileOnS3(from, destination);
  }
  async copyUserContentOnS3(
    from: string,
    fromLevel: CloudAccessLevels,
    destination: string,
    toLevel: CloudAccessLevels
  ) {
    return await AWSS3Manager.copyUserContentOnS3(
      from,
      fromLevel,
      destination,
      toLevel
    );
  }
  async moveUserContentOnS3(
    from: string,
    fromLevel: CloudAccessLevels,
    destination: string,
    toLevel: CloudAccessLevels
  ) {
    return await AWSS3Manager.moveUserContentOnS3(
      from,
      fromLevel,
      destination,
      toLevel
    );
  }

  async uploadUserContentToS3(
    destination: string,
    content: any,
    level: CloudAccessLevels,
    metadata: {} | null,
    dotExtension: string | null = null,
    filenameWithoutExtension: string | null = null,
    progressCompletionPercentCallback?: (progress: any) => void,
    errorCallback?: (error: any) => void
  ) {
    return await AWSS3Manager.uploadUserContentToS3(
      destination,
      content,
      level,
      metadata,
      dotExtension,
      filenameWithoutExtension,
      progressCompletionPercentCallback,
      errorCallback
    );
  }

  async updateIdentityID(): Promise<void> {
    return await AWSAPIManager.updateIdentityID();
  }

  listUserComfyTokens(userID: string): Promise<ComfyTokenData[]> {
    return AWSAPIManager.listUserComfyTokens(userID);
  }
  createComfyToken(
    userID: string,
    password: string,
    scopes: string[]
  ): Promise<string> {
    return AWSAPIManager.createComfyToken(userID, password, scopes);
  }
  revokeComfyToken(token: string, userID: string): Promise<void> {
    return AWSAPIManager.revokeComfyToken(token, userID);
  }
}

export default AWSManagerClass.Instance;
