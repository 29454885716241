/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "./API_WithTypename";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createMLTaskData = /* GraphQL */ `mutation CreateMLTaskData(
  $condition: ModelMLTaskDataConditionInput
  $input: CreateMLTaskDataInput!
) {
  createMLTaskData(condition: $condition, input: $input) {
    Models {
      items {
        createdAt
        id
        isPublic
        mltaskdataID
        name
        updatedAt
      }
      nextToken
    }
    category
    createdAt
    id
    isPublic
    name
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreateMLTaskDataMutationVariables,
  APITypes.CreateMLTaskDataMutation
>;
export const createMLTaskExecutionResult = /* GraphQL */ `mutation CreateMLTaskExecutionResult(
  $condition: ModelMLTaskExecutionResultConditionInput
  $input: CreateMLTaskExecutionResultInput!
) {
  createMLTaskExecutionResult(condition: $condition, input: $input) {
    ANGRYReactionStats
    CUTEReactionStats
    FUNNYReactionStats
    GROSSReactionStats
    HEARTReactionStats
    POOPReactionStats
    Reactions {
      items {
        createdAt
        mltaskexecutionresultID
        type
        updatedAt
        userID
      }
      nextToken
    }
    SADReactionStats
    SCARYReactionStats
    WOWReactionStats
    createdAt
    creditsUsed
    id
    inputs {
      metaData {
        key
        value
      }
      name
      type
      value
    }
    mltasksworkspaceID
    modelName
    modelsID
    output {
      entries {
        name
        type
        value
      }
      message
    }
    presetUserInput {
      presetCreatorName
      presetCreatorUID
      presetID
      presetName
      presetValues {
        modelInputName
      }
    }
    privacyLevel
    status
    taskName
    updatedAt
    userID
  }
}
` as GeneratedMutation<
  APITypes.CreateMLTaskExecutionResultMutationVariables,
  APITypes.CreateMLTaskExecutionResultMutation
>;
export const createMLTasksWorkspace = /* GraphQL */ `mutation CreateMLTasksWorkspace(
  $condition: ModelMLTasksWorkspaceConditionInput
  $input: CreateMLTasksWorkspaceInput!
) {
  createMLTasksWorkspace(condition: $condition, input: $input) {
    MLTaskExecutionResults {
      items {
        ANGRYReactionStats
        CUTEReactionStats
        FUNNYReactionStats
        GROSSReactionStats
        HEARTReactionStats
        POOPReactionStats
        SADReactionStats
        SCARYReactionStats
        WOWReactionStats
        createdAt
        creditsUsed
        id
        mltasksworkspaceID
        modelName
        modelsID
        privacyLevel
        status
        taskName
        updatedAt
        userID
      }
      nextToken
    }
    createdAt
    description
    id
    name
    updatedAt
    userID
  }
}
` as GeneratedMutation<
  APITypes.CreateMLTasksWorkspaceMutationVariables,
  APITypes.CreateMLTasksWorkspaceMutation
>;
export const createModelInputPreset = /* GraphQL */ `mutation CreateModelInputPreset(
  $condition: ModelModelInputPresetConditionInput
  $input: CreateModelInputPresetInput!
) {
  createModelInputPreset(condition: $condition, input: $input) {
    cost
    createdAt
    description
    entries {
      modelInputName
      modelInputType
      presetEntryDatas {
        description
        entryType
        parameterName
        presetOrder
        val
      }
    }
    id
    isOpenSource
    isPublic
    modelName
    modelsID
    presetName
    taskName
    updatedAt
    usage
    userID
    username
  }
}
` as GeneratedMutation<
  APITypes.CreateModelInputPresetMutationVariables,
  APITypes.CreateModelInputPresetMutation
>;
export const createModels = /* GraphQL */ `mutation CreateModels(
  $condition: ModelModelsConditionInput
  $input: CreateModelsInput!
) {
  createModels(condition: $condition, input: $input) {
    MLTaskExecutionResults {
      items {
        ANGRYReactionStats
        CUTEReactionStats
        FUNNYReactionStats
        GROSSReactionStats
        HEARTReactionStats
        POOPReactionStats
        SADReactionStats
        SCARYReactionStats
        WOWReactionStats
        createdAt
        creditsUsed
        id
        mltasksworkspaceID
        modelName
        modelsID
        privacyLevel
        status
        taskName
        updatedAt
        userID
      }
      nextToken
    }
    ModelInputPresets {
      items {
        cost
        createdAt
        description
        id
        isOpenSource
        isPublic
        modelName
        modelsID
        presetName
        taskName
        updatedAt
        usage
        userID
        username
      }
      nextToken
    }
    createdAt
    id
    inputs {
      optional {
        defaultValue
        description
        examples
        name
        optionStep
        optionType
        type
      }
      required {
        defaultValue
        description
        examples
        name
        optionStep
        optionType
        type
      }
    }
    isPublic
    mltaskdataID
    name
    outputs {
      entries {
        name
        type
      }
    }
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreateModelsMutationVariables,
  APITypes.CreateModelsMutation
>;
export const createSocialManPost = /* GraphQL */ `mutation CreateSocialManPost(
  $condition: ModelSocialManPostConditionInput
  $input: CreateSocialManPostInput!
) {
  createSocialManPost(condition: $condition, input: $input) {
    createdAt
    description
    enabledFacebook
    enabledInstagram
    enabledLinkedin
    enabledPinterest
    enabledTiktok
    enabledTwitter
    enabledYoutube
    facebook {
      caption
      postID
      postToStory
      storyID
      storyURL
      targetPageID
      thumbnail
    }
    id
    instagram {
      caption
      postID
      postToStory
      storyID
      targetAccountID
      thumbnail
    }
    linkedin {
      caption
      mediaDescription
      postID
    }
    pinterest {
      boardID
      description
      link
      pinID
      thumbnailURL
      title
    }
    postedTime
    postedToFacebook
    postedToInstagram
    postedToLinkedin
    postedToPinterest
    postedToTiktok
    postedToTwitter
    postedToYoutube
    s3Key
    s3KeyResized
    scheduledPostTime
    status
    tiktok {
      caption
      contentDisclosureBrandedContent
      contentDisclosureEnabled
      contentDisclosureYourBrand
      postID
      privacy
      title
      usersCanComment
      usersCanDuet
      usersCanStitch
      videoCoverTimestampMs
    }
    title
    twitter {
      caption
      tweetID
    }
    updatedAt
    userID
    youtube {
      category
      description
      privacy
      tags
      targetChannelID
      thumbnailURL
      title
      videoID
    }
  }
}
` as GeneratedMutation<
  APITypes.CreateSocialManPostMutationVariables,
  APITypes.CreateSocialManPostMutation
>;
export const createTaskReaction = /* GraphQL */ `mutation CreateTaskReaction(
  $condition: ModelTaskReactionConditionInput
  $input: CreateTaskReactionInput!
) {
  createTaskReaction(condition: $condition, input: $input) {
    createdAt
    mltaskexecutionresultID
    type
    updatedAt
    userID
  }
}
` as GeneratedMutation<
  APITypes.CreateTaskReactionMutationVariables,
  APITypes.CreateTaskReactionMutation
>;
export const createUser = /* GraphQL */ `mutation CreateUser(
  $condition: ModelUserConditionInput
  $input: CreateUserInput!
) {
  createUser(condition: $condition, input: $input) {
    MLTaskExecutionResults {
      items {
        ANGRYReactionStats
        CUTEReactionStats
        FUNNYReactionStats
        GROSSReactionStats
        HEARTReactionStats
        POOPReactionStats
        SADReactionStats
        SCARYReactionStats
        WOWReactionStats
        createdAt
        creditsUsed
        id
        mltasksworkspaceID
        modelName
        modelsID
        privacyLevel
        status
        taskName
        updatedAt
        userID
      }
      nextToken
    }
    MLTasksWorkspaces {
      items {
        createdAt
        description
        id
        name
        updatedAt
        userID
      }
      nextToken
    }
    ModelInputPresets {
      items {
        cost
        createdAt
        description
        id
        isOpenSource
        isPublic
        modelName
        modelsID
        presetName
        taskName
        updatedAt
        usage
        userID
        username
      }
      nextToken
    }
    SocialManPosts {
      items {
        createdAt
        description
        enabledFacebook
        enabledInstagram
        enabledLinkedin
        enabledPinterest
        enabledTiktok
        enabledTwitter
        enabledYoutube
        id
        postedTime
        postedToFacebook
        postedToInstagram
        postedToLinkedin
        postedToPinterest
        postedToTiktok
        postedToTwitter
        postedToYoutube
        s3Key
        s3KeyResized
        scheduledPostTime
        status
        title
        updatedAt
        userID
      }
      nextToken
    }
    completedTasksCount
    createdAt
    createdPresetCount
    credits
    currentSubscriptionPlan
    email
    facebook {
      instagramsData {
        accessToken
        biography
        connectedPageID
        expiresOn
        followersCount
        followsCount
        id
        igID
        mediaCount
        name
        profilePictureURL
        username
      }
      pagesData {
        accessToken
        category
        expiresOn
        id
        name
        picture
      }
      userData {
        accessToken
        expiresOn
        id
        name
        picture
      }
    }
    google {
      channelsData {
        customUrl
        description
        id
        thumbnail
        title
      }
      tokenData {
        accessToken
        expiresOn
        idToken
        refreshToken
        scope
        tokenType
      }
      userData {
        email
        familyName
        givenName
        id
        locale
        name
        picture
        verifiedEmail
      }
    }
    id
    linkedin {
      tokenData {
        accessToken
        accessTokenExpirationDate
        scope
      }
      userData {
        email
        name
        picture
        sub
      }
    }
    name
    periodEndOfLastSubscriptionCredits
    picture
    pinterest {
      boardsData {
        createdAt
        description
        followerCount
        id
        imageCoverUrl
        name
        pinCount
        privacy
      }
      tokenData {
        accessToken
        accessTokenExpirationDate
        refreshToken
        refreshTokenExpirationDate
        scope
      }
      userData {
        about
        accountType
        boardCount
        businessName
        followerCount
        followingCount
        id
        monthlyViews
        pinCount
        profileImage
        username
        websiteUrl
      }
    }
    stripeConnectAccountID
    stripeConnectAccountSetupComplete
    stripeCustomerID
    tiktok {
      tokenData {
        accessToken
        accessTokenExpirationDate
        refreshToken
        refreshTokenExpirationDate
        scope
      }
      userData {
        avatarLargeURL
        avatarURL
        avatarURL100
        bioDescription
        commentDisabled
        displayName
        duetDisabled
        followerCount
        followingCount
        isVerified
        likesCount
        maxVideoPostDuration_sec
        privacyLevelOptions
        profileDeepLink
        stitchDisabled
        username
        videoCount
      }
    }
    twitter {
      tokenData {
        accessToken
        accessTokenSecret
        codeVerifier
        expiresOn
        oauthTokenSecret
        refreshToken
      }
      userData {
        description
        id
        name
        profilePictureURL
        username
        verifiedType
      }
    }
    updatedAt
    username
  }
}
` as GeneratedMutation<
  APITypes.CreateUserMutationVariables,
  APITypes.CreateUserMutation
>;
export const deleteMLTaskData = /* GraphQL */ `mutation DeleteMLTaskData(
  $condition: ModelMLTaskDataConditionInput
  $input: DeleteMLTaskDataInput!
) {
  deleteMLTaskData(condition: $condition, input: $input) {
    Models {
      items {
        createdAt
        id
        isPublic
        mltaskdataID
        name
        updatedAt
      }
      nextToken
    }
    category
    createdAt
    id
    isPublic
    name
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.DeleteMLTaskDataMutationVariables,
  APITypes.DeleteMLTaskDataMutation
>;
export const deleteMLTaskExecutionResult = /* GraphQL */ `mutation DeleteMLTaskExecutionResult(
  $condition: ModelMLTaskExecutionResultConditionInput
  $input: DeleteMLTaskExecutionResultInput!
) {
  deleteMLTaskExecutionResult(condition: $condition, input: $input) {
    ANGRYReactionStats
    CUTEReactionStats
    FUNNYReactionStats
    GROSSReactionStats
    HEARTReactionStats
    POOPReactionStats
    Reactions {
      items {
        createdAt
        mltaskexecutionresultID
        type
        updatedAt
        userID
      }
      nextToken
    }
    SADReactionStats
    SCARYReactionStats
    WOWReactionStats
    createdAt
    creditsUsed
    id
    inputs {
      metaData {
        key
        value
      }
      name
      type
      value
    }
    mltasksworkspaceID
    modelName
    modelsID
    output {
      entries {
        name
        type
        value
      }
      message
    }
    presetUserInput {
      presetCreatorName
      presetCreatorUID
      presetID
      presetName
      presetValues {
        modelInputName
      }
    }
    privacyLevel
    status
    taskName
    updatedAt
    userID
  }
}
` as GeneratedMutation<
  APITypes.DeleteMLTaskExecutionResultMutationVariables,
  APITypes.DeleteMLTaskExecutionResultMutation
>;
export const deleteMLTasksWorkspace = /* GraphQL */ `mutation DeleteMLTasksWorkspace(
  $condition: ModelMLTasksWorkspaceConditionInput
  $input: DeleteMLTasksWorkspaceInput!
) {
  deleteMLTasksWorkspace(condition: $condition, input: $input) {
    MLTaskExecutionResults {
      items {
        ANGRYReactionStats
        CUTEReactionStats
        FUNNYReactionStats
        GROSSReactionStats
        HEARTReactionStats
        POOPReactionStats
        SADReactionStats
        SCARYReactionStats
        WOWReactionStats
        createdAt
        creditsUsed
        id
        mltasksworkspaceID
        modelName
        modelsID
        privacyLevel
        status
        taskName
        updatedAt
        userID
      }
      nextToken
    }
    createdAt
    description
    id
    name
    updatedAt
    userID
  }
}
` as GeneratedMutation<
  APITypes.DeleteMLTasksWorkspaceMutationVariables,
  APITypes.DeleteMLTasksWorkspaceMutation
>;
export const deleteModelInputPreset = /* GraphQL */ `mutation DeleteModelInputPreset(
  $condition: ModelModelInputPresetConditionInput
  $input: DeleteModelInputPresetInput!
) {
  deleteModelInputPreset(condition: $condition, input: $input) {
    cost
    createdAt
    description
    entries {
      modelInputName
      modelInputType
      presetEntryDatas {
        description
        entryType
        parameterName
        presetOrder
        val
      }
    }
    id
    isOpenSource
    isPublic
    modelName
    modelsID
    presetName
    taskName
    updatedAt
    usage
    userID
    username
  }
}
` as GeneratedMutation<
  APITypes.DeleteModelInputPresetMutationVariables,
  APITypes.DeleteModelInputPresetMutation
>;
export const deleteModels = /* GraphQL */ `mutation DeleteModels(
  $condition: ModelModelsConditionInput
  $input: DeleteModelsInput!
) {
  deleteModels(condition: $condition, input: $input) {
    MLTaskExecutionResults {
      items {
        ANGRYReactionStats
        CUTEReactionStats
        FUNNYReactionStats
        GROSSReactionStats
        HEARTReactionStats
        POOPReactionStats
        SADReactionStats
        SCARYReactionStats
        WOWReactionStats
        createdAt
        creditsUsed
        id
        mltasksworkspaceID
        modelName
        modelsID
        privacyLevel
        status
        taskName
        updatedAt
        userID
      }
      nextToken
    }
    ModelInputPresets {
      items {
        cost
        createdAt
        description
        id
        isOpenSource
        isPublic
        modelName
        modelsID
        presetName
        taskName
        updatedAt
        usage
        userID
        username
      }
      nextToken
    }
    createdAt
    id
    inputs {
      optional {
        defaultValue
        description
        examples
        name
        optionStep
        optionType
        type
      }
      required {
        defaultValue
        description
        examples
        name
        optionStep
        optionType
        type
      }
    }
    isPublic
    mltaskdataID
    name
    outputs {
      entries {
        name
        type
      }
    }
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.DeleteModelsMutationVariables,
  APITypes.DeleteModelsMutation
>;
export const deleteSocialManPost = /* GraphQL */ `mutation DeleteSocialManPost(
  $condition: ModelSocialManPostConditionInput
  $input: DeleteSocialManPostInput!
) {
  deleteSocialManPost(condition: $condition, input: $input) {
    createdAt
    description
    enabledFacebook
    enabledInstagram
    enabledLinkedin
    enabledPinterest
    enabledTiktok
    enabledTwitter
    enabledYoutube
    facebook {
      caption
      postID
      postToStory
      storyID
      storyURL
      targetPageID
      thumbnail
    }
    id
    instagram {
      caption
      postID
      postToStory
      storyID
      targetAccountID
      thumbnail
    }
    linkedin {
      caption
      mediaDescription
      postID
    }
    pinterest {
      boardID
      description
      link
      pinID
      thumbnailURL
      title
    }
    postedTime
    postedToFacebook
    postedToInstagram
    postedToLinkedin
    postedToPinterest
    postedToTiktok
    postedToTwitter
    postedToYoutube
    s3Key
    s3KeyResized
    scheduledPostTime
    status
    tiktok {
      caption
      contentDisclosureBrandedContent
      contentDisclosureEnabled
      contentDisclosureYourBrand
      postID
      privacy
      title
      usersCanComment
      usersCanDuet
      usersCanStitch
      videoCoverTimestampMs
    }
    title
    twitter {
      caption
      tweetID
    }
    updatedAt
    userID
    youtube {
      category
      description
      privacy
      tags
      targetChannelID
      thumbnailURL
      title
      videoID
    }
  }
}
` as GeneratedMutation<
  APITypes.DeleteSocialManPostMutationVariables,
  APITypes.DeleteSocialManPostMutation
>;
export const deleteTaskReaction = /* GraphQL */ `mutation DeleteTaskReaction(
  $condition: ModelTaskReactionConditionInput
  $input: DeleteTaskReactionInput!
) {
  deleteTaskReaction(condition: $condition, input: $input) {
    createdAt
    mltaskexecutionresultID
    type
    updatedAt
    userID
  }
}
` as GeneratedMutation<
  APITypes.DeleteTaskReactionMutationVariables,
  APITypes.DeleteTaskReactionMutation
>;
export const deleteUser = /* GraphQL */ `mutation DeleteUser(
  $condition: ModelUserConditionInput
  $input: DeleteUserInput!
) {
  deleteUser(condition: $condition, input: $input) {
    MLTaskExecutionResults {
      items {
        ANGRYReactionStats
        CUTEReactionStats
        FUNNYReactionStats
        GROSSReactionStats
        HEARTReactionStats
        POOPReactionStats
        SADReactionStats
        SCARYReactionStats
        WOWReactionStats
        createdAt
        creditsUsed
        id
        mltasksworkspaceID
        modelName
        modelsID
        privacyLevel
        status
        taskName
        updatedAt
        userID
      }
      nextToken
    }
    MLTasksWorkspaces {
      items {
        createdAt
        description
        id
        name
        updatedAt
        userID
      }
      nextToken
    }
    ModelInputPresets {
      items {
        cost
        createdAt
        description
        id
        isOpenSource
        isPublic
        modelName
        modelsID
        presetName
        taskName
        updatedAt
        usage
        userID
        username
      }
      nextToken
    }
    SocialManPosts {
      items {
        createdAt
        description
        enabledFacebook
        enabledInstagram
        enabledLinkedin
        enabledPinterest
        enabledTiktok
        enabledTwitter
        enabledYoutube
        id
        postedTime
        postedToFacebook
        postedToInstagram
        postedToLinkedin
        postedToPinterest
        postedToTiktok
        postedToTwitter
        postedToYoutube
        s3Key
        s3KeyResized
        scheduledPostTime
        status
        title
        updatedAt
        userID
      }
      nextToken
    }
    completedTasksCount
    createdAt
    createdPresetCount
    credits
    currentSubscriptionPlan
    email
    facebook {
      instagramsData {
        accessToken
        biography
        connectedPageID
        expiresOn
        followersCount
        followsCount
        id
        igID
        mediaCount
        name
        profilePictureURL
        username
      }
      pagesData {
        accessToken
        category
        expiresOn
        id
        name
        picture
      }
      userData {
        accessToken
        expiresOn
        id
        name
        picture
      }
    }
    google {
      channelsData {
        customUrl
        description
        id
        thumbnail
        title
      }
      tokenData {
        accessToken
        expiresOn
        idToken
        refreshToken
        scope
        tokenType
      }
      userData {
        email
        familyName
        givenName
        id
        locale
        name
        picture
        verifiedEmail
      }
    }
    id
    linkedin {
      tokenData {
        accessToken
        accessTokenExpirationDate
        scope
      }
      userData {
        email
        name
        picture
        sub
      }
    }
    name
    periodEndOfLastSubscriptionCredits
    picture
    pinterest {
      boardsData {
        createdAt
        description
        followerCount
        id
        imageCoverUrl
        name
        pinCount
        privacy
      }
      tokenData {
        accessToken
        accessTokenExpirationDate
        refreshToken
        refreshTokenExpirationDate
        scope
      }
      userData {
        about
        accountType
        boardCount
        businessName
        followerCount
        followingCount
        id
        monthlyViews
        pinCount
        profileImage
        username
        websiteUrl
      }
    }
    stripeConnectAccountID
    stripeConnectAccountSetupComplete
    stripeCustomerID
    tiktok {
      tokenData {
        accessToken
        accessTokenExpirationDate
        refreshToken
        refreshTokenExpirationDate
        scope
      }
      userData {
        avatarLargeURL
        avatarURL
        avatarURL100
        bioDescription
        commentDisabled
        displayName
        duetDisabled
        followerCount
        followingCount
        isVerified
        likesCount
        maxVideoPostDuration_sec
        privacyLevelOptions
        profileDeepLink
        stitchDisabled
        username
        videoCount
      }
    }
    twitter {
      tokenData {
        accessToken
        accessTokenSecret
        codeVerifier
        expiresOn
        oauthTokenSecret
        refreshToken
      }
      userData {
        description
        id
        name
        profilePictureURL
        username
        verifiedType
      }
    }
    updatedAt
    username
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserMutationVariables,
  APITypes.DeleteUserMutation
>;
export const updateMLTaskData = /* GraphQL */ `mutation UpdateMLTaskData(
  $condition: ModelMLTaskDataConditionInput
  $input: UpdateMLTaskDataInput!
) {
  updateMLTaskData(condition: $condition, input: $input) {
    Models {
      items {
        createdAt
        id
        isPublic
        mltaskdataID
        name
        updatedAt
      }
      nextToken
    }
    category
    createdAt
    id
    isPublic
    name
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateMLTaskDataMutationVariables,
  APITypes.UpdateMLTaskDataMutation
>;
export const updateMLTaskExecutionResult = /* GraphQL */ `mutation UpdateMLTaskExecutionResult(
  $condition: ModelMLTaskExecutionResultConditionInput
  $input: UpdateMLTaskExecutionResultInput!
) {
  updateMLTaskExecutionResult(condition: $condition, input: $input) {
    ANGRYReactionStats
    CUTEReactionStats
    FUNNYReactionStats
    GROSSReactionStats
    HEARTReactionStats
    POOPReactionStats
    Reactions {
      items {
        createdAt
        mltaskexecutionresultID
        type
        updatedAt
        userID
      }
      nextToken
    }
    SADReactionStats
    SCARYReactionStats
    WOWReactionStats
    createdAt
    creditsUsed
    id
    inputs {
      metaData {
        key
        value
      }
      name
      type
      value
    }
    mltasksworkspaceID
    modelName
    modelsID
    output {
      entries {
        name
        type
        value
      }
      message
    }
    presetUserInput {
      presetCreatorName
      presetCreatorUID
      presetID
      presetName
      presetValues {
        modelInputName
      }
    }
    privacyLevel
    status
    taskName
    updatedAt
    userID
  }
}
` as GeneratedMutation<
  APITypes.UpdateMLTaskExecutionResultMutationVariables,
  APITypes.UpdateMLTaskExecutionResultMutation
>;
export const updateMLTasksWorkspace = /* GraphQL */ `mutation UpdateMLTasksWorkspace(
  $condition: ModelMLTasksWorkspaceConditionInput
  $input: UpdateMLTasksWorkspaceInput!
) {
  updateMLTasksWorkspace(condition: $condition, input: $input) {
    MLTaskExecutionResults {
      items {
        ANGRYReactionStats
        CUTEReactionStats
        FUNNYReactionStats
        GROSSReactionStats
        HEARTReactionStats
        POOPReactionStats
        SADReactionStats
        SCARYReactionStats
        WOWReactionStats
        createdAt
        creditsUsed
        id
        mltasksworkspaceID
        modelName
        modelsID
        privacyLevel
        status
        taskName
        updatedAt
        userID
      }
      nextToken
    }
    createdAt
    description
    id
    name
    updatedAt
    userID
  }
}
` as GeneratedMutation<
  APITypes.UpdateMLTasksWorkspaceMutationVariables,
  APITypes.UpdateMLTasksWorkspaceMutation
>;
export const updateModelInputPreset = /* GraphQL */ `mutation UpdateModelInputPreset(
  $condition: ModelModelInputPresetConditionInput
  $input: UpdateModelInputPresetInput!
) {
  updateModelInputPreset(condition: $condition, input: $input) {
    cost
    createdAt
    description
    entries {
      modelInputName
      modelInputType
      presetEntryDatas {
        description
        entryType
        parameterName
        presetOrder
        val
      }
    }
    id
    isOpenSource
    isPublic
    modelName
    modelsID
    presetName
    taskName
    updatedAt
    usage
    userID
    username
  }
}
` as GeneratedMutation<
  APITypes.UpdateModelInputPresetMutationVariables,
  APITypes.UpdateModelInputPresetMutation
>;
export const updateModels = /* GraphQL */ `mutation UpdateModels(
  $condition: ModelModelsConditionInput
  $input: UpdateModelsInput!
) {
  updateModels(condition: $condition, input: $input) {
    MLTaskExecutionResults {
      items {
        ANGRYReactionStats
        CUTEReactionStats
        FUNNYReactionStats
        GROSSReactionStats
        HEARTReactionStats
        POOPReactionStats
        SADReactionStats
        SCARYReactionStats
        WOWReactionStats
        createdAt
        creditsUsed
        id
        mltasksworkspaceID
        modelName
        modelsID
        privacyLevel
        status
        taskName
        updatedAt
        userID
      }
      nextToken
    }
    ModelInputPresets {
      items {
        cost
        createdAt
        description
        id
        isOpenSource
        isPublic
        modelName
        modelsID
        presetName
        taskName
        updatedAt
        usage
        userID
        username
      }
      nextToken
    }
    createdAt
    id
    inputs {
      optional {
        defaultValue
        description
        examples
        name
        optionStep
        optionType
        type
      }
      required {
        defaultValue
        description
        examples
        name
        optionStep
        optionType
        type
      }
    }
    isPublic
    mltaskdataID
    name
    outputs {
      entries {
        name
        type
      }
    }
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateModelsMutationVariables,
  APITypes.UpdateModelsMutation
>;
export const updateSocialManPost = /* GraphQL */ `mutation UpdateSocialManPost(
  $condition: ModelSocialManPostConditionInput
  $input: UpdateSocialManPostInput!
) {
  updateSocialManPost(condition: $condition, input: $input) {
    createdAt
    description
    enabledFacebook
    enabledInstagram
    enabledLinkedin
    enabledPinterest
    enabledTiktok
    enabledTwitter
    enabledYoutube
    facebook {
      caption
      postID
      postToStory
      storyID
      storyURL
      targetPageID
      thumbnail
    }
    id
    instagram {
      caption
      postID
      postToStory
      storyID
      targetAccountID
      thumbnail
    }
    linkedin {
      caption
      mediaDescription
      postID
    }
    pinterest {
      boardID
      description
      link
      pinID
      thumbnailURL
      title
    }
    postedTime
    postedToFacebook
    postedToInstagram
    postedToLinkedin
    postedToPinterest
    postedToTiktok
    postedToTwitter
    postedToYoutube
    s3Key
    s3KeyResized
    scheduledPostTime
    status
    tiktok {
      caption
      contentDisclosureBrandedContent
      contentDisclosureEnabled
      contentDisclosureYourBrand
      postID
      privacy
      title
      usersCanComment
      usersCanDuet
      usersCanStitch
      videoCoverTimestampMs
    }
    title
    twitter {
      caption
      tweetID
    }
    updatedAt
    userID
    youtube {
      category
      description
      privacy
      tags
      targetChannelID
      thumbnailURL
      title
      videoID
    }
  }
}
` as GeneratedMutation<
  APITypes.UpdateSocialManPostMutationVariables,
  APITypes.UpdateSocialManPostMutation
>;
export const updateTaskReaction = /* GraphQL */ `mutation UpdateTaskReaction(
  $condition: ModelTaskReactionConditionInput
  $input: UpdateTaskReactionInput!
) {
  updateTaskReaction(condition: $condition, input: $input) {
    createdAt
    mltaskexecutionresultID
    type
    updatedAt
    userID
  }
}
` as GeneratedMutation<
  APITypes.UpdateTaskReactionMutationVariables,
  APITypes.UpdateTaskReactionMutation
>;
export const updateUser = /* GraphQL */ `mutation UpdateUser(
  $condition: ModelUserConditionInput
  $input: UpdateUserInput!
) {
  updateUser(condition: $condition, input: $input) {
    MLTaskExecutionResults {
      items {
        ANGRYReactionStats
        CUTEReactionStats
        FUNNYReactionStats
        GROSSReactionStats
        HEARTReactionStats
        POOPReactionStats
        SADReactionStats
        SCARYReactionStats
        WOWReactionStats
        createdAt
        creditsUsed
        id
        mltasksworkspaceID
        modelName
        modelsID
        privacyLevel
        status
        taskName
        updatedAt
        userID
      }
      nextToken
    }
    MLTasksWorkspaces {
      items {
        createdAt
        description
        id
        name
        updatedAt
        userID
      }
      nextToken
    }
    ModelInputPresets {
      items {
        cost
        createdAt
        description
        id
        isOpenSource
        isPublic
        modelName
        modelsID
        presetName
        taskName
        updatedAt
        usage
        userID
        username
      }
      nextToken
    }
    SocialManPosts {
      items {
        createdAt
        description
        enabledFacebook
        enabledInstagram
        enabledLinkedin
        enabledPinterest
        enabledTiktok
        enabledTwitter
        enabledYoutube
        id
        postedTime
        postedToFacebook
        postedToInstagram
        postedToLinkedin
        postedToPinterest
        postedToTiktok
        postedToTwitter
        postedToYoutube
        s3Key
        s3KeyResized
        scheduledPostTime
        status
        title
        updatedAt
        userID
      }
      nextToken
    }
    completedTasksCount
    createdAt
    createdPresetCount
    credits
    currentSubscriptionPlan
    email
    facebook {
      instagramsData {
        accessToken
        biography
        connectedPageID
        expiresOn
        followersCount
        followsCount
        id
        igID
        mediaCount
        name
        profilePictureURL
        username
      }
      pagesData {
        accessToken
        category
        expiresOn
        id
        name
        picture
      }
      userData {
        accessToken
        expiresOn
        id
        name
        picture
      }
    }
    google {
      channelsData {
        customUrl
        description
        id
        thumbnail
        title
      }
      tokenData {
        accessToken
        expiresOn
        idToken
        refreshToken
        scope
        tokenType
      }
      userData {
        email
        familyName
        givenName
        id
        locale
        name
        picture
        verifiedEmail
      }
    }
    id
    linkedin {
      tokenData {
        accessToken
        accessTokenExpirationDate
        scope
      }
      userData {
        email
        name
        picture
        sub
      }
    }
    name
    periodEndOfLastSubscriptionCredits
    picture
    pinterest {
      boardsData {
        createdAt
        description
        followerCount
        id
        imageCoverUrl
        name
        pinCount
        privacy
      }
      tokenData {
        accessToken
        accessTokenExpirationDate
        refreshToken
        refreshTokenExpirationDate
        scope
      }
      userData {
        about
        accountType
        boardCount
        businessName
        followerCount
        followingCount
        id
        monthlyViews
        pinCount
        profileImage
        username
        websiteUrl
      }
    }
    stripeConnectAccountID
    stripeConnectAccountSetupComplete
    stripeCustomerID
    tiktok {
      tokenData {
        accessToken
        accessTokenExpirationDate
        refreshToken
        refreshTokenExpirationDate
        scope
      }
      userData {
        avatarLargeURL
        avatarURL
        avatarURL100
        bioDescription
        commentDisabled
        displayName
        duetDisabled
        followerCount
        followingCount
        isVerified
        likesCount
        maxVideoPostDuration_sec
        privacyLevelOptions
        profileDeepLink
        stitchDisabled
        username
        videoCount
      }
    }
    twitter {
      tokenData {
        accessToken
        accessTokenSecret
        codeVerifier
        expiresOn
        oauthTokenSecret
        refreshToken
      }
      userData {
        description
        id
        name
        profilePictureURL
        username
        verifiedType
      }
    }
    updatedAt
    username
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserMutationVariables,
  APITypes.UpdateUserMutation
>;
export const updateUserUsername = /* GraphQL */ `mutation UpdateUserUsername($userID: ID!, $username: String!) {
  updateUserUsername(userID: $userID, username: $username) {
    MLTaskExecutionResults {
      items {
        ANGRYReactionStats
        CUTEReactionStats
        FUNNYReactionStats
        GROSSReactionStats
        HEARTReactionStats
        POOPReactionStats
        SADReactionStats
        SCARYReactionStats
        WOWReactionStats
        createdAt
        creditsUsed
        id
        mltasksworkspaceID
        modelName
        modelsID
        privacyLevel
        status
        taskName
        updatedAt
        userID
      }
      nextToken
    }
    MLTasksWorkspaces {
      items {
        createdAt
        description
        id
        name
        updatedAt
        userID
      }
      nextToken
    }
    ModelInputPresets {
      items {
        cost
        createdAt
        description
        id
        isOpenSource
        isPublic
        modelName
        modelsID
        presetName
        taskName
        updatedAt
        usage
        userID
        username
      }
      nextToken
    }
    SocialManPosts {
      items {
        createdAt
        description
        enabledFacebook
        enabledInstagram
        enabledLinkedin
        enabledPinterest
        enabledTiktok
        enabledTwitter
        enabledYoutube
        id
        postedTime
        postedToFacebook
        postedToInstagram
        postedToLinkedin
        postedToPinterest
        postedToTiktok
        postedToTwitter
        postedToYoutube
        s3Key
        s3KeyResized
        scheduledPostTime
        status
        title
        updatedAt
        userID
      }
      nextToken
    }
    completedTasksCount
    createdAt
    createdPresetCount
    credits
    currentSubscriptionPlan
    email
    facebook {
      instagramsData {
        accessToken
        biography
        connectedPageID
        expiresOn
        followersCount
        followsCount
        id
        igID
        mediaCount
        name
        profilePictureURL
        username
      }
      pagesData {
        accessToken
        category
        expiresOn
        id
        name
        picture
      }
      userData {
        accessToken
        expiresOn
        id
        name
        picture
      }
    }
    google {
      channelsData {
        customUrl
        description
        id
        thumbnail
        title
      }
      tokenData {
        accessToken
        expiresOn
        idToken
        refreshToken
        scope
        tokenType
      }
      userData {
        email
        familyName
        givenName
        id
        locale
        name
        picture
        verifiedEmail
      }
    }
    id
    linkedin {
      tokenData {
        accessToken
        accessTokenExpirationDate
        scope
      }
      userData {
        email
        name
        picture
        sub
      }
    }
    name
    periodEndOfLastSubscriptionCredits
    picture
    pinterest {
      boardsData {
        createdAt
        description
        followerCount
        id
        imageCoverUrl
        name
        pinCount
        privacy
      }
      tokenData {
        accessToken
        accessTokenExpirationDate
        refreshToken
        refreshTokenExpirationDate
        scope
      }
      userData {
        about
        accountType
        boardCount
        businessName
        followerCount
        followingCount
        id
        monthlyViews
        pinCount
        profileImage
        username
        websiteUrl
      }
    }
    stripeConnectAccountID
    stripeConnectAccountSetupComplete
    stripeCustomerID
    tiktok {
      tokenData {
        accessToken
        accessTokenExpirationDate
        refreshToken
        refreshTokenExpirationDate
        scope
      }
      userData {
        avatarLargeURL
        avatarURL
        avatarURL100
        bioDescription
        commentDisabled
        displayName
        duetDisabled
        followerCount
        followingCount
        isVerified
        likesCount
        maxVideoPostDuration_sec
        privacyLevelOptions
        profileDeepLink
        stitchDisabled
        username
        videoCount
      }
    }
    twitter {
      tokenData {
        accessToken
        accessTokenSecret
        codeVerifier
        expiresOn
        oauthTokenSecret
        refreshToken
      }
      userData {
        description
        id
        name
        profilePictureURL
        username
        verifiedType
      }
    }
    updatedAt
    username
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserUsernameMutationVariables,
  APITypes.UpdateUserUsernameMutation
>;
export const adjustUserCredit = /* GraphQL */ `mutation AdjustUserCredit($input: UpdateUserInput!, $incrementValue: Int!) {
  adjustUserCredit(input: $input, incrementValue: $incrementValue) {
    MLTaskExecutionResults {
      items {
        ANGRYReactionStats
        CUTEReactionStats
        FUNNYReactionStats
        GROSSReactionStats
        HEARTReactionStats
        POOPReactionStats
        SADReactionStats
        SCARYReactionStats
        WOWReactionStats
        createdAt
        creditsUsed
        id
        mltasksworkspaceID
        modelName
        modelsID
        privacyLevel
        status
        taskName
        updatedAt
        userID
      }
      nextToken
    }
    MLTasksWorkspaces {
      items {
        createdAt
        description
        id
        name
        updatedAt
        userID
      }
      nextToken
    }
    ModelInputPresets {
      items {
        cost
        createdAt
        description
        id
        isOpenSource
        isPublic
        modelName
        modelsID
        presetName
        taskName
        updatedAt
        usage
        userID
        username
      }
      nextToken
    }
    SocialManPosts {
      items {
        createdAt
        description
        enabledFacebook
        enabledInstagram
        enabledLinkedin
        enabledPinterest
        enabledTiktok
        enabledTwitter
        enabledYoutube
        id
        postedTime
        postedToFacebook
        postedToInstagram
        postedToLinkedin
        postedToPinterest
        postedToTiktok
        postedToTwitter
        postedToYoutube
        s3Key
        s3KeyResized
        scheduledPostTime
        status
        title
        updatedAt
        userID
      }
      nextToken
    }
    completedTasksCount
    createdAt
    createdPresetCount
    credits
    currentSubscriptionPlan
    email
    facebook {
      instagramsData {
        accessToken
        biography
        connectedPageID
        expiresOn
        followersCount
        followsCount
        id
        igID
        mediaCount
        name
        profilePictureURL
        username
      }
      pagesData {
        accessToken
        category
        expiresOn
        id
        name
        picture
      }
      userData {
        accessToken
        expiresOn
        id
        name
        picture
      }
    }
    google {
      channelsData {
        customUrl
        description
        id
        thumbnail
        title
      }
      tokenData {
        accessToken
        expiresOn
        idToken
        refreshToken
        scope
        tokenType
      }
      userData {
        email
        familyName
        givenName
        id
        locale
        name
        picture
        verifiedEmail
      }
    }
    id
    linkedin {
      tokenData {
        accessToken
        accessTokenExpirationDate
        scope
      }
      userData {
        email
        name
        picture
        sub
      }
    }
    name
    periodEndOfLastSubscriptionCredits
    picture
    pinterest {
      boardsData {
        createdAt
        description
        followerCount
        id
        imageCoverUrl
        name
        pinCount
        privacy
      }
      tokenData {
        accessToken
        accessTokenExpirationDate
        refreshToken
        refreshTokenExpirationDate
        scope
      }
      userData {
        about
        accountType
        boardCount
        businessName
        followerCount
        followingCount
        id
        monthlyViews
        pinCount
        profileImage
        username
        websiteUrl
      }
    }
    stripeConnectAccountID
    stripeConnectAccountSetupComplete
    stripeCustomerID
    tiktok {
      tokenData {
        accessToken
        accessTokenExpirationDate
        refreshToken
        refreshTokenExpirationDate
        scope
      }
      userData {
        avatarLargeURL
        avatarURL
        avatarURL100
        bioDescription
        commentDisabled
        displayName
        duetDisabled
        followerCount
        followingCount
        isVerified
        likesCount
        maxVideoPostDuration_sec
        privacyLevelOptions
        profileDeepLink
        stitchDisabled
        username
        videoCount
      }
    }
    twitter {
      tokenData {
        accessToken
        accessTokenSecret
        codeVerifier
        expiresOn
        oauthTokenSecret
        refreshToken
      }
      userData {
        description
        id
        name
        profilePictureURL
        username
        verifiedType
      }
    }
    updatedAt
    username
  }
}
` as GeneratedMutation<
  APITypes.AdjustUserCreditMutationVariables,
  APITypes.AdjustUserCreditMutation
>;
export const updateModelInputPresetIncrementUsage = /* GraphQL */ `mutation UpdateModelInputPresetIncrementUsage(
  $input: UpdateModelInputPresetInput!
  $incrementValue: Int
  $condition: ModelModelInputPresetConditionInput
) {
  updateModelInputPresetIncrementUsage(
    input: $input
    incrementValue: $incrementValue
    condition: $condition
  ) {
    cost
    createdAt
    description
    entries {
      modelInputName
      modelInputType
      presetEntryDatas {
        description
        entryType
        parameterName
        presetOrder
        val
      }
    }
    id
    isOpenSource
    isPublic
    modelName
    modelsID
    presetName
    taskName
    updatedAt
    usage
    userID
    username
  }
}
` as GeneratedMutation<
  APITypes.UpdateModelInputPresetIncrementUsageMutationVariables,
  APITypes.UpdateModelInputPresetIncrementUsageMutation
>;
